import React, { Component } from 'react'
import trans from '../../trans'
import { Panel, PanelContent, PanelTitle } from '../ui/Panel'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Col, Row } from '../../ui'
import Button from '../ui/ButtonComponent'
import ReservationCancelConfirmationDialog from './ReservationCancelConfirmationDialog'
import { OFFER_STATUS } from '../../constants/offer-status'
import SocketProviderService from '../../utils/SocketProvider'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import {
  fetchDocumentOffer,
  getDocumentOffer,
  cancelDocumentOffer,
  setDocumentOffer,
  getState,
  isCancellationInProgress,
} from '../../store/app/document-offer'

class ReservationDetailsContainer extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      confirmationDialogOpen: false,
      offerSearchUuid: null,
    }

    this.handleConfirmationDialogClose = this.handleConfirmationDialogClose.bind(this)
  }

  componentDidMount() {
    if (
      !this.props.document ||
      !this.props.document.offer_uuid ||
      (this.props.document.type !== 'travel' && this.props.document.offer_uuid)
    ) {
      return null
    }

    const offerUuid = this.props.document.offer_uuid
    const { request } = this.props.document

    this.props.fetchDocumentOffer(request, offerUuid).then((offer) => {
      const searchUuid = offer.search_uuid
      const channelName = `App.Offer.${searchUuid}`
      const eventName = `.App\\Events\\ReservationStatusChangedEvent`

      this.setState({ offerSearchUuid: searchUuid })

      SocketProviderService.subscribe(channelName)(eventName)((response) => {
        this.props.setDocumentOffer(response.offer)
      })
    })
  }

  componentWillUnmount() {
    const channelName = `App.Offer.${this.state.offerSearchUuid}`
    const eventName = `.App\\Events\\ReservationStatusChangedEvent`
    SocketProviderService.unsubscribe(channelName)(eventName)
  }

  handleConfirmationDialogClose(confirmed = false) {
    this.setState({ confirmationDialogOpen: false })

    if (confirmed === true) {
      this.props.cancelDocumentOffer(this.props.document.request, this.props.offer.offer_uuid)
    }
  }

  render() {
    const { document, offer } = this.props

    if (!document || document.type !== 'travel' || !document.readonly || !offer) {
      return null
    }

    const reservationCancelDate = moment(offer.cancel_date)
    const reservationCancelable =
      offer &&
      offer.cancellable &&
      reservationCancelDate.isValid() &&
      moment(new Date()).isBefore(reservationCancelDate)
    const reservationStatus = offer ? offer.status : null

    return (
      <Panel>
        <PanelTitle>
          <strong>{trans('document.reservation-changes-container-title')}</strong>
        </PanelTitle>
        <PanelContent>
          {(reservationStatus === OFFER_STATUS.BOOKED ||
            isCancellationInProgress(reservationStatus)) &&
            reservationCancelable === true && (
              <div>
                <p>
                  {trans('document.reservation-changes-cancel-date', {
                    date: reservationCancelDate.format('DD-MM-YYYY'),
                    time: reservationCancelDate.format('HH:mm'),
                  })}
                </p>
              </div>
            )}

          {isCancellationInProgress(reservationStatus) && (
            <div className='notice-loader' style={{ marginTop: '2rem', marginBottom: '2rem' }}>
              <p className='notice-loader__loader-text'>
                {trans('document.reservation-changes-waiting-for-response')}
              </p>
            </div>
          )}

          {!reservationCancelable &&
            reservationStatus !== OFFER_STATUS.CANCELED &&
            reservationStatus !== OFFER_STATUS.CANCELLATION_FAILED && (
              <p>{trans('document.reservation-changes-can-not-be-canceled')}</p>
            )}

          {reservationStatus === OFFER_STATUS.CANCELED && (
            <p>{trans('document.reservation-changes-canceled-message')}</p>
          )}

          {reservationStatus === OFFER_STATUS.CANCELLATION_FAILED && (
            <p>{trans('document.reservation-changes-cancellation-error')}</p>
          )}

          {reservationStatus === OFFER_STATUS.BOOKED && (
            <Row>
              <Col is_pull_end xs={12}>
                <ReservationCancelConfirmationDialog
                  open={this.state.confirmationDialogOpen}
                  onClose={this.handleConfirmationDialogClose}
                />
                <Button
                  loading={this.props.offerIsCanceling}
                  locked={!reservationCancelable}
                  className='is-margin-right-small'
                  style={{ marginTop: '3rem' }}
                  danger
                  xs
                  onClick={(e) => {
                    this.setState({ confirmationDialogOpen: true })
                  }}
                >
                  {trans('document.reservation-changes-cancel-button-title')}
                </Button>
              </Col>
            </Row>
          )}
        </PanelContent>
      </Panel>
    )
  }
}

ReservationDetailsContainer.propTypes = {
  document: PropTypes.object.isRequired,
  offer: PropTypes.object,
  offerIsCanceling: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    offer: getDocumentOffer(state),
    offerIsCanceling: getState(state).isCanceling,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return bindActionCreators(
    {
      fetchDocumentOffer,
      cancelDocumentOffer,
      setDocumentOffer,
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

ReservationDetailsContainer = compose(withConnect)(ReservationDetailsContainer)

export { ReservationDetailsContainer }
export default ReservationDetailsContainer
