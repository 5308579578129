import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Icon from '../../ui/IconComponent';
import { SEARCH_STATUS_PENDING, SEARCH_STATUS_COMPLETE } from '../../../store/app/hotels-booking';
import { Loader } from '../../ui/LoadingOverlay/Loader';
import { get } from 'lodash';
import { Navigation } from './Navigation';
import { trans } from '../../../trans';
import SingleHotel from '../SingleHotel';
import moment from 'moment';
import { List } from './List';
import { Map } from 'immutable';
import classNames from 'classnames';
import { ComponentOverlay } from '../../ui/ComponentOverlay';
import Sort from './Sort';
import { OffersMap } from '../OfferMap/OffersMap';

interface ModalProps {
  radius: number;
  coordinates: { lat: number; lng: number };
  hotelsBooking: any;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

interface ModalState {
  window: {
    width: number;
    height: number;
  };
  view: View;
}

enum View {
  LIST,
  MAP,
}

class Modal extends Component<ModalProps, ModalState> {
  state = {
    window: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    view: View.LIST,
  };

  componentDidMount() {
    window.addEventListener('resize', () => this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowDimensions());
  }

  updateWindowDimensions() {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  getListHeight() {
    const { width, height } = this.state.window;
    const isScreenSmall = width <= 1366 && height <= 768;
    const isScreenTall = height > 900;

    return !isScreenSmall && !isScreenTall ? 500 : 'calc(100% - 100px)';
  }

  renderHeader = () => {
    const {
      hotelsBooking: {
        selectors: { query },
      },
    } = this.props;

    const location = get(query, 'location', null);
    let city = '';

    if (location instanceof Map) {
      city = location.toJS().city;
    } else {
      city = location.city;
    }

    const from = moment(get(query, 'arrival_at', null)).format('YYYY.MM.DD');
    const to = moment(get(query, 'departure_at', null)).format('YYYY.MM.DD');

    return trans('hotels-booking.results-header', { city, from, to });
  };

  renderNoOffers = () => {
    const {
      hotelsBooking: {
        selectors: { allOffersNumber, offers },
        actions: { setFilter },
      },
    } = this.props;

    let message = trans('hotels-booking.no-results');

    if (allOffersNumber) {
      message = trans('hotels-booking.no-offers-for-that-filters');
    }

    return (
      <div style={{ height: this.getListHeight() }} className='hotels-dialog__no-results'>
        <span>{message}</span>
      </div>
    );
  };

  renderList = () => {
    const {
      hotelsBooking: {
        selectors: {
          offers,
          status,
          allOffersNumber,
          hasOffers,
          isFacilityChanged,
          isChanged,
          isFiltering,
          statusAfterFiltering,
          sortBy,
        },
        actions: { setSortBy },
      },
    } = this.props;

    const noticeLoaderClasses = classNames({
      'notice-loader': true,
      'notice-loader--hotels-search': true,
      'notice-loader--hidden': !(status === SEARCH_STATUS_PENDING && hasOffers),
    });

    return (
      <>
        <div className='hotels-dialog__header-line'>
          <h2 className='h2 hotels-dialog__heading'>{this.renderHeader()}</h2>
          <div className='hotels-dialog__offers-counter'>
            {!!allOffersNumber &&
              hasOffers &&
              trans('hotels-booking.you-see-n-from-x', {
                filteredOffersNumber: offers.length,
                allOffersNumber,
              })}
          </div>
        </div>

        <div className='hotels-dialog__sorting'>
          <div className='hotels-dialog__sort hotels-dialog__navigation-item'>
            <Sort onChange={setSortBy} value={sortBy} />
          </div>

          <div className={noticeLoaderClasses}>
            <p className='notice-loader__loader-text notice-loader__loader-text--hotels-search-pending'>
              {trans('global.searching')}
            </p>
          </div>
        </div>

        <div className='has-loader' style={{ height: this.getListHeight() }}>
          {hasOffers && (
            <Fragment>
              {!!offers.length && <List hotelsBooking={this.props.hotelsBooking} />}

              {!isFiltering &&
                !offers.length &&
                status !== SEARCH_STATUS_PENDING &&
                this.renderNoOffers()}
            </Fragment>
          )}

          {!isFiltering && !hasOffers && status !== SEARCH_STATUS_PENDING && this.renderNoOffers()}
        </div>
      </>
    );
  };

  private renderSearchResults() {
    const {
      hotelsBooking: {
        selectors: {
          status,
          hasOffers,
          isFacilityChanged,
          isChanged,
          isFiltering,
          statusAfterFiltering,
        },
      },
    } = this.props;

    return (
      <div className='hotels-dialog__wrapper'>
        <div className='hotels-dialog__main'>
          <div className='hotels-dialog__sidebar'>
            <Navigation
              hotelsBooking={this.props.hotelsBooking}
              showMap={this.state.view === View.LIST}
              coordinates={this.props.coordinates}
              onMapClick={() => {
                this.setState({ view: View.MAP });
              }}
            />
          </div>

          <div className='hotels-dialog__offers'>
            {this.state.view === View.MAP ? this.renderMap() : this.renderList()}

            {(isChanged || isFacilityChanged) && (
              <ComponentOverlay>
                <div className='component-overlay__message component-overlay__message--unsettled-lump-sums'>
                  <Icon className='icon-info-grey component-overlay__message-icon' type='info' />
                  <span>{trans('request.flight-filters-changed-overlay-message')}</span>
                </div>
              </ComponentOverlay>
            )}

            {((isFiltering &&
              status === SEARCH_STATUS_COMPLETE &&
              statusAfterFiltering === SEARCH_STATUS_COMPLETE) ||
              (!hasOffers && status === SEARCH_STATUS_PENDING)) && <Loader />}
          </div>
        </div>

        {this.state.view !== View.MAP && (
          <button className='hotels-dialog__close-btn'>
            <Icon type='close' onClick={this.props.onClose} />
          </button>
        )}
      </div>
    );
  }

  private renderMap() {
    const {
      hotelsBooking: {
        selectors: { hasOffers, status, offers, allOffersNumber },
        actions: { selectOffer },
      },
    } = this.props;

    const isLoading = status === SEARCH_STATUS_PENDING && hasOffers;

    if (!hasOffers) {
      return <Loader />;
    }

    return (
      <>
        <OffersMap
          coordinates={this.props.coordinates}
          radius={this.props.radius}
          offers={this.props.hotelsBooking.selectors.offers}
          onSelect={(offer) => selectOffer(offer.uuid)}
          onClose={() => this.setState({ view: View.LIST })}
        />

        {isLoading && (
          <div className={'hotels-dialog__map-notification'}>
            <span>
              {trans('global.searching')}{' '}
              {!!allOffersNumber &&
                hasOffers &&
                trans('hotels-booking.you-see-n-from-x', {
                  filteredOffersNumber: offers.length,
                  allOffersNumber,
                })}
            </span>
          </div>
        )}
      </>
    );
  }

  renderSingleHotel = () => {
    const { hotelsBooking, onClose } = this.props;
    return <SingleHotel hotelsBooking={hotelsBooking} onClose={onClose} />;
  };

  render() {
    const {
      hotelsBooking: {
        selectors: { selectedOffer },
      },
    } = this.props;
    const dialogClassNames = classNames([
      'hotels-dialog',
      'hotels-dialog--list',
      selectedOffer ? 'hotels-dialog--single' : '',
    ]);

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.onClose}
        onEnter={this.props.onOpen}
        disableBackdropClick
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        maxWidth={false}
        fullWidth={true}
        PaperProps={{
          className: dialogClassNames,
          square: true,
        }}
        scroll='paper'
      >
        <DialogContent>
          {selectedOffer ? this.renderSingleHotel() : this.renderSearchResults()}
        </DialogContent>
      </Dialog>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hotelsBooking: PropTypes.object.isRequired,
};

export default Modal;
export { Modal };
