import { Element } from './Element'
import { Location } from './index'
import { TRAVEL_TRAIN_TRIP } from '../../constants/travel'

class Train extends Element {
  departure_at: any;
  return_at: any;
  destination_location: Location;
  departure_location: Location;
  amount: any;
  amount_currency: any;
  calculated_amount: any;
  calculated_amount_currency: any;
  round_trip: boolean;
  searcher_disabled: boolean;
  seat_location: any;
  seat_type: any;
  seat_place_number: any;
  seat_coach_number: any;
  carriage_type: any;
  request_travelers: any[];

  constructor(init) {
    super({ ...init, type: TRAVEL_TRAIN_TRIP })

    const defaults = {
      departure_at: null,
      return_at: null,
      destination_location: {},
      departure_location: {},
      amount: null,
      amount_currency: null,
      calculated_amount: null,
      calculated_amount_currency: null,
      round_trip: false,
      searcher_disabled: false,
      seat_location: null,
      seat_type: null,
      seat_place_number: null,
      seat_coach_number: null,
      carriage_type: null,
      request_travelers: [],
      offer_uuid: null,
      target_real_departure_at: null,
      target_real_arrival_at: null,
      return_real_departure_at: null,
      return_real_arrival_at: null,
    }

    const opts = Object.assign({}, defaults, init)
    let travelers = opts.request_travelers
    if (!travelers.length && opts.travelers && opts.travelers.length) {
      travelers = opts.travelers.map((item) => item.slug)
    }

    this.departure_at = opts.departure_at
    this.return_at = opts.return_at
    this.destination_location = new Location(opts.destination_location)
    this.departure_location = new Location(opts.departure_location)
    this.amount = opts.amount
    this.amount_currency = opts.amount_currency
    this.calculated_amount = opts.calculated_amount
    this.calculated_amount_currency = opts.calculated_amount_currency
    this.round_trip = opts.round_trip
    this.searcher_disabled = opts.searcher_disabled
    this.seat_location = opts.seat_location
    this.seat_type = opts.seat_type
    this.seat_place_number = opts.seat_place_number
    this.seat_coach_number = opts.seat_coach_number
    this.carriage_type = opts.carriage_type
    this.request_travelers = travelers
    this.offer_uuid = opts.offer_uuid
    this.target_real_departure_at = opts.target_real_departure_at
    this.target_real_arrival_at = opts.target_real_arrival_at
  }

  getStartLocation() {
    return this.departure_location
  }

  getEndLocation() {
    return this.destination_location
  }

  getStartDate() {
    return this.target_real_departure_at || this.departure_at
  }

  getEndDate() {
    return this.target_real_arrival_at || this.return_at
  }

  getAllAmounts() {
    return [
      {
        currency: this.amount_currency,
        value: this.amount,
      },
    ]
  }

  isRoundTrip() {
    return this.round_trip
  }
}

export { Train }
export default Train
