import PropTypes from 'prop-types'
import React from 'react'
import { RouteManager } from '../../../containers/RouteManager/index'
import { ExpenseRequestManager } from '../../../containers/ExpenseRequestManager/index'
import { ExpenseRequestAccountingPage } from '../../ExpenseRequestAccountingPage/index'

class ExpenseRequestPageAccounting extends React.Component<any, any> {
  render() {
    const { request, fetchRequest } = this.props
    const { summary } = request

    return (
      <RouteManager>
        {({ push, getRouteByName }) => (
          <ExpenseRequestManager expenseRequest={request}>
            {({ expenseRequest, isExpenseRequestLoaded, instanceCurrency, currentUser }) => (
              <ExpenseRequestAccountingPage
                expenseRequest={expenseRequest}
                isLoaded={isExpenseRequestLoaded}
                fetchRequest={fetchRequest}
                push={push}
                getRouteByName={getRouteByName}
                summary={summary}
                instanceCurrency={instanceCurrency}
                currentUser={currentUser}
                isTrip={false}
                changeRequestValue={this.props.changeRequestValue}
              />
            )}
          </ExpenseRequestManager>
        )}
      </RouteManager>
    )
  }
}

ExpenseRequestPageAccounting.propTypes = {
  request: PropTypes.object.isRequired,
}

export { ExpenseRequestPageAccounting }
export default { TripRequestPageAccounting: ExpenseRequestPageAccounting }
