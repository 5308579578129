import PropTypes from 'prop-types'
import React from 'react'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { ExpenseRequestSummary } from '../ExpenseRequestSummary'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import { RequestBasicInfo } from '../../RequestPageCommon/BasicInfo/RequestBasicInfo'
import { BasicInfoManager } from '../../../containers/BasicInfoManager'
import { LoadingOverlay } from '../../ui/LoadingOverlay'
import trans from '../../../trans'
import { BasicInfoForm } from '../../../containers/BasicInfoForm'
import { RequestAccountingSummary } from '../../RequestAccountingSummary'
import { AccountingDocuments } from '../../AccountingDocuments'
import { AccountDimensionManager } from '../../../containers/AccountDimensionManager'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'
import PrintRequestDocuments from '../../PrintRequestDocuments'

class ExpenseRequestPageFinish extends React.Component<any, any> {
  render() {
    const { request, fetchRequest } = this.props

    return (
      <div>
        <RequestHeader request={request} />

        <BasicRequestInfoRenderer />

        <AccountingDocuments
          request={request}
          fetchRequest={fetchRequest}
          disableUpload={true}
          disableDelete={true}
        />

        <AccountingDocuments
          request={request}
          fetchRequest={fetchRequest}
          disableUpload={true}
          disableDelete={true}
          showMileageAllowance={false}
          readOnly={true}
          showSummary={true}
          types={['travel', 'confirmation']}
        />

        <ExpenseRequestSummary request={request} />

        <RequestAccountingSummary
          changeRequestValue={this.props.changeRequestValue}
          request={request}
          accountingBalance={request.accountingBalance || {}}
        />

        <PrintRequestDocuments request={request} />

        <RequestComments comments={request['comments']} />
      </div>
    )
  }
}

ExpenseRequestPageFinish.propTypes = {
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isModuleActive: PropTypes.func.isRequired,
}

export { ExpenseRequestPageFinish }
export default { ExpenseRequestPageFinish }
