import React from 'react';

import { Link, useParams } from 'react-router-dom';

import { BreadCrumbs } from '../../containers';
import { getRouteByName } from '../../routes';
import trans from '../../trans';
import useTransactions from '../PaymentsPage/hooks/useTransactions';
import PaymentsPageFilters from '../PaymentsPageFilters';
import PaymentsPageTable from '../PaymentsPageTable';
import { LoadingOverlay } from '../ui/LoadingOverlay';
import { Section } from '../ui/Section';
import { SectionHeader } from '../ui/SectionHeader';

import useStatement from './hooks/useStatement';

function StatementPage() {
  const id = 'P2700003';
  const card = '4239 **** **** 7345';
  const params = useParams<{ from: string; to: string }>();

  const {
    data: transactions,
    paginator,
    loading,
    setPage,
    load,
    setFilter,
    filters,
  } = useTransactions();
  const { summaries } = useStatement(id);

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'my-card')}>{trans('main-menu.my-card')}</Link>
        <Link to={getRouteByName('main', 'my-card-statements')}>
          {trans('main-menu.my-card-statements')}
        </Link>
        <Link to={getRouteByName('main', 'my-card-settlement', { id })}>
          {trans('main-menu.my-card-statement', { id })}
        </Link>
      </BreadCrumbs>

      <Section className={'my-cards'} noBorder>
        <SectionHeader
          className={'my-cards__header'}
          caption={trans('my-card.single_statement_header', { from: params.from, to: params.to })}
        />

        <PaymentsPageFilters filters={filters} setFilter={setFilter} />
        {loading && <LoadingOverlay />}
        {!loading && (
          <>
            <PaymentsPageTable
              data={transactions}
              paginator={paginator}
              setPage={setPage}
              refresh={load}
            />
            {/*<StatementSummary data={summaries} /> disabled due MIN-5804 */}
          </>
        )}
      </Section>
    </div>
  );
}

export default StatementPage;
