import React, { Component, Fragment } from 'react';

import { get, has, values } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';

import { trans } from '../../trans';
import { Tooltip } from '../Tooltip';
import Button from '../ui/ButtonComponent';
import Icon from '../ui/IconComponent';

import Availability from './Availability';
import CommissionMessage from './CommissionMessage';
import OfferDate from './OfferDate';
import OfferTitle from './OfferTitle';
import OperatorLogo from './OperatorLogo';
import { Agreement } from './Reservation/Agreement';
import CarriageTypeField from './Reservation/CarriageTypeField';
import SeatCoachNumberField from './Reservation/SeatCoachNumberField';
import SeatLocationField from './Reservation/SeatLocationField';
import SeatPlaceNumberField from './Reservation/SeatPlaceNumberField';
import { SeatTypeField } from './Reservation/SeatTypeField';

const hasAvailableOption = (option, key) => {
  return get(option, 'availableOptions.' + key, []).length > 0;
};

class SelectedOffer extends Component<any, any> {
  searchAnotherOne = () => {
    const { searchAnotherOne } = this.props;
    searchAnotherOne();
  };

  areSeatPreferencesVisible = () => {
    const { offer, element } = this.props;
    const option = offer.option;
    const preferencesDisabled = option.booking !== null;

    const seat_type = get(element, 'seat_type', null);

    if (seat_type === 'ANY' || seat_type === null || !hasAvailableOption(option, 'seat_type')) {
      return false;
    }

    if (!preferencesDisabled) {
      return true;
    }

    const seat_place_number = get(element, 'seat_place_number', null);
    const seat_coach_number = get(element, 'seat_coach_number', null);

    return seat_type && (seat_place_number || seat_coach_number);
  };

  getStatus = (props) => {
    const {
      trainsBooking: {
        selectors: { selectedOffer },
      },
    } = props;
    return get(selectedOffer, 'option.booking', null);
  };

  renderAgreement = () => {
    if (this.getStatus(this.props) === null) {
      return <Agreement trainsBooking={this.props.trainsBooking} />;
    }

    return null;
  };

  renderSaveButton = () => {
    const {
      trainsBooking: {
        selectors: { hasAgreement },
      },
    } = this.props;

    const button = (
      <Button
        disabled={!hasAgreement}
        type='submit'
        primary
        className='train-trip__selected-save-button'
      >
        {trans('trains-booking.save')}
      </Button>
    );

    if (!hasAgreement) {
      return (
        <Tooltip html={<span>{trans('global.booking-agreement-tooltip')}</span>}>{button}</Tooltip>
      );
    }

    return button;
  };

  render() {
    const {
      offer,
      element,
      errors,
      trainsBooking: {
        selectors: { totalPaxes },
      },
    } = this.props;
    const option = offer.option;

    const arrival = moment(get(offer, 'attributes.arrivalDate', null));
    const departure = moment(get(offer, 'attributes.departureDate', null));
    const operator = get(offer, 'attributes.equipmentCode', '');
    const trainNumber = get(offer, 'attributes.trainNumber', '');
    const travelTime = get(offer, 'attributes.travelTime', '');
    const amount = get(offer, 'option.amount.formatted', '0,00 zł').toLowerCase();
    const travelClass =
      get(option, 'attributes.service_class', null) === '1'
        ? trans('trains-booking.travel-class-item-first')
        : trans('trains-booking.travel-class-item-second');
    const preferencesDisabled = option.booking !== null;

    return (
      <div className='train-trip__selected-ticket'>
        <div className='train-trip__selected-ticket-title'>
          <span className='train-trip__selected-ticket-title-cities'>
            <OfferTitle offer={offer} />
          </span>
          <span>
            <OfferDate offer={offer} />
          </span>
        </div>

        <div className='train-trip__selected-ticket-details-container'>
          <div className='train-trip__selected-ticket-intro'>
            <OperatorLogo offer={offer} className='train-trip__selected-ticket-logo' />

            <div className='train-trip__selected-ticket-details'>
              <span className='train-trip__selected-ticket-departure-hours'>
                {departure.format('HH:mm')} - {arrival.format('HH:mm')}
              </span>
              <div className='train-trip__dialog-ticket-provider'>
                <span>{operator}&nbsp;</span>
                <span>{trainNumber}</span>
              </div>
            </div>
          </div>

          <div className='train-trip__selected-ticket-travel-details'>
            <div className='train-trip__selected-ticket-travel-detail'>
              <div className='train-trip__selected-ticket-row'>
                <span className='train-trip__selected-ticket-travel-detail-title'>
                  {trans('trains-booking.travel-time')}
                </span>
                <span className='train-trip__selected-ticket-travel-detail-value'>
                  {travelTime}
                </span>
              </div>

              <div className='train-trip__selected-ticket-row'>
                <span className='train-trip__selected-ticket-travel-detail-title'>
                  {trans('trains-booking.travel-class')}
                </span>
                <span className='train-trip__selected-ticket-travel-detail-value'>
                  {travelClass}
                </span>
              </div>
            </div>

            <Availability offer={offer} />

            {hasAvailableOption(option, 'seat_type') && (
              <div className='train-trip__selected-ticket-place-detail'>
                {hasAvailableOption(option, 'seat_location') && element.seat_type === 'ANY' && (
                  <div className='train-trip__selected-ticket-row'>
                    <Field
                      name='seat_location'
                      component={SeatLocationField}
                      offer={offer}
                      disabled={preferencesDisabled}
                    />
                  </div>
                )}

                <div className='train-trip__selected-ticket-row'>
                  <Field
                    name='seat_type'
                    component={SeatTypeField}
                    offer={offer}
                    disabled={preferencesDisabled}
                  />
                </div>
              </div>
            )}

            {((hasAvailableOption(option, 'carriage_type') && element.seat_type === 'ANY') ||
              hasAvailableOption(option, 'seat_type')) && (
              <div className='train-trip__selected-ticket-place-detail'>
                {hasAvailableOption(option, 'seat_type') && this.areSeatPreferencesVisible() && (
                  <div className='train-trip__selected-ticket-row'>
                    {!hasAvailableOption(option, 'seat_coach_number') && (
                      <Fragment>
                        <Field
                          name='seat_coach_number'
                          component={SeatCoachNumberField}
                          offer={offer}
                          disabled={preferencesDisabled}
                        />

                        {!hasAvailableOption(option, 'seat_place_number') && (
                          <Field
                            name='seat_place_number'
                            component={SeatPlaceNumberField}
                            offer={offer}
                            disabled={preferencesDisabled}
                          />
                        )}
                      </Fragment>
                    )}

                    {(has(errors, 'seat_place_number') || has(errors, 'seat_coach_number')) && (
                      <div className='train-trip__seat-validation'>
                        {values(errors).map((error) => (
                          <span className='form-group__error'>{error}</span>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {hasAvailableOption(option, 'carriage_type') && element.seat_type === 'ANY' && (
                  <div className='train-trip__selected-ticket-row'>
                    <Field
                      name='carriage_type'
                      component={CarriageTypeField}
                      offer={offer}
                      disabled={preferencesDisabled}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className='train-trip__selected-ticket-save-details'>
          <span className='train-trip__selected-ticket-price plane-offer__price-wrapper'>
            {offer.option.rules.length > 0 && (
              <div className='plane-trip__offer-detail-price-warning'>
                <Icon
                  type='warning'
                  className='summary-warnings__icon is-color-warning is-gradient-warning'
                />
              </div>
            )}
            <span className='plane-offer__price'>{amount}</span>
          </span>

          <div className='train-trip__selected-ticket-messages'>
            <div className='train-trip__selected-ticket-agreement-message'>
              {this.renderAgreement()}
            </div>
            <div>
              <div className='train-trip__selected-buttons'>
                {totalPaxes > 1 && (
                  <span className='train-trip__selected-ticket-message'>
                    {trans('trains-booking.reservation-short-info', { paxes: totalPaxes })}
                  </span>
                )}

                <CommissionMessage offer={offer} />

                <div className='train-trip__selected-ticket-buttons'>
                  <Button
                    outline
                    className='train-trip__selected-change-button'
                    onClick={this.searchAnotherOne}
                  >
                    {trans('trains-booking.search-another-one')}
                  </Button>
                  {this.renderSaveButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectedOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  searchAnotherOne: PropTypes.func.isRequired,
};

export default SelectedOffer;
export { SelectedOffer };
