import React from 'react'
import classNames from 'classnames'
import Icon from './IconComponent'
import PropTypes from 'prop-types'

class Button extends React.Component<any, any> {
  unmounted = false

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  onClick = (e) => {
    const { onClick } = this.props

    if (typeof onClick === 'undefined' || this.props.waiting || this.props.locked === true) {
      return
    }

    const possiblePromise = onClick(e)

    if (Promise.resolve(possiblePromise) === possiblePromise) {
      this.setState({ loading: true })
      possiblePromise
        .then((response) => {
          if (this.unmounted) {
            return
          }

          this.setState({ loading: false })
          return Promise.resolve(response)
        })
        .catch((error) => {
          if (this.unmounted) {
            return
          }

          this.setState({ loading: false })
          return Promise.reject(error)
        })
    }
  }

  render() {
    const {
      primary,
      hidden,
      secondary,
      outline,
      danger,
      link,
      icon,
      pull_end,
      xs,
      xxs,
      arrow,
      className,
      ...props
    } = this.props

    const btnClasses = classNames({
      'btn--primary': primary,
      'btn--secondary': secondary,
      'btn--pull-end': pull_end,
      'btn--xs': xs,
      'btn--xxs': xxs,
      btn: true,
      'btn--hidden': hidden,
      'btn--outline': outline,
      'btn--danger': danger,
      'btn--link': link,
      'btn--icon': icon,
      'btn--loading': this.state.loading || this.props.loading,
      'btn--waiting': this.props.waiting,
      'btn--locked': this.props.locked,
      'with-arrow': arrow,
      [className]: !!className,
    })

    let propsPrepared = { ...props }
    delete propsPrepared.locked

    return (
      <button {...propsPrepared} className={btnClasses} onClick={this.onClick}>
        <div className='btn__progress-wrap'>
          <div className='btn__content'>
            {icon && <Icon type={icon} />}
            {props.children}
          </div>

          {(this.state.loading || this.props.loading) && <div className='btn__progress' />}
        </div>
      </button>
    )
  }
}

Button.propTypes = {
  waiting: PropTypes.number,
  locked: PropTypes.bool,
  loading: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  outline: PropTypes.bool,
  danger: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Button
