import { get, mapValues, has } from 'lodash';
import { change as changeForm, stopSubmit } from 'redux-form';

import { STATUS_DRAFT, STATUS_TRIP, STATUS_UPCOMING_TRIP } from '../../../constants/request';
import { Train } from '../../../models/timeline';
import APIClient from '../../../services/APIClient';
import { TrainOffersWebsocketMessage } from '../../../types/offers';
import { getFormValues } from '../../../utils/forms';
import SocketProviderService from '../../../utils/SocketProvider';
import { deleteElement, saveElement, updateElementSearchUuid } from '../trip-timeline';

import {
  getOfferCurrency,
  getQuery,
  getSearchUuid,
  getSelectedOffer,
  getTotalAmount,
  getSelectedOption,
} from './selectors';

import {
  BOOK_OFFER,
  BOOKING_FAILED,
  BOOKING_NEW,
  CHANGE,
  RESET,
  SEARCH_ANOTHER_ONE,
  SELECT_OFFER,
  SET,
  SET_FILTER,
  SET_OFFERS,
  TOGGLE_SEARCH,
  SET_AGREEMENT,
  CHANGE_STATUS,
  CHANGE_ERROR_MESSAGE_SLUG,
  REPLACE_OFFER,
} from './index';

// actions Creators
export const reset = (name) => () => (dispatch) => {
  dispatch({
    type: RESET,
    payload: {
      name,
    },
  });
};

export const set = (name) => (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      name,
      data,
    },
  });
};

export const change = (name) => (path, value) => (dispatch) => {
  dispatch({
    type: CHANGE,
    payload: {
      name,
      path,
      value,
    },
  });
};

export const search =
  (name) =>
  (request, data = {}) =>
  (dispatch, getState) => {
    const form = getFormValues(name, getState());

    dispatch(startLoading(name)());
    dispatch(setIntegrity(name)());
    dispatch(change(name)('hasOffers', false));
    dispatch(
      setQuery(name)({
        ...data,
        request_travelers: form.request_travelers,
      }),
    );

    const fields = {
      location_from: get(data, 'departure_location.additional_data.code', null),
      location_from_type: get(data, 'departure_location.additional_data.type', null),
      location_to: get(data, 'destination_location.additional_data.code', null),
      location_to_type: get(data, 'destination_location.additional_data.type', null),
      date_from: get(data, 'departure_at', null),
      request_travelers: form.request_travelers,
    };

    APIClient.searchTrains(request, fields)
      .then((response) => {
        dispatch(change(name)('isResultsListOpen', true));
        dispatch(change(name)('uuid', response.data.uuid));
        dispatch(subscribeSearchResult(name)());

        if (response.data.status === 'complete') {
          return APIClient.getOffersByUuid(response.data.uuid).then((response) => {
            dispatch(setOffers(name)(response.data));
            dispatch(setInitialized(name)(true));

            dispatch(change(name)('searching', false));

            return response;
          });
        }
      })
      .catch((error) => {
        dispatch(stopLoading(name)());

        const errors = mapValues(get(error, 'alerts.0.errors', {}), (error) => get(error, '0'));

        if (has(errors, 'location_from')) {
          errors.departure_location = errors['location_from'];
        }

        if (has(errors, 'location_to')) {
          errors.destination_location = errors['location_to'];
        }

        dispatch(stopSubmit(name, errors));

        return error;
      });
  };

export const replaceOffer = (name) => (offers) => (dispatch) => {
  dispatch({
    type: REPLACE_OFFER,
    payload: {
      offers,
      name,
    },
  });
};

export const setOffers = (name) => (data: TrainOffersWebsocketMessage) => (dispatch) => {
  const { uuid, status, offers, totalPaxes } = data;

  if (offers.length) {
    dispatch(change(name)('hasOffers', true));
  }

  dispatch(change(name)('waitingForAttributes', false));

  dispatch({
    type: SET_OFFERS,
    payload: {
      name,
      uuid,
      offers,
      status,
      totalPaxes,
    },
  });
};

export const changeStatus = (name) => (offerUuid, optionUuid, status) => (dispatch) => {
  dispatch({
    type: CHANGE_STATUS,
    payload: {
      name,
      offerUuid,
      optionUuid,
      status,
    },
  });
};

export const changeErrorMessageSlug = (name) => (offerUuid, errorMessageSlug) => (dispatch) => {
  dispatch({
    type: CHANGE_ERROR_MESSAGE_SLUG,
    payload: {
      name,
      offerUuid,
      errorMessageSlug,
    },
  });
};

export const subscribeReservationStatus = (name) => () => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState());
  const channelName = `App.Offer.${searchUuid}`;
  const eventName = `.App\\Events\\ReservationStatusChangedEvent`;

  SocketProviderService.subscribe(channelName)(eventName)((response) => {
    dispatch(
      changeErrorMessageSlug(name)(response.offer.offer_uuid, response.offer.error_message_slug),
    );
    dispatch(
      changeStatus(name)(
        response.offer.offer_uuid,
        response.offer.option_uuid,
        response.offer.status,
      ),
    );
  });
};

export const unsubscribeReservationStatus = (name) => () => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState());
  const channelName = `App.Offer.${searchUuid}`;
  const eventName = `.App\\Events\\ReservationStatusChangedEvent`;

  SocketProviderService.unsubscribe(channelName)(eventName);
};

export const subscribeSearchResult = (name) => () => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState());
  const channelName = `App.Offer.${searchUuid}`;
  const eventName = `.App\\Events\\SearchOffersResultsChanged`;

  SocketProviderService.subscribe(channelName)(eventName)(
    (response: TrainOffersWebsocketMessage) => {
      dispatch(setOffers(name)(response));
    },
  );
};

export const unsubscribeSearchResult = (name) => () => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState());
  const channelName = `App.Offer.${searchUuid}`;
  const eventName = `.App\\Events\\SearchOffersResultsChanged`;

  SocketProviderService.unsubscribe(channelName)(eventName);
};

export const startLoading = (name) => () => (dispatch) => {
  dispatch(change(name)('loading', true));
};

export const stopLoading = (name) => () => (dispatch) => {
  dispatch(change(name)('loading', false));
};

export const selectOffer =
  (name) =>
  (request, { search_uuid, offer, request_element, attributes }, updateOffers = true) =>
  (dispatch, getState) => {
    dispatch(change(name)('isBlocked', true));

    if (request.status === STATUS_TRIP || request.status === STATUS_UPCOMING_TRIP) {
      dispatch(change(name)('waitingForAttributes', true));
    }

    if (request_element instanceof Train) {
      const amount = Number.parseFloat(get(offer.option, 'amount.amount', 0));
      const fee = Number.parseFloat(get(offer.option, 'reservation_fee.amount', 0));
      const gross = (amount + fee).toFixed(2);

      dispatch(changeForm(request_element.key, 'amount', gross));
      dispatch(
        changeForm(request_element.key, 'departure_at', get(offer, 'attributes.departureDate')),
      );
      dispatch(changeForm(request_element.key, 'search_uuid', search_uuid));
      dispatch(updateElementSearchUuid(request_element.key, search_uuid));
    }

    dispatch({
      type: SELECT_OFFER,
      payload: {
        name,
        offer_uuid: offer.uuid,
      },
    });

    dispatch(unsubscribeSearchResult(name)());

    return APIClient.chooseTrainOffer(request, {
      search_uuid,
      offer_uuid: offer.uuid,
      option_uuid: offer.option.uuid,
      request_element: get(request_element, 'draft', null)
        ? null
        : getFormValues(request_element.key, getState()),
      attributes: attributes,
    })
      .then(({ data }) => {
        dispatch(closeResultsList(name)());
        dispatch(change(name)('waitingForAttributes', false));
        dispatch(change(name)('isBlocked', false));
        dispatch(change(name)('totalPaxes', data.totalPaxes));

        if (updateOffers) {
          dispatch(setOffers(name)(data));
        }

        if (request_element instanceof Train) {
          const offer = getSelectedOffer(name)(getState());

          dispatch(changeForm(request_element.key, 'amount', offer.option.amount.amount));
          dispatch(
            changeForm(request_element.key, 'amount_currency', offer.option.amount.currency),
          );
          dispatch(changeForm(request_element.key, 'offer_uuid', get(offer, 'uuid', null)));
          dispatch(
            changeForm(
              request_element.key,
              'target_real_departure_at',
              get(offer, 'attributes.departureDate', null),
            ),
          );
          dispatch(
            changeForm(
              request_element.key,
              'target_real_arrival_at',
              get(offer, 'attributes.arrivalDate', null),
            ),
          );
        }
      })
      .catch(() => {
        dispatch(change(name)('isBlocked', false));
      });
  };

const updateFormWithRequestedAttributes = (name) => (dispatch, getState) => {
  const offer = getSelectedOffer(name)(getState());

  if (offer && offer.option) {
    const attributes = offer.option.requestedAttributes;

    Object.keys(attributes).forEach((key) => {
      dispatch(changeForm(name, key, attributes[key]));
    });
  }
};

export const fetchSelectedForElement = (name) => (request, element) => (dispatch, getState) => {
  return APIClient.getOffers(request, { element_id: element.id, element_type: element.type }).then(
    (response) => {
      dispatch(setOffers(name)(response.data));
      dispatch(updateFormWithRequestedAttributes(name));

      if (
        get(request, 'status', null) === STATUS_DRAFT &&
        !response.data.uuid &&
        Number(element.amount)
      ) {
        dispatch(setEnable(name)(false));
      }
    },
  );
};

export const setIntegrity = (name) => () => (dispatch) => {
  dispatch(change(name)('integrity', true));
};

export const setNoneIntegrity = (name) => () => (dispatch) => {
  dispatch(change(name)('integrity', false));
};

export const setFilter = (name) => (filter) => (dispatch) => {
  dispatch({
    type: SET_FILTER,
    payload: {
      name,
      filter,
    },
  });
};

export const setFilters = (name) => (filters) => (dispatch) => {
  dispatch(change(name)('filter', filters));
};

export const setQuery = (name) => (query) => (dispatch) => {
  dispatch(change(name)('query', query));
};

export const bookOffer =
  (name) => (request, element) => (uuid, data) => async (dispatch, getState) => {
    dispatch({
      type: BOOK_OFFER,
      payload: {
        name,
        status: BOOKING_NEW,
      },
    });

    dispatch(unsubscribeSearchResult(name)());

    const selectedOffer = getSelectedOffer(name)(getState());
    const selectedOption = getSelectedOption(name)(getState());
    const query = getQuery(name)(getState());
    const totalAmount = getTotalAmount(name)(getState());
    const currency = getOfferCurrency(name)(getState());
    const searchUuid = getSearchUuid(name)(getState());
    let requestElement = element;

    if (element.draft) {
      requestElement = await dispatch(
        saveElement(
          request,
          {
            ...element,
            amount: totalAmount,
            amount_currency: currency,
            departure_at: query.departure_at,
            departure_location: query.departure_location,
            destination_location: query.destination_location,
            request_travelers: query.request_travelers,
          },
          element,
        ),
      );
    }

    dispatch(changeForm(requestElement.key, 'id', requestElement.id));
    dispatch(changeForm(requestElement.key, 'search_uuid', searchUuid));
    dispatch(changeForm(requestElement.key, 'offer_uuid', selectedOffer.uuid));
    dispatch(changeForm(requestElement.key, 'amount', totalAmount));
    dispatch(changeForm(requestElement.key, 'amount_currency', currency));
    dispatch(changeForm(requestElement.key, 'departure_at', query.departure_at));
    dispatch(changeForm(requestElement.key, 'departure_location', query.departure_location));
    dispatch(changeForm(requestElement.key, 'destination_location', query.destination_location));
    dispatch(changeForm(requestElement.key, 'request_travelers', query.request_travelers));

    await dispatch(
      selectOffer(name)(
        request,
        {
          search_uuid: searchUuid,
          offer: selectedOffer,
          request_element: { ...requestElement },
          option: selectedOption,
          attributes: data.attributes,
        },
        false,
      ),
    );

    APIClient.bookOffer(request, uuid, data)
      .then(() => {
        dispatch(subscribeReservationStatus(name)());
      })
      .catch((error) => {
        dispatch(unsubscribeReservationStatus(name)());
        dispatch({
          type: BOOK_OFFER,
          payload: {
            name,
            status: get(error, 'data.booking', BOOKING_FAILED),
          },
        });
      });
  };

export const searchAnotherOne =
  (name) =>
  (request, element: Train) =>
  (data = {}) =>
  (dispatch) => {
    dispatch({
      type: SEARCH_ANOTHER_ONE,
      payload: {
        name,
      },
    });

    // if (element.isAdditional()) {
    //   dispatch(
    //     updateElement({
    //       ...element,
    //       id: element.uuid,
    //       search_uuid: element.search_uuid,
    //       offer_uuid: element.offer_uuid,
    //     }),
    //   );
    // }

    APIClient.chooseTrainOffer(request, {
      search_uuid: data.search_uuid,
      request_element: {
        type: null,
        id: null,
      },
    });
  };

export const toggleSearch = (name) => () => (dispatch) => {
  dispatch({
    type: TOGGLE_SEARCH,
    payload: {
      name,
    },
  });
};

export const setEnable = (name) => (state) => (dispatch) => {
  dispatch(change(name)('enabled', state));
};

export const resignFromSearching = (name) => (request, element) => () => (dispatch) => {
  if (element.isAdditional()) {
    dispatch(deleteElement(request, element));

    return;
  }

  dispatch(change(name)('enabled', false));
  dispatch(changeForm(name, 'searcher_disabled', true));

  if (!element.draft) {
    APIClient.disableSearcher(request, element);
  }
};

export const backToSearching = (name) => (request) => (element) => () => (dispatch) => {
  dispatch(change(name)('enabled', true));
  dispatch(changeForm(name, 'searcher_disabled', false));

  if (!element.draft) {
    APIClient.updateTrainTrip(request.slug, element.id, { ...element, searcher_disabled: false });
  }
};

export const closeResultsList = (name) => () => (dispatch) => {
  dispatch(change(name)('isResultsListOpen', false));
  dispatch(change(name)('loading', false));
  dispatch(unsubscribeSearchResult(name)());
};

export const unselectOption = (name) => (request, element, searchUuid) => (dispatch) => {
  dispatch({
    type: SELECT_OFFER,
    payload: {
      name,
      offer_uuid: null,
    },
  });

  dispatch(change(name)('isResultsListOpen', true));

  const isDraft = get(element, 'draft', false);

  if (!isDraft) {
    APIClient.chooseTrainOffer(request, {
      search_uuid: searchUuid,
      request_element: {
        type: null,
        id: null,
      },
    });
  }
};

export const setAgreement = (name) => (value) => (dispatch) => {
  dispatch({
    type: SET_AGREEMENT,
    payload: {
      name,
      value,
    },
  });
};

export const setInitialized = (name) => (value) => (dispatch) => {
  dispatch(change(name)('initialized', value));
};
