import { Element } from './Element'
import { Location } from './index'
import uuid from '../../utils/uuid'
import { TRAVEL_PRIVATE_ACCOMODATION } from '../../constants/travel'

class PrivateAccommodation extends Element {
  location: Location;
  amount: any;
  arrival_at: any;
  departure_at: any;
  converted_amount: any;
  amount_currency: any;
  converted_amount_currency: any;

  constructor(init) {
    super({ ...init, type: TRAVEL_PRIVATE_ACCOMODATION })

    const defaults = {
      location: {},
      arrival_at: null,
      departure_at: null,
      converted_amount: null,
      amount: null,
      amount_currency: null,
      converted_amount_currency: null,
    }

    const opts = Object.assign({}, defaults, init)

    this.location = new Location(opts.location)
    this.amount = opts.amount
    this.arrival_at = opts.arrival_at
    this.departure_at = opts.departure_at
    this.converted_amount = opts.converted_amount
    this.amount_currency = opts.amount_currency
    this.converted_amount_currency = opts.converted_amount_currency
  }

  getStartLocation() {
    return this.location
  }

  getEndLocation() {
    return this.location
  }

  getStartDate() {
    return this.arrival_at
  }

  getEndDate() {
    return this.departure_at
  }

  getAllAmounts() {
    return [
      {
        currency: this.amount_currency,
        value: this.amount,
      },
    ]
  }
}

export { PrivateAccommodation }
export default PrivateAccommodation
