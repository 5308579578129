import { ICurrency } from './currency'
import { HttpLink } from './http-link'
import { Request } from './request'

export interface IDocumentAbility {
  account: boolean
  add_custom_provider: boolean
  canUserEditElementExchangeRate: boolean
  delete: boolean
  edit: boolean
  edit_basic: boolean
  sendToSettlement: boolean
  view: boolean
}

export enum OcrStatus {
  UNPROCESSABLE = 'unprocessable',
}

export interface OcrHint {
  id: number
  accepted: boolean
  column: keyof IDocument
  instance_id: number
}

export interface IDocument {
  abilities: IDocumentAbility
  accountDimensionItems: unknown[]
  currency: ICurrency
  id: number
  gross: number
  instance_id: number
  request_slug: string
  ocr_status: OcrStatus
  ocrHints: OcrHint[]
  ocrSuggestedProvider: Omit<OcrHint, 'instance_id'>[]
  request: Request
  transaction: unknown
  self_generated: boolean
  can_edit_exchange_rate: boolean
  exchange_rate?: number
  last_exchange_rate: {
    currency: ICurrency
  }
  default_exchange_rate?: number
  default_exchange_rate_date?: number
  converted_gross?: number
  _links: {
    transaction_suggestions?: HttpLink<void>
  }
}
