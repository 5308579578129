import React, { FunctionComponent } from 'react'

import './RoomTypeIcon.scss'
import { get } from 'lodash'
import { ROOM_TYPES } from '../index'
import { HotelOfferEntryOption } from '../../../types/hotel-offers'
import trans from '../../../trans'

interface Props {
  roomType: HotelOfferEntryOption['attributes']['roomType']
}

export const RoomTypeIcon: FunctionComponent<Props> = ({ roomType }) => {
  if (!roomType.length) {
    return null
  }

  return (
    <>
      {roomType.map((type, index) => {
        const convertedRoomType = get(ROOM_TYPES, type, null)

        return (
          <span key={index} className='booked-hotel__room-icon-text'>
            {convertedRoomType ? trans(convertedRoomType) : type}
          </span>
        )
      })}
    </>
  )
}
