import React from 'react'
import PropTypes from 'prop-types'
import trans from '../../../trans'
import { Panel, PanelContent, PanelTitle } from '../../../components/ui/Panel'
import { Col, Row } from '../../../ui/Grid'
import { TravelList } from './TravelList'
import Icon from '../../../components/ui/IconComponent'
import { Loader } from '../../../components/ui/LoadingOverlay/Loader'
import { Tooltip } from '../../../components/Tooltip'
import NonDelegationOverlay from '../../../components/BorderCrossings/NonDelegationOverlay'
import TripDidNotHavePlaceOverlay from '../../../components/BorderCrossings/TripDidNotHavePlaceOverlay'
import { ComponentOverlay } from '../../../components/ui/ComponentOverlay'

class View extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      accessLumpSums,
      setAccessLumpSumPair,
      deleteAccessLumpSums,
      addAccessLumpSum,
      setStoreForAccessLumpSumsPair,
      isLoading,
      isReadOnly,
      showUnsettledOverlay,
    } = this.props

    if (accessLumpSums.length === 0 && isReadOnly) return null

    return (
      <NonDelegationOverlay>
        <TripDidNotHavePlaceOverlay>
          <Panel className='expense-commute has-loader'>
            <PanelTitle>
              <h1 className='expense-commute__panel-heading'>
                <span>{trans('request-summary.lump-sum-for-access-to-stations')}</span>
                <Tooltip
                  className='expense-commute__panel-tooltip'
                  html={
                    <div>
                      <p className='expense-commute__panel-tooltip-text'>
                        {trans('request-summary.lump-sum-info-1')}
                      </p>
                      <p className='expense-commute__panel-tooltip-text'>
                        {trans('request-summary.lump-sum-info-2')}
                      </p>
                    </div>
                  }
                >
                  <Icon type='info' className='icon-info-grey' />
                </Tooltip>
              </h1>
            </PanelTitle>

            <PanelContent>
              {isLoading && <Loader />}

              {accessLumpSums.length > 0 && (
                <div>
                  <Row>
                    <Col xs={6}>
                      <strong>{trans('request-expense-commute.header-1')}</strong>
                    </Col>
                    <Col xs={6}>
                      <strong>{trans('request-expense-commute.header-2')}</strong>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <TravelList
                        accessLumpSums={accessLumpSums}
                        setAccessLumpSumPair={setAccessLumpSumPair}
                        deleteAccessLumpSums={deleteAccessLumpSums}
                        setStoreForAccessLumpSumsPair={setStoreForAccessLumpSumsPair}
                        isReadOnly={isReadOnly}
                      />
                    </Col>
                  </Row>
                </div>
              )}

              {!isReadOnly && (
                <Row>
                  <Col xs={12}>
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        addAccessLumpSum()
                      }}
                      className='expense-commute__button'
                    >
                      <Icon type='plus' />
                      {trans('request-expense-commute.button')}
                    </a>
                  </Col>
                </Row>
              )}
            </PanelContent>
          </Panel>

          {showUnsettledOverlay && (
            <ComponentOverlay className='component-overlay--unsettled-lump-sums'>
              <div className='component-overlay__message component-overlay__message--unsettled-lump-sums'>
                <Icon className='icon-info-grey component-overlay__message-icon' type='info' />
                <span>{trans('request-summary.unsettled-lump-sums-message')}</span>
              </div>
            </ComponentOverlay>
          )}
        </TripDidNotHavePlaceOverlay>
      </NonDelegationOverlay>
    )
  }
}

View.propTypes = {
  accessLumpSums: PropTypes.array.isRequired,
  setAccessLumpSumPair: PropTypes.func.isRequired,
  deleteAccessLumpSums: PropTypes.func.isRequired,
  addAccessLumpSum: PropTypes.func.isRequired,
  setStoreForAccessLumpSumsPair: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  showUnsettledOverlay: PropTypes.bool.isRequired,
}

export { View }
export default { View }
