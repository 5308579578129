import React, { MouseEventHandler, PropsWithChildren } from 'react'
import classNames from 'classnames'

interface PanelProps {
  className: string
  closable: boolean
  onClose: MouseEventHandler
  iconClass: string
}

function Panel(props: PropsWithChildren<Partial<PanelProps>>) {
  const { children, closable, onClose, className, iconClass } = props
  const classes = classNames({ panel: true }, className)
  const iconClasses = classNames({ icon: true, 'icon-close': true }, iconClass)

  return (
    <div className={classes}>
      {closable && (
        <span className='panel__close-icon' onClick={onClose}>
          <i className={iconClasses} />
        </span>
      )}

      {children}
    </div>
  )
}

export { Panel }
export default { Panel }
