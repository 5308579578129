import React, { useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useGridOptions } from './hooks/useGridOptions';
import { useHistory, useParams } from 'react-router-dom';
import Pagination from '../SettingsPage/CompanyPage/Pagination/Pagination';
import { CellClickedEvent } from 'ag-grid-community';
import { IAccountStatement } from '../../types/account';
import { Row } from '../ui/AccordionTable';
import { Col, Radio } from '../../ui';
import { ExpenseRequestActions } from '../ExpenseRequestAccountingPage/ExpenseRequestActions';
import trans from '../../trans';
import Button from '../ui/ButtonComponent';
import { useMyCardExport } from '../AccountPaymentsPageTable/hooks/useMyCardExport';

function AccountsStatementsPageTable({ data, paginator, setPage }) {
  const [selected, setSelected] = useState<IAccountStatement[]>([]);
  const [action, setAction] = useState<string>();
  const gridOptions = useGridOptions();
  const { exportOptions, doExport } = useMyCardExport();
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const applyAction = useCallback(() => {
    return doExport(
      selected.map((statement) => statement.id),
      [],
      action,
    );
  }, [action, doExport, selected]);

  const onCellClicked = useCallback((event: CellClickedEvent<IAccountStatement>) => {
    if (event.column.getId() === 'id') {
      event.node.setSelected(!event.node.isSelected());

      return;
    }

    history.push(`/bank-account/${params.id}/statements/${event.data.id}/entries`);
  }, []);

  return (
    <>
      <div className={'ag-theme-alpine'}>
        <AgGridReact
          gridOptions={gridOptions}
          rowData={data}
          rowHeight={58}
          headerHeight={40}
          onCellClicked={onCellClicked}
          onSelectionChanged={(e) => {
            setSelected(e.api.getSelectedRows());
          }}
        />
      </div>

      {selected.length > 0 && (
        <Row className='notification-bar'>
          <Col sm={12} is_pull_end>
            <div className='notification-bar__row'>
              <ExpenseRequestActions hideNotApplicable>
                {Object.entries(exportOptions).map(([accept, format]) => (
                  <div key={format} className='react-select__option'>
                    <Radio
                      name='status'
                      disabled={!selected.length}
                      value={accept}
                      label={`${trans('global.export_to', { format })} (${selected.length})`}
                      onClick={() => {
                        setAction(accept);
                      }}
                    />
                  </div>
                ))}
              </ExpenseRequestActions>

              <Button primary xs disabled={!selected.length || !action} onClick={applyAction}>
                {trans('global.perform')}
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <Pagination
        page={paginator.page}
        total={paginator.total}
        perPage={paginator.per_page}
        changePage={(page) => setPage(page)}
      />
    </>
  );
}

export default AccountsStatementsPageTable;
