import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../components/ui/ButtonComponent';
import Icon, { EXPENSES_TYPES_TO_ICONS } from '../ui/IconComponent';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  STATUS_ACCOUNTING,
  STATUS_SETTLEMENT,
  STATUS_TRIP,
  STATUS_UPCOMING_TRIP,
  STATUS_WAITING_FOR_ACCEPTANCE,
  STATUS_TRANSFERRED,
  STATUS_TRANSFER_ERROR,
  STATUS_FINISH,
  STATUS_ACCEPTANCE_OF_SETTLEMENT,
} from '../../constants/request';
import _, { get } from 'lodash';
import { AmountFormatter } from '../AmountFormatter';
import { renderKey } from '../../utils/react';
import { RouteManager } from '../../containers/RouteManager';
import { trans } from '../../trans';
import { DocumentCellPresentation } from '../DocumentCellPresentation';
import { DocumentCellLoader } from './DocumentCellLoader';
import { DocumentCellFail } from './DocumentCellFail';
import APIClient from '../../services/APIClient';
import SocketProviderService from '../../utils/SocketProvider';

class DocumentCell extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      blobImgInURLObject: null,
      documentStatus: this.props.document.status,
      documentTicketInvalidated: false,
    };

    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    if (this.state.blobImgInURLObject === null) {
      this.fetchThumbnail();
      const channelName = `App.Request.${this.props.document.request_slug}`;
      const eventName = '.App\\Events\\Document\\TicketInvalidatedEvent';

      if (this.props.document.type === 'travel') {
        SocketProviderService.subscribe(channelName)(eventName)((data) => {
          this.fetchThumbnail();
          this.setState({
            documentStatus: 'unprocessable',
            documentTicketInvalidated: true,
          });
        });
      }
    }
  }

  componentWillUnmount() {
    const channelName = `App.Request.${this.props.document.request_slug}`;
    const eventName = '.App\\Events\\Document\\TicketInvalidatedEvent';
    SocketProviderService.unsubscribe(channelName)(eventName);
  }

  fetchThumbnail() {
    if (typeof this.props.document.id === 'number') {
      this.setState({ loading: true });

      APIClient.getDocumentAsBlob('storage/documents/' + this.props.document.id + '/thumb/300/300')
        .then((response) => {
          this.setState({
            blobImgInURLObject: URL.createObjectURL(response.data),
            loading: false,
          });
        })
        .catch(() => {
          this.setState({ blobImgInURLObject: null, loading: false });
        });
    }
  }

  renderDocumentNumberComponent() {
    return <strong>{this.getDocumentNumber()}</strong>;
  }

  getDocumentNumber() {
    const {
      document: { document_number },
    } = this.props;

    return document_number;
  }

  renderGrossComponent() {
    const {
      document: { gross_sum_of_elements, currency, converted_gross_sum_of_elements },
      instanceCurrency,
      disableDelete,
    } = this.props;

    const finalGross =
      instanceCurrency !== currency ? converted_gross_sum_of_elements : gross_sum_of_elements;

    return (
      <span>
        <AmountFormatter amount={finalGross} /> {instanceCurrency}
      </span>
    );
  }

  getGrossTooltip() {
    const {
      document: { currency, gross_sum_of_elements, exchange_rate, converted_gross_sum_of_elements },
      instanceCurrency,
    } = this.props;

    let exchangeRate = exchange_rate;
    if (exchangeRate === null) {
      exchangeRate = converted_gross_sum_of_elements / gross_sum_of_elements;
    }
    if (instanceCurrency !== currency) {
      return (
        <div>
          <span>
            <AmountFormatter amount={gross_sum_of_elements} /> <span>{currency}</span>
          </span>
          <br />1 {currency} = <AmountFormatter amount={exchangeRate} format='0.0000' />{' '}
          {instanceCurrency}
        </div>
      );
    }

    return null;
  }

  renderDescriptionComponent() {
    const { document } = this.props;

    return (
      <span>{document.erp_accounting_number ? this.getErpId() : this.getDescriptionString()}</span>
    );
  }

  getErpId() {
    const { document } = this.props;
    return document.erp_accounting_number;
  }

  getDescriptionString() {
    const { document } = this.props;
    const provider = _.get(document, 'provider.name', '');
    const annotation = _.get(document, 'annotation', '');

    if (document['type'] === 'travel') {
      const ticketInvalidated = this.state.documentTicketInvalidated || document.ticket_invalidated;
      return (
        <div>
          {ticketInvalidated ? (
            <p style={{ color: 'red' }}>{trans('document.reservation-ticket-invalidated')}</p>
          ) : (
            <p>{annotation}</p>
          )}
        </div>
      );
    }

    if (!annotation) {
      return provider;
    }

    return annotation;
  }

  renderStatusIconComponent() {
    const {
      request: { status },
      document: { settled_at, accounted_at, type },
    } = this.props;

    if (type === 'confirmation' || type === 'travel') {
      return (
        <Icon
          type='checked_fill-square'
          xlg
          className='icon--inline icon--document-cover is-color-grey document-status-icon-ok'
        />
      );
    }

    if (
      status === STATUS_SETTLEMENT ||
      status === STATUS_TRIP ||
      status === STATUS_UPCOMING_TRIP ||
      status === STATUS_WAITING_FOR_ACCEPTANCE ||
      status === STATUS_ACCEPTANCE_OF_SETTLEMENT
    ) {
      if (settled_at != null) {
        return (
          <Icon
            type='checked_fill-square'
            xlg
            className='icon--inline icon--document-cover is-color-success document-status-icon-ok'
          />
        );
      }
    } else if (
      status === STATUS_ACCOUNTING ||
      status === STATUS_TRANSFERRED ||
      status === STATUS_FINISH
    ) {
      if (accounted_at != null) {
        return (
          <Icon
            type='checked_fill-square'
            xlg
            className='icon--inline icon--document-cover is-color-success'
          />
        );
      }
    }

    return (
      <Icon type='warning' xlg className='icon--inline icon--document-cover is-gradient-warning' />
    );
  }

  renderPaymentIconComponent() {
    const {
      document: { payment },
    } = this.props;

    if (payment === 'own') {
      return <Icon type='eurozl' xlg grey />;
    } else {
      return <Icon type='credit_card' xlg grey />;
    }
  }

  renderDocumentIcons() {
    const { document } = this.props;

    if (_.isEmpty(document['elements'])) {
      return null;
    }

    if (document['type'] === 'travel') {
      return null;
    }

    const iconsTypes = _.uniq(
      document['elements'].map((element) => EXPENSES_TYPES_TO_ICONS[element.type.slug]),
    );

    const expensesSum = document.elements.reduce(
      (acc, element) => parseFloat(element.gross) + acc,
      0.0,
    );
    if (document.gross > expensesSum) {
      iconsTypes.push(EXPENSES_TYPES_TO_ICONS['expense-private']);
    }

    return iconsTypes.map((type, i) => {
      return (
        <Icon
          type={type}
          grey
          className='icon--inline icon--document-cover'
          key={renderKey('icon', type, i)}
        />
      );
    });
  }

  renderInfo() {
    return {
      text: {
        row1: {
          component: this.renderDocumentNumberComponent(),
          tooltip: this.getDocumentNumber(),
          smartTruncateCSS: true,
        },
        row2: {
          component: this.renderGrossComponent(),
          tooltip: this.getGrossTooltip(),
        },
        row3: {
          component: this.renderDescriptionComponent(),
          smartTruncateCSS: true,
        },
      },
      icons: {
        icon1: {
          component: this.renderStatusIconComponent(),
        },
        icon2: {
          component: this.renderPaymentIconComponent(),
        },
        icon3: {
          components: this.renderDocumentIcons(),
        },
      },
    };
  }

  getDocumentLink(match, getRouteByName) {
    const {
      document,
      request: { status, type, slug },
      currentUser: {
        abilities: { isAccountant },
      },
      absoluteLink,
      readOnly,
    } = this.props;

    let url = '/documents/' + document['id'];
    let label = trans('document.settle');

    if (absoluteLink) {
      url = getRouteByName('main', 'requestDocument', { id: document.id, type, request: slug });
    }

    if (
      (status === STATUS_ACCOUNTING ||
        status === STATUS_TRANSFERRED ||
        status === STATUS_TRANSFER_ERROR ||
        status === STATUS_FINISH) &&
      isAccountant &&
      !readOnly
    ) {
      url = '/documents/' + document['id'] + '/account';
      label = trans('document.post');

      if (absoluteLink) {
        url = getRouteByName('main', 'requestAccountDocument', {
          id: document.id,
          type,
          request: slug,
        });
      }
    }

    if (!absoluteLink) {
      url = match.url + url;
    }

    return { url, label };
  }

  getContentBottom() {
    const {
      request: { status, abilities },
      document: { settled_at, accounted_at, type },
    } = this.props;

    if (type === 'travel' || type === 'confirmation') {
      return this.renderInfo();
    } else {
      if (
        status === STATUS_SETTLEMENT ||
        status === STATUS_TRIP ||
        status === STATUS_UPCOMING_TRIP
      ) {
        if (settled_at === null) {
          return {};
        }
      } else if (status === STATUS_ACCOUNTING) {
        if (accounted_at === null) {
          return {};
        }
      }

      return this.renderInfo();
    }
  }

  getButton() {
    const {
      request: { status, abilities },
      document: { settled_at, accounted_at, type },
      readOnly,
    } = this.props;

    const DocumentButton = () => (
      <RouteManager>
        {({ match, getRouteByName }) => {
          const link = this.getDocumentLink(match, getRouteByName);
          return (
            <Link to={link.url}>
              <Button primary xs>
                {link.label}
              </Button>
            </Link>
          );
        }}
      </RouteManager>
    );

    if (type !== 'travel' && type !== 'confirmation') {
      if (
        status === STATUS_SETTLEMENT ||
        status === STATUS_TRIP ||
        status === STATUS_UPCOMING_TRIP
      ) {
        if (settled_at === null && abilities.settle) {
          return <DocumentButton />;
        }
      } else if (status === STATUS_ACCOUNTING) {
        if (accounted_at === null && abilities.account) {
          return <DocumentButton />;
        }
      }
    }

    return null;
  }

  delete() {
    const { document, deleteDocument, disableDelete, request } = this.props;

    if (
      disableDelete ||
      document.self_generated ||
      document.readonly ||
      document.abilities.delete === false ||
      request.abilities.addAndDeleteDocuments === false
    ) {
      return null;
    }

    return () => deleteDocument(document);
  }

  render() {
    const { document, xs, sm, md, width, onDeleteDocument, readOnly } = this.props;
    const { loading } = this.state;
    const { type: documentType } = document;

    if (
      document.loader ||
      loading ||
      (documentType === 'travel' && this.state.documentStatus === 'processing')
    ) {
      return <DocumentCellLoader {...this.props} />;
    }

    if (document.failure) {
      return <DocumentCellFail {...this.props} />;
    }

    const imgWrapperStyle = {
      background: this.state.blobImgInURLObject ? `url('${this.state.blobImgInURLObject}')` : null,
    };

    const cellOuterClasses = classNames({
      'document-cell-outer': true,
      'document-cell-outer--unset-min-height': documentType === 'travel',
    });

    const isPdfToDisplay =
      documentType === 'accomodation' ||
      documentType === 'confirmation' ||
      documentType === 'accounting' ||
      documentType === 'travel';

    const imgWrapperClasses = classNames({
      'document-cell__img-wrapper': true,
      'document-cell__img-wrapper--position-top': isPdfToDisplay,
    });

    const shouldShowThumb = !!this.state.blobImgInURLObject;

    const contentTop = shouldShowThumb ? (
      <div className={imgWrapperClasses} style={imgWrapperStyle} />
    ) : (
      <div className={imgWrapperClasses}>
        <Icon type='document' />
      </div>
    );

    return (
      <RouteManager>
        {({ match }) => {
          const link = get(
            this.props,
            'link',
            this.getDocumentLink(match, this.props.getRouteByName),
          );

          return (
            <DocumentCellPresentation
              deleteFn={this.delete}
              onDelete={onDeleteDocument}
              className={cellOuterClasses}
              contentTop={contentTop}
              contentTopLink={link.url}
              contentBottom={this.getContentBottom()}
              button={this.getButton()}
              xs={xs}
              sm={sm}
              md={md}
              width={width}
            />
          );
        }}
      </RouteManager>
    );
  }
}

DocumentCell.propTypes = {
  document: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  getRouteByName: PropTypes.func.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  width: PropTypes.string,
  deleteDocument: PropTypes.func,
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  onDeleteDocument: PropTypes.func,
  absoluteLink: PropTypes.bool,
  readOnly: PropTypes.bool,
};

DocumentCell.defaultProps = {
  absoluteLink: false,
  readOnly: false,
};

export { DocumentCell };
export default { DocumentCell };
