import { useCallback, useEffect, useState } from 'react';
import APIClient from '../../../services/APIClient';
import { useSelector } from 'react-redux';
import { getMyCardTenantId } from '../../../store/app/user-profile';

export function useMyCardExport() {
  const [options, setOptions] = useState<Record<string, string>>({});
  const tenant = useSelector(getMyCardTenantId);

  const doExport = useCallback(
    (statements: string[], entries: string[], action: string) => {
      return APIClient.exportStatements(statements, entries, action, tenant);
    },
    [tenant],
  );

  useEffect(() => {
    APIClient.getExportOptionsForStatements(tenant).then((res) => setOptions(res));
  }, [tenant]);

  return { exportOptions: options, doExport };
}
