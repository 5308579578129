import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../containers'
import { Link } from 'react-router-dom'
import { getRouteByName } from '../../routes'
import { Col, Row } from '../../ui'
import APIClient from '../../services/APIClient'
import { fromJS, Map } from 'immutable'
import trans from '../../trans'
import { get, isArray, isEmpty } from 'lodash'
import moment from 'moment'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { makeSelectCurrentUser } from '../App/selectors'
import { createStructuredSelector } from 'reselect'
import Button from '../../components/ui/ButtonComponent'
import { Panel, PanelContent, PanelTitle } from '../../components/ui/Panel'
import { DocumentForm } from '../DocumentForm'
import { DocumentManager } from '../DocumentManager'
import { DocumentRules } from '../../components/DocumentRules'
import { RouteManager } from '../RouteManager'
import { getDocumentPageBreadcrumbs } from '../../breadcrumbs'
import ExpenseTypes from '../../components/DocumentPage/ExpenseTypes/ExpenseTypes'
import { getFormValues } from 'redux-form/immutable'
import { Loader } from '../../components/ui/LoadingOverlay/Loader'
import DocumentPreview from './DocumentPreview'
import ImagePreview from './ImagePreview'
import Sticky from '../../components/Sticky/Sticky'
import ReservationDetailsContainer from '../../components/Reservation/ReservationDetailsContainer'
import { getDocumentOffer, isCancellationInProgress } from '../../store/app/document-offer'
import store from '../../store'
import { AccountDimensionManager } from '../AccountDimensionManager'
import classNames from 'classnames'
import { fetchDocument, getDocument } from '../../store/app/document-account-page'
import { IDocument } from '../../types/document'

class DocumentPage extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      currencies: [],
      exchange_rate: null,
      exchanging: true,
      values: Map(),
      ready: false,
      errors: Map(),
      travel_elements: [],
      show_document_belongs_select: false,
      hints_array: [],
      forms: {},
      closeButtonClicked: false,
      // providers: [],
    }
  }

  getCloseRedirectUrl() {
    const pos = window.location.pathname.indexOf('/documents/')
    if (pos === -1 || !pos) {
      return getRouteByName('main', 'allRequests')
    }

    return window.location.pathname.slice(0, pos)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state['closeButtonClicked'] === true) {
      const { forms } = this.state
      const { pushHistory } = this.props
      if (Object.keys(forms).length === 0) {
        pushHistory(this.getCloseRedirectUrl())
      } else {
        const numLockedForms = Object.keys(forms)
          .map((key) => {
            return forms[key]
          })
          .filter((value) => value === true).length

        if (numLockedForms === 0) {
          pushHistory(this.getCloseRedirectUrl())
        }
      }
    }
  }

  formInProgressHandler = (formName) => {
    return ({ subject }) => {
      const { forms } = this.state

      subject.onUnlock(() => {
        forms[formName] = false
        this.setState({ forms })
      })
      subject.onLock(() => {
        forms[formName] = true
        this.setState({ forms })
      })
    }
  }

  formUnmountHandler = (formName) => {
    return () => {
      const { forms } = this.state
      delete forms[formName]
      this.setState({ forms })
    }
  }

  handleClickCloseButton = (e) => {
    e.preventDefault()
    this.setState({ closeButtonClicked: true })
  }

  isCurrentUserSettlementAcceptorOfDocument(document) {
    const { currentUser } = this.props

    let result = false
    if (isArray(document['request']['settlementAcceptors'])) {
      document['request']['settlementAcceptors'].forEach(({ id }) => {
        if (id === currentUser['id']) {
          result = true
        }
      })
    }

    return result
  }

  renderDocumentForm(document, travel_elements, default_currency, currencies, accountDimensions) {
    const { fetchDocument } = this.props

    const headerClasses = classNames({
      'text-uppercase':
        document.request.status === 'settlement' ||
        document.request.status === 'acceptance_of_settlement',
    })

    return (
      <Panel
        iconClass='document__close'
        closable={!(document.type === 'accounting' || document.type === 'confirmation')}
        onClose={this.handleClickCloseButton}
      >
        <PanelTitle>
          <strong className={headerClasses}>{trans('document.basic-info')}</strong>
        </PanelTitle>
        <PanelContent>
          {!isEmpty(document) && !isEmpty(currencies) && (
            <DocumentForm
              fetch={fetchDocument}
              document={document}
              travelElements={travel_elements}
              currencies={currencies}
              defaultCurrency={default_currency}
              accountDimensions={accountDimensions}
              componentDidMount={this.formInProgressHandler('document-form')}
              label=' '
              // providers={providers}
            />
          )}
        </PanelContent>
      </Panel>
    )
  }

  renderTypeOfExpenses(document: IDocument) {
    if (document['type'] === 'accounting' || document['type'] === 'confirmation') {
      const readOnly = !(
        document.gross !== null &&
        document.gross !== undefined &&
        document.gross !== 0
      )

      return <ExpenseTypes document={document} readOnly={readOnly} />
    }

    return null
  }

  getFormValues() {
    const { formValues } = this.props

    if (formValues instanceof Map) {
      return formValues.toJS()
    }

    return formValues
  }

  renderRightColumn(document, travel_elements, default_currency, currencies) {
    const formValues = this.getFormValues()

    return (
      <Fragment>
        <div>
          <AccountDimensionManager fetchMethod={'document'} fetchArgs={[document.id]}>
            {({ accountDimensions, isLoaded }) => {
              if (isLoaded) {
                return this.renderDocumentForm(
                  document,
                  travel_elements,
                  default_currency,
                  currencies,
                  accountDimensions,
                )
              }

              return null
            }}
          </AccountDimensionManager>

          {(get(formValues, 'type', null) === 'accounting' || document.type === 'confirmation') &&
            this.renderTypeOfExpenses(document)}
          {document && document.type === 'travel' && (
            <ReservationDetailsContainer document={document} />
          )}
        </div>

        {get(formValues, 'type', null) === 'accounting' && <DocumentRules document={document} />}

        <Row>
          <Col xs={12} is_pull_end>
            <Button primary onClick={this.handleClickCloseButton}>
              {this.isCurrentUserSettlementAcceptorOfDocument(document) &&
                trans('document.finish-document-edition-settlement-acceptor')}
              {!this.isCurrentUserSettlementAcceptorOfDocument(document) &&
                trans('document.finish-document-edition')}
            </Button>
          </Col>
        </Row>
      </Fragment>
    )
  }

  isPDF(fileType) {
    return fileType === 'pdf'
  }

  renderPreview(document, { style = {} } = {}) {
    if (this.isPDF(document.file_type)) {
      return (
        <div style={style}>
          <DocumentPreview
            id={document.id}
            name={document.name}
            type={document.type}
            requestSlug={document.request_slug}
          />
        </div>
      )
    } else {
      return (
        <div style={style}>
          <ImagePreview
            id={document.id}
            onLoad={(e) => {
              // this.setState({});
            }}
          />
        </div>
      )
    }
  }

  toggleImageStickyProp(document) {
    let preview = this.renderPreview(document, { style: { width: '100%' } })

    return (
      <div className='has-loader'>
        {document &&
          document.type === 'travel' &&
          this.props.offer &&
          (isCancellationInProgress(this.props.offer.status) ||
            document.status === 'processing') && <Loader />}
        {preview}
      </div>
    )
  }

  renderBreadcrumbs(document: IDocument) {
    return (
      <RouteManager>
        {({ match, getRouteByName }) => (
          <BreadCrumbs>
            {getDocumentPageBreadcrumbs(match, getRouteByName, document).map((item) => (
              <Link key={item.url} to={item.url}>
                {item.label}
              </Link>
            ))}
          </BreadCrumbs>
        )}
      </RouteManager>
    )
  }

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props

    return (
      <div className='document-page'>
        <Helmet title={trans('global.document')} />

        <DocumentManager documentId={id}>
          {({ document, currencies, travelElements, defaultCurrency }) => (
            <>
              {!document.id && <Loader />}
              {document.id && (
                <>
                  {this.renderBreadcrumbs(document)}
                  <section className='section section--no-padding section--no-border'>
                    <header className='section__header'>
                      <h1 className='h2 section__header-title'>
                        {trans('request.state_settlement')}
                      </h1>
                    </header>
                  </section>
                  <Row nowrap className={!document.abilities.edit ? 'read-only' : ''}>
                    <Col fill>
                      <Sticky offset={10}>{this.toggleImageStickyProp(document)}</Sticky>
                    </Col>
                    <Col fill style={{ minWidth: '500px', maxWidth: '500px', width: '100%' }}>
                      {this.renderRightColumn(
                        document,
                        travelElements,
                        defaultCurrency,
                        currencies,
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </DocumentManager>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  document: getDocument,
  offer: getDocumentOffer,
})

const mapDispatchToProps = (dispatch) => ({
  pushHistory: (url) => dispatch(push(url)),
  fetchDocument,
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withFormValues = connect((state) => ({
  formValues: getFormValues('document-form')(state),
}))

export default compose(withConnect, withFormValues)(DocumentPage)
