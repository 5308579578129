import React, { FunctionComponent } from 'react'

import './OfferMealIcon.scss'
import trans from '../../../trans'
import Icon from '../../ui/IconComponent'
import { Tooltip } from '../../Tooltip'
import { HotelOfferEntryOption } from '../../../types/hotel-offers'
import { MEAL_TYPES } from '../index'

interface Props {
  mealType: HotelOfferEntryOption['attributes']['mealType']
}

export const OfferMealIcon: FunctionComponent<Props> = ({ mealType }) => {
  const meal = Array.isArray(mealType) ? mealType[0] : mealType

  if (!meal || meal === 'RO') {
    return null
  }

  return (
    <Tooltip className='booked-hotel__room-icon' html={<span>{trans(MEAL_TYPES[meal])}</span>}>
      <Icon type='restaurant' />
    </Tooltip>
  )
}
