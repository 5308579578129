import React from 'react';

import ToggleableFilters from '../ToggleableFilters/ToggleableFilters';
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters';
import ToggleableSearchField from '../ToggleableFilters/ToggleableSearchField';

interface Props {
  filters: Record<string, unknown>;
  setFilter: (key: string, value: unknown) => void;
}

function AccountsPageFilters({ filters, setFilter }: Props) {
  const filtersCount = Object.keys(filters).length;

  return (
    <form>
      <ToggleableFilters filtersCount={filtersCount} hideIcon>
        {({ isOpen }) => (
          <>
            <ToggleableQuickFilters>
              <ToggleableSearchField
                value={filters['description']}
                onChange={(value) => {
                  setFilter('description', value);
                }}
              />
            </ToggleableQuickFilters>
          </>
        )}
      </ToggleableFilters>
    </form>
  );
}

export default AccountsPageFilters;
