import PropTypes from 'prop-types'
import React from 'react'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import BreadCrumbs from '../../containers/BreadCrumbs/index'
import { Link } from 'react-router-dom'
import { RouteManager } from '../../containers/RouteManager'
import pluralize from 'pluralize'
import { capitalize } from 'lodash'

class RequestBreadcrumb extends React.Component<any, any> {
  renderBreadcrumbs(match) {
    const { request } = this.props
    const { state, uid } = request
    let isSettlement =
      match.path === getRouteByName('main', `show${capitalize(request.type)}Settlement`, false)

    return (
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        {isSettlement ? (
          <Link to={getRouteByName('main', 'settlementRequestsList')}>
            {trans('global.settlements')}
          </Link>
        ) : (
          <Link to={getRouteByName('main', `${request.type}RequestsList`)}>
            {trans('global.trips')}
          </Link>
        )}
        {isSettlement && (
          <Link
            to={
              getRouteByName('main', 'settlementRequestsList') +
              '?filters=' +
              encodeURIComponent('types=' + request.type)
            }
          >
            {trans('global.' + pluralize.plural(request.type))}
          </Link>
        )}
        <Link to={match.url}>
          {trans(`request.${state}`)} {uid}
        </Link>
      </BreadCrumbs>
    )
  }

  render() {
    return <RouteManager>{({ match }) => this.renderBreadcrumbs(match)}</RouteManager>
  }
}

RequestBreadcrumb.propTypes = {
  request: PropTypes.object.isRequired,
}

export { RequestBreadcrumb }
export default { TripRequestBreadcrumb: RequestBreadcrumb }
