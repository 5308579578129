import React, { Component } from 'react';
import { trans } from '../../../trans';
import { StarsRatingField } from '../../ui/Form/StarsRatingField';
import { CheckboxField } from '../../ui/Form';
import FilterInput from './FilterInput';
import { Factory as ElementFactory } from '../../../models/timeline/Factory';
import { connect } from 'react-redux';
import { getFacilities } from '../../../store/app/hotels-booking/selectors';
import Button from '../../ui/ButtonComponent';
import { SwitchToMap } from '../SwitchToMap/SwitchToMap';
import { View } from '../../../widgets/RequestExpenseCommute/components/View';

class PureNavigation extends Component<any, any> {
  state = {
    parking: null,
    breakfast: null,
    others: null,
    refundable: null,
    standard: null,
    initialized: false,
  };

  componentDidMount() {
    const {
      hotelsBooking: {
        actions: { setFilters },
      },
    } = this.props;

    const filters = {
      parking: this.props.parking,
      others: this.props.others,
      standard: this.props.standard,
      refundable: this.props.refundable,
    };

    setFilters(filters);

    this.setState({
      ...filters,
      breakfast: this.props.breakfast,
      initialized: true,
    });
  }

  changeStandard = (value) => {
    const current = this.state.standard;
    let newValue = value;

    if (current === newValue) {
      newValue = null;
    }

    this.change('standard', newValue);
  };

  change = (prop, value) => {
    const {
      hotelsBooking: {
        actions: { setFilter },
      },
    } = this.props;

    setFilter(prop, value);

    this.setState({
      [prop]: value,
    });
  };

  changeFacility = (prop, value) => {
    const {
      hotelsBooking: {
        actions: { isChanged, setFacility },
      },
    } = this.props;

    setFacility(prop, value);

    this.setState({
      [prop]: value,
    });
  };

  search = () => {
    const {
      hotelsBooking: {
        actions: { searchFilters },
      },
    } = this.props;

    searchFilters();
  };

  render() {
    const { standard, breakfast, parking, others, refundable, initialized } = this.state;
    const { showMap, coordinates, onMapClick } = this.props;

    if (!initialized) {
      return null;
    }

    return (
      <div className='hotels-dialog__navigation'>
        <div className='hotels-dialog__navigation-map'>
          {showMap && (
            <SwitchToMap height={180} width={320} coordinates={coordinates} onClick={onMapClick} />
          )}
        </div>
        <div className='hotels-dialog__navigation-filters'>
          <div className='hotels-dialog__checkboxes-wrapper'>
            <div className='hotels-dialog__filters'>{trans('accommodation.filters')}</div>

            <div className='hotels-dialog__checkboxes hotels-dialog__form-control'>
              <CheckboxField
                label={trans('accommodation.breakfast')}
                onChange={(value) => this.changeFacility('breakfast', value)}
                checked={breakfast}
              />

              <CheckboxField
                label={trans('accommodation.parking')}
                onChange={(value) => this.change('parking', value)}
                checked={parking}
              />

              <CheckboxField
                label={trans('accommodation.refundable-offer')}
                onChange={(value) => this.change('refundable', value)}
                checked={refundable}
              />
            </div>
          </div>

          <div className='hotels-dialog__form-control hotels-dialog__form-control--stars'>
            <div className='accommodation__input-container--stars'>
              <label className='accommodation__input-label'>
                {trans('accommodation.number-of-stars')}
              </label>
              <StarsRatingField
                stars={5}
                input={{
                  value: standard,
                  onChange: this.changeStandard,
                }}
              />
            </div>
          </div>

          <div className='hotels-dialog__input-filter-wrapper hotels-dialog__navigation-item'>
            <FilterInput
              onChange={(value) => this.change('others', value)}
              label={trans('accommodation.other-filters')}
              initValue={others}
            />
          </div>
        </div>
        <div className='hotels-dialog__input-filter-wrapper hotels-dialog__navigation-filters'>
          <div className='hotels-dialog__action'>
            <Button primary xxs onClick={this.search}>
              {trans('global.search')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

PureNavigation.propTypes = {};

const mapStateToProps = (state, props) => {
  const {
    hotelsBooking: {
      element,
      selectors: { filters },
    },
  } = props;
  const accommodation = ElementFactory.create(element);
  const facilities = getFacilities(accommodation.key)(state);
  const standard = accommodation.standard;
  const breakfast = facilities.breakfast;
  const parking = accommodation.parking;
  const others = accommodation.others;

  return {
    standard: filters.standard || standard,
    breakfast: filters.breakfast || breakfast,
    parking: filters.parking || parking,
    others: filters.others || others,
    refundable: filters.refundable,
  };
};

const Navigation = connect(mapStateToProps)(PureNavigation);

export default Navigation;
export { Navigation };
