import React, { Component } from 'react';
import Button from '../ui/ButtonComponent';
import Icon from '../ui/IconComponent';
import { Scrollbars } from 'react-custom-scrollbars-2';
import _, { get } from 'lodash';
import classNames from 'classnames';
import { Tooltip } from '../Tooltip';

import {
  BOOKING_BOOKED_WITHOUT_TICKET,
  BOOKING_FAILED,
  BOOKING_WAITING_FOR_VERIFY,
  BOOKING_NEW,
  BOOKING_OFFER_CHANGED,
  BOOKING_PROCESS_TOO_LONG,
  BOOKING_SUCCESS,
  BOOKING_WAITING_FOR_ACCEPTATION,
  BOOKING_WAITING_FOR_CONFIRM,
  BOOKING_WAITING_FOR_TICKET,
  BOOKING_YA_RE_CHECK,
} from '../../store/app/hotels-booking';
import { trans } from '../../trans';
import Facilities from './Facilities';
import { BOOKING_EXPIRED } from '../../store/app/trains-booking';
import { MEAL_TYPES, ROOM_TYPES } from './index';
import Stars from './Stars';
import OptionBrokenRules from './OptionBrokenRules';
import NoticeLoader from '../ui/LoadingOverlay/NoticeLoader';
import { Agreement } from './Reservation/Agreement';
import { Ability } from '../RequestPageCommon/Ability/Ability';
import { BOOKING_CANCELLATION_FAILED } from '../../store/app/flights-booking';
import { CorporateRate } from './CorporateRate';
import { RoomTypeIcon } from './RoomTypeIcon/RoomTypeIcon';
import { CancelInfoIcon } from './CancelInfoIcon/CancelInfoIcon';
import { OfferMealIcon } from './OfferMealIcon/OfferMealIcon';

class SingleOffer extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      valuated: false,
      booked: false,
      // contentColumnHeight: 'auto'
    };

    this.contentColumnRef = React.createRef();
  }

  componentWillUnmount() {
    const {
      hotelsBooking: {
        actions: { unsubscribeReservationStatus },
      },
    } = this.props;

    unsubscribeReservationStatus();
  }

  componentDidMount = () => {
    const {
      hotelsBooking: {
        selectors: { selectedOption },
      },
      reservation,
    } = this.props;
    this.setState({ isOpen: !reservation || selectedOption.booking !== BOOKING_SUCCESS });
  };

  getPhotos = () => {
    const {
      hotelsBooking: {
        selectors: { selectedOffer: offer },
      },
    } = this.props;
    return get(offer, 'attributes.photos', []);
  };

  searchAnotherOne = (e) => {
    e.preventDefault();
    const { hotelsBooking } = this.props;
    hotelsBooking.actions.searchAnotherOne(
      hotelsBooking.request,
      hotelsBooking.element,
      hotelsBooking.selectors.uuid,
    );
  };

  bookOffer = (e) => {
    e.preventDefault();
    const {
      hotelsBooking: {
        actions: { bookOffer },
        selectors: { uuid },
      },
    } = this.props;

    return bookOffer(uuid);
  };

  nextPhoto = (e) => {
    e.stopPropagation();

    this.setState({ photoIndex: Math.min(this.state.photoIndex + 1, this.getPhotos().length - 1) });
  };

  prevPhoto = (e) => {
    e.stopPropagation();

    this.setState({ photoIndex: Math.max(this.state.photoIndex - 1, 0) });
  };

  renderGallery = () => {
    const {
      hotelsBooking: {
        selectors: { selectedOffer: offer },
      },
    } = this.props;

    return (
      <div className='booked-hotel__gallery'>
        <div className='booked-hotel__image-wrapper'>
          {this.state.photoIndex > 0 && (
            <button
              className='booked-hotel__gallery-btn booked-hotel__gallery-btn--prev'
              type='button'
              onClick={this.prevPhoto}
            />
          )}

          <img
            className='booked-hotel__image'
            src={get(this.getPhotos(), this.state.photoIndex, '')}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/no-images.png';
            }}
          />

          {this.state.photoIndex < this.getPhotos().length - 1 && (
            <button
              className='booked-hotel__gallery-btn booked-hotel__gallery-btn--next'
              type='button'
              onClick={this.nextPhoto}
            />
          )}
        </div>
      </div>
    );
  };

  getOptionStatus = () => {
    const {
      hotelsBooking: {
        selectors: { selectedOption },
      },
    } = this.props;
    return get(selectedOption, 'booking', null);
  };

  isWaitingStatus = () => {
    const booking = this.getOptionStatus();
    return (
      booking === BOOKING_WAITING_FOR_CONFIRM ||
      booking === BOOKING_WAITING_FOR_VERIFY ||
      booking === BOOKING_NEW ||
      booking === BOOKING_WAITING_FOR_ACCEPTATION ||
      booking === BOOKING_YA_RE_CHECK ||
      booking === BOOKING_WAITING_FOR_TICKET
    );
  };

  renderBookingButton = () => {
    const {
      hotelsBooking: {
        selectors: { isValuating, hasAgreement },
      },
    } = this.props;

    if (this.isWaitingStatus()) {
      return null;
    }

    if (isValuating) {
      return (
        <div className='booking-hotel__loader-wrapper'>
          <p className='booking-hotel__reservation-message'>
            {trans('hotels-booking.waiting-for-valuate')}
          </p>
          <div className='booking-hotel__loader component-loader__loader component-loader__loader--small' />
        </div>
      );
    }
    if (this.getOptionStatus() === BOOKING_FAILED || this.getOptionStatus() === BOOKING_EXPIRED) {
      return null;
    }

    if (
      this.getOptionStatus() === BOOKING_SUCCESS ||
      this.getOptionStatus() === BOOKING_BOOKED_WITHOUT_TICKET
    ) {
      return (
        <p className='booking-hotel__reservation-message'>
          {trans('hotels-booking.cancel-message')}
        </p>
      );
    }

    if (this.getOptionStatus() === BOOKING_PROCESS_TOO_LONG) {
      return (
        <div className='booking-hotel__reservation-message booking-hotel__reservation-message--long'>
          <Icon type='warning' className='is-gradient-warning' />
          <span>{trans('trains-booking.booking-process-too-long')}</span>
        </div>
      );
    }

    const button = (
      <Button
        primary
        disabled={!hasAgreement}
        className='booked-hotel__button booked-hotel__button--save'
        onClick={this.bookOffer}
      >
        {trans('trains-booking.book')}
      </Button>
    );

    if (!hasAgreement) {
      return (
        <Tooltip html={<span>{trans('global.booking-agreement-tooltip')}</span>}>{button}</Tooltip>
      );
    }

    return button;
  };

  renderAgreement = () => {
    const { hotelsBooking } = this.props;
    const {
      selectors: { isValuating },
    } = hotelsBooking;
    const optionStatus = this.getOptionStatus();

    if (
      !isValuating &&
      !this.isWaitingStatus() &&
      optionStatus !== BOOKING_FAILED &&
      optionStatus !== BOOKING_EXPIRED &&
      optionStatus !== BOOKING_SUCCESS &&
      optionStatus !== BOOKING_BOOKED_WITHOUT_TICKET &&
      optionStatus !== BOOKING_PROCESS_TOO_LONG
    )
      return <Agreement hotelsBooking={hotelsBooking} />;

    return null;
  };

  renderReservationNotice = () => {
    if (this.isWaitingStatus()) {
      return (
        <div className='booking-hotel__reservation-notice'>
          <NoticeLoader
            loaderText={trans('hotels-booking.reservation-waiting-loader')}
            description={trans('hotels-booking.reservation-waiting-notice')}
          />
        </div>
      );
    }
    return null;
  };

  renderSaveElementButton = () => {
    return (
      <Button primary type='submit' className='booked-hotel__button booked-hotel__button--save'>
        {trans('global.save')}
      </Button>
    );
  };

  renderSearchAnotherOneButton = () => {
    const {
      reservation,
      hotelsBooking: {
        selectors: { isValuating },
      },
    } = this.props;

    if (
      ([null, BOOKING_EXPIRED, BOOKING_FAILED].indexOf(this.getOptionStatus()) !== -1 ||
        !reservation) &&
      !isValuating
    ) {
      return (
        <Button
          outline
          className='booked-hotel__button booked-hotel__button--another'
          onClick={this.searchAnotherOne}
        >
          {trans('trains-booking.search-another-one')}
        </Button>
      );
    }

    return null;
  };

  renderTravelerInfo = () => {
    const {
      hotelsBooking: {
        selectors: {
          roomAllocations,
          totalPaxes,
          selectedOffer: { nights },
        },
      },
    } = this.props;

    if (totalPaxes <= 1) {
      return null;
    }

    return (
      <p className='booked-hotel__commission-message'>
        {trans('hotels-booking.reservation-short-info', {
          paxes: totalPaxes,
          rooms: roomAllocations.length,
          nights: nights,
        })}
      </p>
    );
  };

  renderCommissionMessage = () => {
    const {
      hotelsBooking: {
        selectors: { selectedOption },
      },
    } = this.props;
    const commission = get(selectedOption, 'reservation_fee.formatted', '0,00 zł').toLowerCase();

    if (commission === '0,00 zł') {
      return <p className='booked-hotel__commission-message' />; //go to line 348, :see_no_evil:
    }

    if (this.getOptionStatus() !== BOOKING_SUCCESS) {
      return (
        <p className='booked-hotel__commission-message booked-hotel__commission-message--fixed-height'>
          {trans('hotels-booking.commission-message', {
            commission,
          })}
        </p>
      );
    }

    return null;
  };

  renderStatus = () => {
    const {
      hotelsBooking: {
        selectors: { selectedOffer: offer },
      },
    } = this.props;
    const statusClass = classNames({
      'booked-hotel__status': true,
      'booked-hotel__status--success': this.getOptionStatus() === BOOKING_SUCCESS,
      'booked-hotel__status--warning': this.getOptionStatus() === BOOKING_OFFER_CHANGED,
      'booked-hotel__status--error':
        this.getOptionStatus() === BOOKING_FAILED ||
        this.getOptionStatus() === BOOKING_EXPIRED ||
        this.getOptionStatus() === BOOKING_CANCELLATION_FAILED,
    });

    let label = null;

    if (this.getOptionStatus() === BOOKING_OFFER_CHANGED) {
      label = trans('hotels-booking.offer-changed');
    }

    if (this.getOptionStatus() === BOOKING_FAILED) {
      label = trans('trains-booking.booking-failed');
    }

    if (this.getOptionStatus() === BOOKING_EXPIRED) {
      label = trans('trains-booking.booking-expired');
    }

    if (this.getOptionStatus() === BOOKING_SUCCESS) {
      label = trans('trains-booking.booking-success');
    }

    if (offer && offer.errorMessageSlug) {
      label = trans(offer.errorMessageSlug);
    }

    if (label) {
      return (
        <span className={statusClass}>
          <span className='booked-hotel__status-message'>{label}</span>
        </span>
      );
    }

    return null;
  };

  render() {
    const {
      hotelsBooking: {
        selectors: { selectedOffer: offer, selectedOption: option },
        request: { abilities },
      },

      reservation,
    } = this.props;

    const hotelFacilities = get(offer, 'attributes.facilities.hotel', null);
    const facilities = get(offer, 'attributes.facilities.facilities', []);
    const name = get(offer, 'attributes.name', '');
    const stars = get(offer, 'attributes.stars', null);
    const address = get(offer, 'attributes.address', '');
    const readOnly = abilities.view && !abilities.edit && !abilities.bookOffers;

    return (
      <div className='booked-hotel'>
        <span className='booked-hotel__selected-offer-heading'>
          {trans('trains-booking.selected-offer')}
        </span>

        {/*<div className="booked-hotel__row" style={{ gridTemplateRows: contentColumnHeight }}>*/}
        <div className='booked-hotel__row'>
          <div className='booked-hotel__column'>{this.renderGallery()}</div>

          <div className='booked-hotel__column'>
            <div className='booked-hotel__column-wrapper' ref={this.contentColumnRef}>
              <div className='booked-hotel__header'>
                <div>
                  <div className='booked-hotel__name'>
                    <span>{_.startCase(_.toLower(name))}</span>

                    <div className='booked-hotel__name-icons'>
                      <div className='booked-hotel__name-stars'>
                        <Stars stars={stars} />
                      </div>

                      <CorporateRate option={option} />
                    </div>
                  </div>

                  <a
                    target='_blank'
                    href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                    className='booked-hotel__address'
                    rel='noreferrer'
                  >
                    {address}
                  </a>
                </div>
                {this.renderStatus()}
              </div>

              <div className='booked-hotel__offer-wrapper'>
                <div className='booked-hotel__offer-icons'>
                  <div className='booked-hotel__offer-facilities'>
                    <Facilities facilities={facilities} />
                  </div>

                  <div className='booked-hotel__room-icons'>
                    <RoomTypeIcon roomType={option.attributes.roomType} />

                    <div>
                      <CancelInfoIcon cancelDate={option.cancelDate} />
                      <OfferMealIcon mealType={option.attributes.mealType} />
                    </div>
                  </div>
                </div>
              </div>

              <div className='booked-offer__details'>
                <span className='booked-offer__detail-title h2'>
                  {trans('hotels-booking.description')}
                </span>
                <Scrollbars style={{ height: 130 }}>
                  <div className='booked-offer__detail'>
                    <p
                      className='booked-offer__detail-copy'
                      dangerouslySetInnerHTML={{ __html: offer.attributes.description }}
                    />
                  </div>

                  {!!hotelFacilities && (
                    <div className='booked-offer__detail'>
                      <span className='booked-offer__detail-title h2'>
                        {trans('hotels-booking.equipment')}
                      </span>
                      <p
                        className='booked-offer__detail-copy'
                        dangerouslySetInnerHTML={{ __html: hotelFacilities }}
                      />
                    </div>
                  )}
                </Scrollbars>
              </div>

              <div className='booked-hotel__footer'>
                <div className='booked-hotel__price'>
                  <OptionBrokenRules option={option} />
                  <span className='price-amount'>
                    {get(option, 'amount.formatted', '0,00 zł').toLowerCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='booked-hotel__row'>
          <div className='booked-hotel__column booked-hotel__messages'>
            {this.renderTravelerInfo()}
            {this.renderCommissionMessage()}

            {!readOnly && (
              <Ability ability={['edit', 'bookOffers']} comparator='or'>
                <div className='booked-hotel__agreement-message'>
                  {reservation && this.renderAgreement()}
                </div>

                <div className='booked-hotel__buttons'>
                  {this.renderSearchAnotherOneButton()}
                  {reservation && this.renderBookingButton()}
                  {!reservation && this.renderSaveElementButton()}
                </div>
              </Ability>
            )}
          </div>
        </div>

        {this.renderReservationNotice()}
      </div>
    );
  }
}

SingleOffer.propTypes = {};

export default SingleOffer;
export { SingleOffer };
