// initial state
import { createSlice } from '@reduxjs/toolkit';
import { Map } from 'immutable';
import { includes } from 'lodash';
import moment from 'moment';
import { change as changeForm } from 'redux-form';

import { config } from '../../../config';
import { TRAVEL_EMPTY, TRAVEL_TARGET_POINT } from '../../../constants/travel';
import { Factory, Factory as ElementFactory } from '../../../models/timeline/index';
import APIClient from '../../../services/APIClient';
import store from '../../../store';
import { lockWaitingForWebSocketEvent, setIsWaitingForWebSocketEventStop } from '../trip-request';

import AccommodationSuggester from './services/accommodation-suggester';

const ADDITIONAL = 'additional';
const PRIMARY = 'primary';

const getInitialState = () => {
  return {
    elements: {
      data: [
        ElementFactory.create({
          type: TRAVEL_TARGET_POINT,
          draft: true,
          isOpen: true,
          acceptance_source: PRIMARY,
        }),
      ],
    },
    additionalElements: {
      data: [],
    },
    recomposition: true,
    recomposed: null,
    dirty: false,
  };
};

export const createModule = ({ mountPoint }) => {
  // constants
  const MOUNT_POINT = mountPoint;

  // composed actions
  const setElements = (data) => (dispatch) => {
    dispatch(slice.actions.startRecomposition());
    dispatch(slice.actions.setElements(data));

    data.forEach((element) => {
      dispatch(slice.actions.addReturnElement(element));
    });

    dispatch(slice.actions.sortElements());
    dispatch(slice.actions.stopRecomposition());
  };

  const saveElement = (request, values, element) => (dispatch) => {
    dispatch(slice.actions.startRecomposition());

    if (values instanceof Map) {
      values = values.toJS();
    }

    const action = element.draft ? createElementRequest : updateElementRequest;

    return dispatch(action(request, values, element)).then(
      async (response) => {
        dispatch(slice.actions.removeReturnElement(element));
        dispatch(slice.actions.addReturnElement(response));
        dispatch(slice.actions.addAccommodationSuggestion({ current: response, past: element }));
        await dispatch(updateListOrder(request));
        dispatch(slice.actions.stopRecomposition());

        // when create new element, ID of element is changing, so need to update the redux form
        dispatch(changeForm(element.key, 'id', response.id));
        dispatch(changeForm(element.key, 'draft', false));

        return ElementFactory.create(response);
      },
      (alerts) => {
        dispatch(slice.actions.stopRecomposition());

        throw alerts;
      },
    );
  };

  const deleteElement = (request, element) => (dispatch) => {
    dispatch(slice.actions.startRecomposition());
    return new Promise((resolve, reject) => {
      dispatch(removeElementRequest(request, element, false))
        .then(async (response) => {
          await dispatch(updateListOrder(request));
          dispatch(slice.actions.stopRecomposition());
          resolve(response);
        })
        .catch((error) => {
          dispatch(slice.actions.stopRecomposition());
          reject(error);
        });
    });
  };

  const getPrivateAccommodationCost = (element, requestSlug) => (dispatch) => {
    return APIClient.getPrivateAccommodationCost(element, requestSlug);
  };

  const addTargetPoint = () => (dispatch) => {
    dispatch(slice.actions.startRecomposition());
    dispatch(slice.actions.addTargetPoint());
    dispatch(slice.actions.stopRecomposition());
  };

  const addFirstElement = () => (dispatch) => {
    dispatch(slice.actions.startRecomposition());
    dispatch(slice.actions.addFirstElement());
    dispatch(slice.actions.stopRecomposition());
  };

  const clearOffer = () => (dispatch) => {
    dispatch(slice.actions.startRecomposition());
    dispatch(slice.actions.clearOffer());
    dispatch(slice.actions.stopRecomposition());
  };

  const addElement =
    (index, acceptance_source = PRIMARY) =>
    (dispatch) => {
      dispatch(slice.actions.startRecomposition());
      dispatch(slice.actions.addElement({ index, acceptance_source }));
      dispatch(slice.actions.stopRecomposition());
    };

  const updateElementSearchUuid = (key, search_uuid) => (dispatch) => {
    dispatch(
      slice.actions.updateElementSearchUuid({
        key,
        search_uuid,
      }),
    );
  };

  const changeElementType = (element, type) => (dispatch) => {
    dispatch(slice.actions.startRecomposition());
    dispatch(
      slice.actions.changeElementType({
        element,
        type,
      }),
    );
    dispatch(slice.actions.stopRecomposition());
  };

  const changeElementTypeAtIndex =
    (index, type, acceptance_source = PRIMARY) =>
    (dispatch, state) => {
      const elements =
        acceptance_source === PRIMARY ? getElements(state()) : getAdditionalElements(state());

      dispatch(slice.actions.startRecomposition());

      dispatch(
        slice.actions.changeElementType({
          element: elements[index],
          type,
        }),
      );
      dispatch(slice.actions.stopRecomposition());
    };

  // private actions
  const createElementRequest = (request, values, element) => (dispatch) => {
    return new Promise((resolve, reject) => {
      return APIClient.createTravelElement(request, values)
        .then((response) => {
          const data = {
            ...response.data,
            search_uuid: response.data.search_uuid || element.search_uuid,
            offer_uuid: response.data.offer_uuid ? response.data.offer_uuid : values.offer_uuid,
            target_real_arrival_at: response.data.target_real_arrival_at
              ? response.data.target_real_arrival_at
              : values.target_real_arrival_at,
            target_real_departure_at: response.data.target_real_departure_at
              ? response.data.target_real_departure_at
              : values.target_real_departure_at,
            return_real_arrival_at: response.data.return_real_arrival_at
              ? response.data.return_real_arrival_at
              : values.return_real_arrival_at,
            return_real_departure_at: response.data.return_real_departure_at
              ? response.data.return_real_departure_at
              : values.return_real_departure_at,
          };

          dispatch(lockWaitingForWebSocketEvent(response.lock_uuid));
          dispatch(
            slice.actions.updateElement({
              prevValues: element,
              nextValues: { ...data, draft: false, scrollToElement: true },
            }),
          );
          resolve(data);
        })
        .catch((response) => {
          dispatch(setIsWaitingForWebSocketEventStop());
          reject(response.alerts);
        });
    });
  };

  const updateElementRequest = (request, values, element) => (dispatch) => {
    return new Promise((resolve, reject) => {
      return APIClient.updateTravelElement(request, element.id, values)
        .then((response) => {
          const data = {
            ...response.data,
            search_uuid: response.data.search_uuid || element.search_uuid,
            offer_uuid: response.data.offer_uuid ? response.data.offer_uuid : values.offer_uuid,
            target_real_arrival_at: response.data.target_real_arrival_at
              ? response.data.target_real_arrival_at
              : values.target_real_arrival_at,
            target_real_departure_at: response.data.target_real_departure_at
              ? response.data.target_real_departure_at
              : values.target_real_departure_at,
            return_real_arrival_at: response.data.return_real_arrival_at
              ? response.data.return_real_arrival_at
              : values.return_real_arrival_at,
            return_real_departure_at: response.data.return_real_departure_at
              ? response.data.return_real_departure_at
              : values.return_real_departure_at,
          };

          dispatch(lockWaitingForWebSocketEvent(response.lock_uuid));
          dispatch(
            slice.actions.updateElement({
              prevValues: element,
              nextValues: data,
            }),
          );

          resolve(data);
        })
        .catch((response) => {
          dispatch(setIsWaitingForWebSocketEventStop());
          reject(response.alerts);
        });
    });
  };

  const removeElementRequest =
    (request, element, silent = false) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        if (element.draft || element.type === TRAVEL_EMPTY) {
          dispatch(slice.actions.removeElement(element));
          resolve();
        } else if (element.type) {
          APIClient.removeTravelElement(request, element, silent)
            .then((response) => {
              dispatch(lockWaitingForWebSocketEvent(response.lock_uuid));
              dispatch(slice.actions.removeElement(element));
            })
            .then((resposne) => resolve(resposne))
            .catch((error) => reject(error));
        } else {
          dispatch(setIsWaitingForWebSocketEventStop());
          resolve();
        }
      });
    };

  const updateListOrder = (request) => (dispatch) => {
    const dateOnly = (item) => createElement(item).getStartDate();
    const sortByDate = (a, b) => toUnix(a) - toUnix(b);
    const toUnix = (item) => {
      const element = createElement(item);
      const date = element.virtual ? element.getEndDate() : element.getStartDate();

      return moment(date, config.apiDateTimeFormat).unix();
    };

    const elements = getElements(store.getState());
    const sorted = elements.filter(dateOnly).sort(sortByDate);

    const order = sorted.map((element, index) => {
      let weight = {
        type: element.type,
        id: element.id,
      };

      if (element.virtual) {
        weight['return_weight'] = index;
      } else {
        weight['weight'] = index;
      }

      return weight;
    });

    return APIClient.updateTimelineWeight(request.slug, order)
      .then((res) => {
        dispatch(slice.actions.setBaseElements(sorted));
        dispatch(lockWaitingForWebSocketEvent(res.lock_uuid));

        return res;
      })
      .catch((e) => {
        dispatch(setIsWaitingForWebSocketEventStop());
      });
  };

  // selectors
  const getState = (state) => {
    return state.get(MOUNT_POINT);
  };

  const getElements = (state, excludedTypes = []) => {
    return getElementsByAcceptanceSource(PRIMARY)(state, excludedTypes);
  };

  const getAdditionalElements = (state, excludedTypes = []) => {
    return getElementsByAcceptanceSource(ADDITIONAL)(state, excludedTypes);
  };

  const getElementsByAcceptanceSource =
    (acceptance_source = PRIMARY) =>
    (state, excludedTypes = []) => {
      let elements =
        acceptance_source === ADDITIONAL
          ? getState(state).additionalElements.data
          : getState(state).elements.data;

      if (excludedTypes.length) {
        elements = elements.filter((e) => !includes(excludedTypes, e.type));
      }

      return elements.map(ElementFactory.create);
    };

  const isRecomposition = (state) => {
    return getState(state).recomposition;
  };

  const getRecomposed = (state) => {
    return getState(state).recomposed;
  };

  const createElement = (element) => element;
  const filterByAcceptanceSource = (type) => (element) => element.acceptance_source === type;
  const isPrimary = (element) => element.acceptance_source === PRIMARY;
  const isAdditional = (element) => element.acceptance_source === ADDITIONAL;

  const slice = createSlice({
    name: MOUNT_POINT,
    initialState: getInitialState(),
    reducers: {
      reset: () => getInitialState(),
      setBaseElements: (state, { payload: elements }) => {
        if (!elements.length) {
          return state;
        }

        state.elements.data = elements.filter(filterByAcceptanceSource(PRIMARY)).map(createElement);

        state.recomposition = false;
      },
      setElements: (state, { payload: elements }) => {
        if (!elements.length) {
          return state;
        }

        state.elements.data = elements.filter(filterByAcceptanceSource(PRIMARY)).map(createElement);

        state.additionalElements.data = elements
          .filter(filterByAcceptanceSource(ADDITIONAL))
          .map(createElement);

        state.recomposition = false;
      },
      startRecomposition: (state) => {
        state.recomposition = true;
      },
      stopRecomposition: (state) => {
        state.recomposition = false;
        state.recomposed = Date.now();
      },
      updateElementSearchUuid: (state, action) => {
        const { key, search_uuid } = action.payload;
        const update = (element) => (element.key === key ? { ...element, search_uuid } : element);

        state.elements.data = state.elements.data.map(update);
        state.additionalElements.data = state.additionalElements.data
          .map(update)
          .map(createElement);
      },
      updateElement: (state, { payload: { prevValues, nextValues, silent } }) => {
        const update = (element) => {
          const elementExist = element.uuid === prevValues.uuid && element.type === prevValues.type;

          if (elementExist) {
            return {
              ...element,
              ...nextValues,
              search_uuid: nextValues.search_uuid ? nextValues.search_uuid : element.search_uuid,
              draft: silent ? prevValues.draft : nextValues.draft,
              dirty: silent ? prevValues.dirty : nextValues.dirty,
              isOpen: silent ? prevValues.isOpen : nextValues.isOpen,
            };
          }

          return element;
        };

        state.elements.data = state.elements.data.map(update).map(createElement);
        state.additionalElements.data = state.additionalElements.data
          .map(update)
          .map(createElement);
      },
      clearOffer: (state, { payload: { element } }) => {
        const update = (e) => {
          const elementExist = element.id === e.id && element.type === e.type;

          if (elementExist) {
            delete e.id;
            delete e.amount;
            delete e.converted_amount;
            delete e.accounted_amount;
            delete e.search_uuid;
            delete e.dirty;
            delete e.isOpen;
            delete e.draft;

            if (e.virtual) {
              return null;
            }

            return Factory.create({
              ...e,
              draft: true,
              isOpen: true,
            });
          }

          return e;
        };

        state.elements.data = state.elements.data.map(update).filter(Boolean).map(createElement);
        state.additionalElements.data = state.additionalElements.data
          .map(update)
          .filter(Boolean)
          .map(createElement);
      },
      removeElement: (state, { payload: element }) => {
        const filterOut = (item) => item.id !== element.id || item.type !== element.type;

        state.elements.data = state.elements.data.filter(filterOut);
        state.additionalElements.data = state.additionalElements.data.filter(filterOut);
      },
      addElement: (state, { payload: { index, acceptance_source = PRIMARY } }) => {
        const element = ElementFactory.create({ type: TRAVEL_EMPTY, acceptance_source });

        if (acceptance_source === PRIMARY) {
          state.elements.data.splice(index, 0, element);
        } else {
          state.additionalElements.data.splice(index, 0, element);
        }
      },
      addFirstElement: (state) => {
        if (state.elements.data.length > 1) {
          return state;
        }

        const element = ElementFactory.create({ type: TRAVEL_EMPTY, acceptance_source: PRIMARY });

        state.elements.data.splice(0, 0, element);
      },
      changeElementType: (state, { payload: { type, element } }) => {
        const change = (item) =>
          item.id === element.id && item.type === element.type ? { ...newElement } : item;
        const getElement = (type) =>
          type
            ? ElementFactory.create({
                type,
                acceptance_source: element.acceptance_source,
                searcher_disabled: element.searcher_disabled,
                isOpen: true,
                draft: true,
                dirty: true,
              })
            : { ...element, type: TRAVEL_EMPTY };
        const newElement = getElement(type);

        state.elements.data = state.elements.data.map(change).map(createElement);

        state.additionalElements.data = state.additionalElements.data
          .map(change)
          .map(createElement);
      },
      sortElements: (state) => {
        state.elements.data.sort((a, b) => {
          const weightA = a['virtual'] ? a['return_weight'] : a['weight'];
          const weightB = b['virtual'] ? b['return_weight'] : b['weight'];

          if (weightA < weightB) {
            return -1;
          }

          if (weightA > weightB) {
            return 1;
          }

          return 0;
        });
      },
      addAccommodationSuggestion: (state, action) => {
        const { current, past } = action.payload;
        const suggester = new AccommodationSuggester(state.elements.data, current, past);

        state.elements.data = suggester.getStateWithSuggestions();
      },
      addReturnElement: (state, action) => {
        const element = action.payload;

        if (!element.round_trip || isAdditional(element)) return state;

        const newElement = createElement({
          ...element,
          virtual: true,
        });

        const sourceElement = ElementFactory.create(element);
        const sourceElementDate = sourceElement.getEndDate();

        const elements = state.elements.data;
        let newElements = [...elements];

        const sourceIndex = elements.findIndex(
          (e) => e.id === sourceElement.id && e.type === sourceElement.type,
        );

        const destinationIndex = elements.findIndex((item, key) => {
          item = ElementFactory.create(item);

          let itemDate = moment(item.getDate());

          let diff = itemDate.diff(sourceElementDate, 'days');
          if (diff === 0 && elements[key].virtual && key > sourceIndex) {
            return true;
          }

          if (diff > 0) {
            return true;
          }
        });

        if (destinationIndex === -1) {
          newElements.push(newElement);
        } else {
          newElements.splice(destinationIndex, 0, newElement);
        }

        state.elements.data = newElements;
      },
      removeReturnElement: (state, { payload: element }) => {
        const filterOut = (e) => !(e.id === element.id && e.type === element.type && e.virtual);

        state.elements.data = state.elements.data.filter(filterOut);
      },

      addTargetPoint: (state, action) => {
        const element = ElementFactory.create({
          type: TRAVEL_TARGET_POINT,
          acceptance_source: PRIMARY,
          isOpen: true,
          draft: true,
        });

        state.elements.data.push(element);
      },

      // private methods
      setDirty: (state, { payload: dirty }) => {
        state.dirty = dirty;
      },
    },
  });

  return {
    MOUNT_POINT,
    reducer: slice.reducer,
    actions: {
      // composed actions
      setElements,
      saveElement,
      deleteElement,
      addTargetPoint,
      addFirstElement,
      getPrivateAccommodationCost,
      updateElementSearchUuid,
      changeElementType,
      changeElementTypeAtIndex,
      clearOffer,
      addElement,

      // actions
      reset: slice.actions.reset,
      startRecomposition: slice.actions.startRecomposition,
      stopRecomposition: slice.actions.stopRecomposition,
      updateElement: slice.actions.updateElement,
      removeElement: slice.actions.removeElement,
      sortElements: slice.actions.sortElements,
      addReturnElement: slice.actions.addReturnElement,
      removeReturnElement: slice.actions.removeReturnElement,
      setDirty: slice.actions.setDirty,
    },
    selectors: {
      getElements,
      getAdditionalElements,
      isRecomposition,
      getRecomposed,
    },
  };
};
