import { Element } from './Element'
import { Location } from './index'
import { TRAVEL_TARGET_POINT } from '../../constants/travel'
import uuid from '../../utils/uuid'
import moment from 'moment'
import { config } from '../../config'

class TargetPoint extends Element {
  id: string;
  country_id: any;
  location: Location;
  date: string;

  constructor(init) {
    super({ ...init, type: TRAVEL_TARGET_POINT })

    const defaults = {
      id: uuid(),
      location: {},
      date: moment().hour(12).minute(0).format(config.apiDateTimeFormat),
      country_id: null,
    }

    const opts = Object.assign({}, defaults, init)

    this.id = opts.id
    this.country_id = opts.country_id
    this.location = new Location(opts.location)
    this.date = opts.date
  }

  getStartLocation() {
    return this.location
  }

  getEndLocation() {
    return this.location
  }

  getStartDate() {
    return this.date
  }

  getEndDate() {
    return this.date
  }
}

export { TargetPoint }
export default TargetPoint
