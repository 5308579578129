import PropTypes from 'prop-types'
import React from 'react'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { ExpenseRequestSummary } from '../ExpenseRequestSummary'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import { AccountingDocuments } from '../../AccountingDocuments'
import { RequestAccountingSummary } from '../../RequestAccountingSummary'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'
import PrintRequestDocuments from '../../PrintRequestDocuments'
import { RequestType } from '../../../types/request'

class ExpenseRequestPageTransferred extends React.Component<any, any> {
  render() {
    const { request, fetchRequest } = this.props

    const { accountingPart1, accountingPart2 } = request.accountingBalance || {}

    return (
      <div>
        <RequestHeader request={request} />

        <BasicRequestInfoRenderer />

        <AccountingDocuments
          request={request}
          fetchRequest={fetchRequest}
          disableUpload={true}
          disableDelete={true}
        />

        {request.type !== RequestType.INVOICE && (
          <AccountingDocuments
            request={request}
            fetchRequest={fetchRequest}
            disableUpload={true}
            disableDelete={true}
            showMileageAllowance={false}
            readOnly={true}
            showSummary={true}
            types={['travel', 'confirmation']}
          />
        )}

        <ExpenseRequestSummary request={request} />

        <RequestAccountingSummary
          changeRequestValue={this.props.changeRequestValue}
          request={request}
          accountingBalance={{ accountingPart1, accountingPart2 }}
        />

        <PrintRequestDocuments request={request} />

        <RequestComments comments={request['comments']} />
      </div>
    )
  }
}

ExpenseRequestPageTransferred.propTypes = {
  request: PropTypes.object.isRequired,
  instanceCurrency: PropTypes.string.isRequired,
  fetchRequest: PropTypes.func.isRequired,
}

export { ExpenseRequestPageTransferred }
export default { ExpenseRequestPageTransferred }
