import React from 'react'
import trans from '../../../trans'
import { Panel, PanelContent, PanelTitle } from '../../ui/Panel'
import { Row } from '../../../ui/Grid'
import classnames from 'classnames'

class ExpenseTypesTab extends React.Component<any, any> {
  render() {
    const { children, readOnly, className } = this.props

    const classes = classnames({
      'expense-types-tab': true,
      'expense-types-tab--read-only': readOnly,
      [className || '']: true,
    })

    return (
      <div className={classes}>
        <Panel>
          <PanelTitle>
            <strong>{trans('document-element.expenses-types')}</strong>
          </PanelTitle>
          <PanelContent>
            {children}
          </PanelContent>
        </Panel>
      </div>
    )
  }
}

export default ExpenseTypesTab
