import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField } from '../ui/Form'
import trans from '../../trans'
import { AmountFormatter } from '../AmountFormatter'
import { deductibleOptions } from '../../store/app/deductibilities'
import { fetchMpkForAsyncSelect } from '../../store/app/mpks'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import Icon from '../ui/IconComponent'
import { Panel, PanelContent, PanelTitle } from '../ui/Panel'

class AccountMileageAllowanceForm extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      accountingAccounts,
      mpks,
      projects,
      accountDimensions,
      user: { instance } = {},
      mileageAllowance,
      data,
      isDeductibilityEnabled,
      isDeletingInProgress,
      removeAccountingMileageAllowance,
    } = this.props

    return (
      <Panel
        className='panel--header-icon'
        closable
        onClose={() => {
          removeAccountingMileageAllowance()
        }}
      >
        <PanelTitle>
          <h1>
            <Icon type={'car_flat'} lg grey />
            {trans('cover.private-car-routes-header')}
          </h1>
        </PanelTitle>

        <PanelContent>
          <form onSubmit={handleSubmit}>
            <div>
              <Field
                name='accounting_account_id'
                type='select'
                label={trans('document-element.accounting-account')}
                component={FormField}
                options={accountingAccounts}
                disabled={submitting}
                labeltop
              />

              <Field
                name='mpk'
                type='async-select'
                label={trans('document-element.mpk')}
                component={FormField}
                loadOptions={fetchMpkForAsyncSelect()}
                labeltop
                disabled={submitting}
                selectedOption={data.mpk}
                placeholder={trans('instance-users.none')}
                noOptionsMessage={() => {
                  return trans('user.no-results-for-search-message')
                }}
                loadingMessage={() => {
                  return trans('user.searching-message')
                }}
                returnFullItemValueOnChange
              />

              {projects.length > 0 && (
                <Field
                  name='project_id'
                  type='select'
                  label={trans('document-element.project')}
                  component={FormField}
                  options={projects}
                  emptyValue={{
                    label: trans('trip-request.empty-project-option-label'),
                    value: null,
                  }}
                  labeltop
                />
              )}

              {isDeductibilityEnabled && (
                <Field
                  name='deductibility'
                  type='select'
                  label={trans('document-element.deductibility')}
                  component={FormField}
                  options={deductibleOptions()}
                  clearable={false}
                  labeltop
                />
              )}

              <div className='form-group--inline-checkbox form-group--inline-checkbox-disabled'>
                <Field
                  name='cost_of_earning'
                  type='checkbox'
                  label={trans('document-element.cost-of-earning')}
                  component={FormField}
                  inputOnly={true}
                  labeltop
                  disabled={true}
                  className={submitting}
                />
              </div>

              <Field
                name='amount'
                type='formatted_number'
                label={trans('request.amount')}
                component={FormField}
                labeltop
              />
            </div>

            {mileageAllowance && instance && (
              <Field
                name='dummy4'
                component={FormField}
                type='html'
                html={() => {
                  return (
                    <div style={{ marginTop: '7px' }}>
                      <strong>
                        <AmountFormatter amount={mileageAllowance.amount} />{' '}
                        {instance.default_currency}
                      </strong>
                    </div>
                  )
                }}
                label={trans('request.amount')}
                labeltop
              />
            )}

            {accountDimensions.map((dimension) => (
              <Field
                name={dimension.fieldName}
                type='account-dimensions'
                component={FormField}
                accountDimension={dimension}
                selectedDimensions={mileageAllowance.accountDimensionItems}
                placeholder={trans('account-dimensions.placeholder')}
                label={dimension.label}
                labeltop
                asField
                key={dimension.id}
              />
            ))}
          </form>

          {isDeletingInProgress && <LoadingOverlay />}
        </PanelContent>
      </Panel>
    )
  }
}

AccountMileageAllowanceForm.propTypes = {
  request: PropTypes.object.isRequired,
  mpks: PropTypes.array.isRequired,
  accountingAccounts: PropTypes.array.isRequired,
}

export { AccountMileageAllowanceForm }
export default { AccountMileageAllowanceForm }
