import React, { FunctionComponent } from 'react'

import './CancelInfoIcon.scss'
import classNames from 'classnames'
import { Tooltip } from '../../Tooltip'
import Icon from '../../ui/IconComponent'
import { HotelOfferEntryOption } from '../../../types/hotel-offers'
import trans from '../../../trans'

interface Props {
  cancelDate?: HotelOfferEntryOption['cancelDate']
}

export const CancelInfoIcon: FunctionComponent<Props> = ({ cancelDate }) => {
  const tooltipClassName = classNames({
    'booked-hotel__room-icon': true,
    'booked-hotel__room-icon--cancellable': cancelDate?.refundable,
    'booked-hotel__room-icon--non-cancellable': !cancelDate?.refundable,
  })

  return (
    <Tooltip
      className={tooltipClassName}
      html={
        <span>
          {cancelDate?.refundable
            ? trans('hotels-booking.offer-cancellable', {
                date: cancelDate?.date,
                hour: cancelDate?.hour,
              })
            : trans('hotels-booking.offer-non-cancellable')}
        </span>
      }
    >
      <Icon type='calendar_cancel' />
    </Tooltip>
  )
}
