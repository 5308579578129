import React from 'react';

import { Link } from 'react-router-dom';

import { BreadCrumbs } from '../../containers';
import { RouteManager } from '../../containers/RouteManager';
import { getRouteByName } from '../../routes';
import trans from '../../trans';
import StatementsPageFilters from '../StatementsPageFilters';
import StatementsPageTable from '../StatementsPageTable';
import { LoadingOverlay } from '../ui/LoadingOverlay';
import { Section } from '../ui/Section';
import { SectionHeader } from '../ui/SectionHeader';

import useStatements from './hooks/useStatements';

function StatementsPage() {
  const card = '4239 24** **** 7345';
  const { data, paginator, loading, setPage, setFilter, filters } = useStatements();

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'my-card')}>{trans('main-menu.my-card')}</Link>
        <Link to={getRouteByName('main', 'my-card-settlements')}>
          {trans('main-menu.my-card-statements')}
        </Link>
      </BreadCrumbs>

      <Section className={'my-cards'} noBorder>
        <SectionHeader
          className={'my-cards__header'}
          caption={trans('my-card.statement_header', { card })}
        />

        <RouteManager>
          {({ getRouteByName, push }) => (
            <div>
              <StatementsPageFilters filters={filters} setFilter={setFilter} />
              {loading && <LoadingOverlay />}
              {!loading && (
                <StatementsPageTable
                  data={data}
                  paginator={paginator}
                  setPage={setPage}
                  navigate={(id, from, to) =>
                    push(getRouteByName('main', 'my-card-statement', { id, from, to }))
                  }
                />
              )}
            </div>
          )}
        </RouteManager>
      </Section>
    </div>
  );
}

export default StatementsPage;
