import { Element } from './Element'
import { Location } from './index'
import { TRAVEL_RENTED_CAR_TRIP } from '../../constants/travel'

class RentedCar extends Element {
  pickup_at: any;
  return_at: any;
  departure_location: Location;
  destination_location: Location;
  rent_cost: any;
  rent_cost_currency: any;
  fuel_cost: any;
  fuel_cost_currency: any;
  other_costs_amount: any;
  other_costs_currency: any;
  amount: any;
  amount_currency: any;
  calculated_amount: any;
  calculated_amount_currency: any;
  round_trip: boolean;
  order: boolean;
  order_car_types: any[];
  order_car_additional_equipment: any[];
  order_car_other_requirement: any;

  constructor(init) {
    super({ ...init, type: TRAVEL_RENTED_CAR_TRIP })

    const defaults = {
      pickup_at: null,
      return_at: null,
      rent_cost: null,
      departure_location: {},
      destination_location: {},
      rent_cost_currency: null,
      fuel_cost: null,
      fuel_cost_currency: null,
      other_costs_amount: null,
      other_costs_currency: null,
      amount: null,
      amount_currency: null,
      calculated_amount: null,
      calculated_amount_currency: null,
      round_trip: true,
      order: false,
      order_car_types: [],
      order_car_additional_equipment: [],
      order_car_other_requirement: null,
    }

    const opts = Object.assign({}, defaults, init)

    this.pickup_at = opts.pickup_at
    this.return_at = opts.return_at
    this.departure_location = new Location(opts.departure_location)
    this.destination_location = new Location(opts.destination_location)
    this.rent_cost = opts.rent_cost
    this.rent_cost_currency = opts.rent_cost_currency
    this.fuel_cost = opts.fuel_cost
    this.fuel_cost_currency = opts.fuel_cost_currency
    this.other_costs_amount = opts.other_costs_amount
    this.other_costs_currency = opts.other_costs_currency
    this.amount = opts.amount
    this.amount_currency = opts.amount_currency
    this.calculated_amount = opts.calculated_amount
    this.calculated_amount_currency = opts.calculated_amount_currency
    this.round_trip = opts.round_trip
    this.order = opts.order
    this.order_car_types = opts.order_car_types
    this.order_car_additional_equipment = opts.order_car_additional_equipment
    this.order_car_other_requirement = opts.order_car_other_requirement
  }

  getStartLocation() {
    return this.departure_location
  }

  getEndLocation() {
    return this.destination_location
  }

  getStartDate() {
    return this.pickup_at
  }

  getEndDate() {
    return this.return_at
  }

  getAllAmounts() {
    return [
      {
        currency: this.rent_cost_currency,
        value: this.rent_cost,
      },
      {
        currency: this.fuel_cost_currency,
        value: this.fuel_cost,
      },
      {
        currency: this.other_costs_currency,
        value: this.other_costs_amount,
      },
      // {
      //     currency: this.amount_currency,
      //     value: this.amount,
      // }
    ]
  }

  isOrder() {
    return this.order
  }

  getOrderCarTypes() {
    return this.order_car_types
  }
}

export { RentedCar }
export default RentedCar
