import { Tooltip } from '../Tooltip';
import React from 'react';
import trans from '../../trans';
import DollarIcon from '../../ui/Icons/DollarIcon';

export function CorporateRate({ option }) {
  if (!option || !option.corporateRate) {
    return null;
  }

  return (
    <Tooltip html={<span>{trans('hotels-booking.individual-price')}</span>}>
      <DollarIcon />
    </Tooltip>
  );
}
