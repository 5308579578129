import { Element } from './Element'
import { Location } from './index'
import { TRAVEL_PASSENGER_CAR_TRIP } from '../../constants/travel'

class PassengerCar extends Element {
  departure_at: any;
  return_at: any;
  departure_location: Location;
  destination_location: Location;
  round_trip: boolean;
  fuel_cost: any;
  fuel_cost_currency: any;
  other_costs_amount: any;
  other_costs_currency: any;
  amount: any;
  amount_currency: any;
  calculated_amount: any;
  calculated_amount_currency: any;
  license_plate: string;

  constructor(init) {
    super({ ...init, type: TRAVEL_PASSENGER_CAR_TRIP })

    const defaults = {
      departure_at: null,
      return_at: null,
      departure_location: {},
      destination_location: {},
      round_trip: true,
      fuel_cost: null,
      fuel_cost_currency: null,
      other_costs_amount: null,
      other_costs_currency: null,
      amount: null,
      amount_currency: null,
      calculated_amount: null,
      calculated_amount_currency: null,
      license_plate: '',
    }

    const opts = Object.assign({}, defaults, init)

    this.departure_at = opts.departure_at
    this.return_at = opts.return_at
    this.departure_location = new Location(opts.departure_location)
    this.destination_location = new Location(opts.destination_location)
    this.round_trip = opts.round_trip
    this.fuel_cost = opts.fuel_cost
    this.fuel_cost_currency = opts.fuel_cost_currency
    this.other_costs_amount = opts.other_costs_amount
    this.other_costs_currency = opts.other_costs_currency
    this.amount = opts.amount
    this.amount_currency = opts.amount_currency
    this.calculated_amount = opts.calculated_amount
    this.calculated_amount_currency = opts.calculated_amount_currency
    this.license_plate = opts.license_plate
  }

  getStartLocation() {
    return this.departure_location
  }

  getEndLocation() {
    return this.destination_location
  }

  getStartDate() {
    return this.departure_at
  }

  getEndDate() {
    return this.return_at
  }

  getAllAmounts() {
    return [
      {
        currency: this.fuel_cost_currency,
        value: this.fuel_cost,
      },
      {
        currency: this.other_costs_currency,
        value: this.other_costs_amount,
      },
    ]
  }
}

export { PassengerCar }
export default PassengerCar
