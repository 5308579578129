import { RefObject, useEffect } from 'react'

export const useInfoBoxPositioning = (
  marker: google.maps.marker.AdvancedMarkerElement,
  infoBoxRef: RefObject<HTMLDivElement>,
  map: google.maps.Map,
  hovered: boolean,
) => {
  useEffect(() => {
    if (!hovered || !infoBoxRef.current) return

    const infoBoxEl = infoBoxRef.current
    const markerEl = marker.element

    if (!markerEl) return

    const markerRect = markerEl.getBoundingClientRect()
    const contentRect = infoBoxEl.getBoundingClientRect()
    const mapRect = map.getDiv().getBoundingClientRect()

    let offsetX = markerRect.width / 2 - contentRect.width / 2
    let offsetY = -contentRect.height - 12
    let arrowOnTop = false

    if (markerRect.top - contentRect.height < mapRect.top + 20) {
      offsetY = markerRect.height + 12
      arrowOnTop = true
    }

    infoBoxEl.style.left = `${offsetX}px`
    infoBoxEl.style.top = `${offsetY}px`

    infoBoxEl.classList.remove('arrow-top')
    if (arrowOnTop) {
      infoBoxEl.classList.add('arrow-top')
    }

    const contentAbsLeft = markerRect.left + offsetX
    const contentAbsRight = contentAbsLeft + contentRect.width

    let arrowLeft = contentRect.width / 2

    if (contentAbsLeft < mapRect.left + 10) {
      const shift = mapRect.left + 10 - contentAbsLeft
      offsetX += shift
      infoBoxEl.style.left = `${offsetX}px`
      arrowLeft -= shift
    } else if (contentAbsRight > mapRect.right - 10) {
      const shift = contentAbsRight - (mapRect.right - 10)
      offsetX -= shift
      infoBoxEl.style.left = `${offsetX}px`
      arrowLeft += shift
    }

    if (arrowLeft < 20) arrowLeft = 20
    else if (arrowLeft > contentRect.width - 20) {
      arrowLeft = contentRect.width - 20
    }

    infoBoxEl.style.setProperty('--arrow-left', `${arrowLeft}px`)
  }, [hovered, marker, map])
}
