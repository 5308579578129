import uuid from '../../utils/uuid'
import moment from 'moment'
import { TRAVEL_PRIVATE_ACCOMODATION, TRAVEL_PRIVATE_CAR_TRIP } from '../../constants/travel'
import { includes } from 'lodash'

class Element {
  isOpen: boolean;
  type: any;
  id: string;
  uuid: string;
  weight: any;
  return_weight: any;
  virtual: boolean;
  draft: boolean;
  dirty: boolean;
  converted_amount: number;
  accounted_amount: number;
  documents: any[];
  rules: any[];
  accounted_component_amounts: any[];
  search_uuid: any;
  acceptance_source: string;
  offer_uuid: any;
  target_real_arrival_at: any;
  target_real_departure_at: any;
  return_real_arrival_at: any;
  return_real_departure_at: any;
  key: string;
  className: string;

  constructor(init) {
    const generatedUuid = uuid()

    const defaults = {
      isOpen: false,
      type: null,
      id: generatedUuid,
      uuid: generatedUuid,
      search_uuid: null,
      virtual: false,
      draft: false,
      dirty: false,
      converted_amount: 0,
      accounted_amount: 0,
      documents: [],
      return_weight: null,
      rules: [],
      scrollToElement: false,
      accounted_component_amounts: [],
      offer_uuid: null,
      target_real_arrival_at: null,
      target_real_departure_at: null,
      return_real_arrival_at: null,
      return_real_departure_at: null,
      acceptance_source: 'primary',
    }

    const opts = Object.assign({}, defaults, init)

    this.isOpen = opts.isOpen
    this.type = opts.type
    this.id = opts.id
    this.uuid = opts.uuid
    this.weight = opts.weight
    this.return_weight = opts.return_weight
    this.virtual = opts.virtual
    this.draft = opts.draft
    this.dirty = opts.dirty
    this.converted_amount = opts.converted_amount
    this.accounted_amount = opts.accounted_amount
    this.documents = opts.documents
    this.rules = opts.rules
    this.accounted_component_amounts = opts.accounted_component_amounts
    this.search_uuid = opts.search_uuid
    this.acceptance_source = opts.acceptance_source

    this.offer_uuid = opts.offer_uuid
    this.target_real_arrival_at = opts.target_real_arrival_at
    this.target_real_departure_at = opts.target_real_departure_at
    this.return_real_arrival_at = opts.return_real_arrival_at
    this.return_real_departure_at = opts.return_real_departure_at

    this.key = `trip-timeline-element-${this.type}-${this.virtual}-${this.uuid || this.id}`
    this.className = `trip-timeline-element-${this.type}-${this.uuid || this.id}`
  }

  isPrimary() {
    return this.acceptance_source === 'primary'
  }

  isAdditional() {
    return this.acceptance_source === 'additional'
  }

  getStartDate() {
    throw new Error('You have to implement the method getStartDate!')
  }

  getEndDate() {
    throw new Error('You have to implement the method getEndDate!')
  }

  getStartLocation() {
    throw new Error('You have to implement the method getStartLocation!')
  }

  getEndLocation() {
    throw new Error('You have to implement the method getEndLocation!')
  }

  getType() {
    return this.type
  }

  getKey() {
    return this.key
  }

  getValues() {
    const keys = Object.keys(this)
    let valueKeys = keys.filter((key) => typeof this[key] != 'function')

    let output = {}
    valueKeys.forEach((key) => {
      output[key] = this[key]
    })

    return output
  }

  difference(element) {
    const aDate = moment(this.virtual ? this.getEndDate() : this.getStartDate())
    const bDate = moment(element.virtual ? element.getEndDate() : element.getStartDate())

    return aDate.diff(bDate, 'days') !== 0
  }

  isLumpSum() {
    return includes([TRAVEL_PRIVATE_ACCOMODATION, TRAVEL_PRIVATE_CAR_TRIP], this.type)
  }

  getVirtualEndLocation() {
    return this.virtual ? this.getStartLocation() : this.getEndLocation()
  }

  getDate() {
    return this.virtual ? this.getEndDate() : this.getStartDate()
  }

  getAllAmounts() {
    throw new Error('You have to implement the method getAllAmounts!')
  }

  compareTo(element) {
    return (
      (this.uuid === element.uuid || this.id === element.id) &&
      this.type === element.type &&
      this.virtual === element.virtual
    )
  }

  isOfType(type) {
    return this.type === type
  }
}

export { Element }
export default Element
