import React from 'react';

import moment from 'moment/moment';
import { StateTextButton } from '../ui/StateTextButton';
import ToggleableInputWrapper from '../ToggleableFilters/ToggleableInputWrapper';
import trans from '../../trans';
import { DatePickerField } from '../ui/Form';
import ToggleableFilters from '../ToggleableFilters/ToggleableFilters';
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters';
import ToggleableSearchField from '../ToggleableFilters/ToggleableSearchField';
import ToggleableQuickFiltersContainer from '../ToggleableFilters/ToggleableQuickFiltersContainer';
import {
  QUICK_FILTER_ASSIGNED,
  QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT,
} from '../../constants/request';
import ToggleableBottomFilters from '../ToggleableFilters/ToggleableBottomFilters';

interface Props {
  filters: Record<string, unknown>;
  setFilter: (key: string, value: unknown) => void;
}

function PaymentsPageFilters({ filters, setFilter }: Props) {
  const filtersCount = Object.keys(filters).length;

  const bottomFilters = (
    <>
      <ToggleableInputWrapper label={trans('global.filter-period')}>
        <div className='filters__period-container'>
          <DatePickerField
            placeholder={trans('global.datepicker-placeholder')}
            onChange={(value) => setFilter('booked_at_from', moment(value))}
            isClearable={true}
            value={filters['booked_at_from']}
          />
          <span className='filters__separator'>-</span>
          <DatePickerField
            placeholder={trans('global.datepicker-placeholder')}
            onChange={(value) => setFilter('booked_at_to', moment(value))}
            isClearable={true}
            minDate={filters['booked_at_from']}
            value={filters['booked_at_to']}
          />
        </div>
      </ToggleableInputWrapper>
    </>
  );

  return (
    <form>
      <ToggleableFilters filtersCount={filtersCount}>
        {({ isOpen }) => (
          <>
            <ToggleableQuickFilters>
              <ToggleableSearchField
                value={filters['description']}
                onChange={(value) => {
                  setFilter('description', value);
                }}
              />

              <ToggleableQuickFiltersContainer>
                <StateTextButton
                  onClick={(activate) => {
                    setFilter('quick_filters', activate ? QUICK_FILTER_ASSIGNED : null);
                  }}
                  active={filters['quick_filters'] === QUICK_FILTER_ASSIGNED}
                >
                  {trans('global.assigned')}
                </StateTextButton>

                <StateTextButton
                  onClick={(activate) => {
                    setFilter(
                      'quick_filters',
                      activate ? QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT : null,
                    );
                  }}
                  active={filters['quick_filters'] === QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT}
                >
                  {trans('global.unassigned-settlements')}
                </StateTextButton>
              </ToggleableQuickFiltersContainer>
            </ToggleableQuickFilters>

            <ToggleableBottomFilters isOpen={isOpen}>{bottomFilters}</ToggleableBottomFilters>
          </>
        )}
      </ToggleableFilters>
    </form>
  );
}

export default PaymentsPageFilters;
