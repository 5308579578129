import axios from 'axios';
import _, { get, isUndefined, transform } from 'lodash';
import { config } from '../config';
import { setupCache } from 'axios-cache-adapter';
import mime from 'mime/lite';
import store from '../store';
import { logoutUserAction } from '../containers/App/actions';
import { resetInstance } from '../store/app/instance';
import { STATUS_WAITING_FOR_ACCEPTANCE } from '../constants/request';
import AlertController from './alerts-controller';
import moment from 'moment';
import queryString from 'query-string';
import {
  TRAVEL_ACCOMMODATION_PROVIDED,
  TRAVEL_ACCOMODATION,
  TRAVEL_BUS_TRIP,
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_FERRY,
  TRAVEL_PASSENGER_CAR_TRIP,
  TRAVEL_PLANE_TRIP,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_REPLACEMENT_CAR_TRIP,
  TRAVEL_TARGET_POINT,
  TRAVEL_TRAIN_TRIP,
} from '../constants/travel';
import Session from './Session';
import { isEndOfDay } from '../utils/date';
import { ChooseHotelOfferResponse } from '../types/hotel-offers';
import { TableConfigResponse } from '../store/app/table-config/table-config.model';
import { HttpLink } from '../types/http-link';
import { HttpResponse, Paginator } from '../types/response';
import { Grade } from '../types/grade';
import { IDocument } from '../types/document';
import { ITransactionSuggestionResponse } from '../types/transaction-suggestion';
import { IImportOptions } from '../types/import';
import { User } from '../types/user';
import { Report } from '../components/ReportsPage/useReports';

const createURLparams = (obj) => {
  let paramsURL = '';
  for (let key in obj) {
    if (!obj.hasOwnProperty(key) || obj[key] === null || obj[key] === '') {
      continue;
    }

    if (paramsURL.length > 0) {
      paramsURL += '&';
    }
    paramsURL += key + '=' + encodeURIComponent(obj[key]);
  }

  return paramsURL;
};

export const processAPIerrorResponseToFormErrors = (alerts, dotToUnderscore = false) => {
  //errors appear always on this path, trust me
  let sourceErrors = get(alerts, '0.errors', {});

  return transform(sourceErrors, (result, value, key) => {
    const splitted = key.split('.');
    const resultKey = dotToUnderscore ? splitted.join('_') : splitted[0];

    result[resultKey] = value;
  });
};

class APIClient {
  constructor() {
    this._prefix = '/api';
    this.prefix = this._prefix;
    this.token = null;
    this.headers = {};
    this.alerts = {};
    this.alertsController = AlertController;

    const cache = setupCache({
      maxAge: 60 * 1000,
      debug: false,
      exclude: {
        paths: [
          'notifications',
          '/instance/ping',
          '/auth/instance',
          'request-elements-for-documents',
          'widget',
          'border-crossings',
          'countries',
          '/reports/cockpit',
          '/cards',
          '/login-as',
          '/auth/relogin',
          '/user/profile',
          '/clear',
          '/user-deputies',
          '/user-assistants',
          '/users',
          'validate',
          '/auth/logout',
        ],
      },
    });

    this.directionsService = axios.create({
      adapter: cache.adapter,
    });

    this._prepareToken();
  }

  _prepareToken() {
    const sessionData = config.storage.getItem('sessionData');

    if (sessionData) {
      this.token = JSON.parse(sessionData).token;
    }
  }

  _prepareHeaders() {
    this.headers = {
      Authorization: `Bearer ${this.token}`,
    };
  }

  _validateRequest() {
    if (!this.token) {
      throw new Error('No session data in storage.');
    }
  }

  _prepareAuthorizedRequest() {
    this._prepareToken();
    this._prepareHeaders();
    this._validateRequest();
  }

  _call<T>(method, url, data, withBearer = true, cache = false, customConfig = {}): Promise<T> {
    if (withBearer) {
      this._prepareAuthorizedRequest();
    }

    let config = {
      headers: {
        'X-B3-TraceId': 'web-' + Math.random().toString(36).substring(7),
        ...this.headers,
      },
    };

    if (customConfig.confirm) {
      config.headers['Accept-confirmation'] = 'accepted';
    }

    if (cache) {
      config.cache = { exclude: { query: false } };
    } else {
      config.cache = { ignoreCache: true, exclude: { filter: () => true } };
    }

    config = _.merge(config, customConfig);
    url = this._prepareUrl(url, config.addPrefix);

    let responsePromise;

    switch (method.toLowerCase()) {
      case 'post':
        responsePromise = this.directionsService.post(url, data, config);
        break;
      case 'put':
        responsePromise = this.directionsService.put(url, data, config);
        break;
      case 'delete':
        responsePromise = this.directionsService.delete(url, config);
        break;
      default:
        responsePromise = this.directionsService.get(url, config);
    }

    const showAlerts = isUndefined(customConfig.showAlerts) || customConfig.showAlerts === true;

    return this._responsePromise(responsePromise, showAlerts, config);
  }

  _get<T>(url, withBearer = true, cache = false, customConfig = {}) {
    return this._call<T>('get', url, [], withBearer, cache, customConfig);
  }

  _post<T>(url, args, withBearer = true, cache = false, customConfig = {}): Promise<T> {
    return this._call<T>('post', url, args, withBearer, cache, customConfig);
  }

  _put<T>(url, args, withBearer = true, cache = false, customConfig = {}) {
    return this._call<T>('put', url, args, withBearer, cache, customConfig);
  }

  _delete<T>(url, withBearer = true, cache = false, customConfig = {}, data = []) {
    return this._call<T>('delete', url, data, withBearer, cache, customConfig);
  }

  _blob(url, args, withBearer = true, cache = false, customConfig = {}, directOpen = false) {
    return new Promise((resolve, reject) => {
      const method = customConfig.method || 'get';
      const getFileName = (response) => {
        if (response.headers['content-disposition']) {
          return response.headers['content-disposition'].split('filename=')[1];
        }

        const extension = mime.getExtension(response.headers['content-type']);

        return `export.${extension}`;
      };

      customConfig.responseType = 'blob';

      this._call(method, url, args, withBearer, cache, customConfig)
        .then((response) => {
          if (directOpen === true) {
            const a = document.createElement('a');
            const filename = getFileName(response);

            document.body.appendChild(a);
            a.href = window.URL.createObjectURL(response.data);
            a.download = filename;
            a.target = '_blank';
            a.click();
            a.remove();
            window.URL.revokeObjectURL(a.href);
            resolve({ success: true });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  _manageErrors(error, showAlerts, reject) {
    if (axios.isCancel(error)) {
      return error;
    }

    if (error.response) {
      if (error.response.status === 401 || error.response.status === 402) {
        Session.clearSessionData();
        window.location.replace('/login');
      }

      if (showAlerts) {
        this.alertsController.displayAlerts(error['response']['data']['alerts']);
      }

      reject(error.response.data, error.response.status);
    } else {
      reject('Something went wrong', 500);
    }
  }

  _responsePromise<T>(promise, showAlerts, config): Promise<T> {
    return new Promise((resolve, reject) => {
      promise
        .then((response) => {
          if (config.responseType === 'blob') {
            resolve(response);
          } else {
            resolve(response.data);

            if (showAlerts) {
              this.alertsController.displayAlerts(response['data']['alerts']);
            }
          }
        })
        .catch((error) => {
          return this._manageErrors(error, showAlerts, reject);
        });
    });
  }

  _prepareUrl(url, addPrefix = true) {
    if (url.indexOf('http') === -1 && addPrefix) {
      url = _.trimStart(url, '/');
      url = `${this.prefix}/${url}`;
    }

    return url;
  }

  loginUser(email, password, lang) {
    return this._post(
      `/auth/login?lang=${lang}&with=mpk,instance,citizenship,loyaltyCards`,
      { email, password },
      false,
    );
  }

  userStatus() {
    return this._get<HttpResponse<{ user: User; loggedAs: User }>>(
      '/auth/status?with=mpk,instance,nationality,citizenship,loyaltyCards,workLocation,availableIdentities',
      true,
    );
  }

  updateMPK(slug, data) {
    return this._put(`/mpks/${slug}`, data);
  }

  createMPK(data) {
    return this._post('/mpks', data);
  }

  getMPKs(company_id = null, offset = 0, limit = 1000, phrase = '') {
    const params = {
      company_id,
      offset,
      limit,
      phrase,
    };

    return this._get(`/mpks?${queryString.stringify(params)}`, true, false);
  }

  getAdditionalDimensionItems(slug, offset = 0, limit = 1000, phrase = '') {
    const params = {
      offset,
      limit,
      phrase,
    };

    return this._get(
      `/reports/account-dimensions-items/${slug}?${queryString.stringify(params)}`,
      true,
      false,
    );
  }

  getEditableMpk(offset = 0, limit = 1000) {
    return this._get(`/mpks/editable?with=companies&offset=${offset}&limit=${limit}`, true);
  }

  getLevels() {
    return this._get('/levels', true);
  }

  getProjects(offset = 0, limit = 1000) {
    return this._get(`/reports/projects?offset=${offset}&limit=${limit}`, true);
  }

  createProject(data) {
    return this._post('/reports/projects', data);
  }

  updateProject(slug, data) {
    return this._put(`/reports/projects/${slug}`, data);
  }

  createRequest(...fields) {
    return this._post('/requests', ...fields, true, false, { showAlerts: true });
  }

  updateRequest(slug, fields, meta) {
    return this._put(
      `/requests/${slug}?with=mpk,comments.user,documents.elements.type,documents.provider,project,acceptors,settlementAcceptors,installments,borderCrossings.country,travelExpenses,combinedTravelElements,costs.documents.provider,costs.documents.ocrHints,summary,accountingBalance,accountingMileageAllowances,subsummary`,
      fields,
      true,
      false,
      meta,
    );
  }

  changePrivate(slug, isPrivate) {
    return this._put(`/requests/${slug}/change-private/${isPrivate}`, {}, true);
  }

  updateRequestWithoutDependency(slug, ...fields) {
    return this._put(`/requests/${slug}`, ...fields, true);
  }

  getTripRequests(filters = {}, cancelToken = null) {
    const config = {
      params: _.merge(
        {
          types: 'trip',
          with: 'acceptors,user,requestElementsSumAmount',
          quick_filters: null,
          pagination: true,
        },
        filters,
      ),
    };

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get('/requests', true, false, config);
  }

  getAgentTripRequests(filters = {}, cancelToken = null) {
    const config = {
      params: filters,
    };

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get('/agent/requests', true, false, config);
  }

  getTripRequest(slug) {
    // After change any 'with' please remember to reset redis cache on API.
    return this._get(
      `/requests/${slug}?with=mpk,comments.user,documents.elements.type,documents.provider,project,acceptors,settlementAcceptors,installments,borderCrossings.country,travelExpenses,combinedTravelElements,costs.documents.provider,costs.documents.ocrHints,summary,accountingBalance,accountingMileageAllowances,subsummary,unassignedDocuments.elements.type,balance,lumpSumsDeclaration,sendToERP,unrequestedElementAmounts,additionalDimensionItems,valueOfOtherDocuments`,
      true,
    );
  }

  getExpenseRequests(filters = {}, cancelToken = null) {
    const config = {
      params: _.merge(
        { types: 'expense', with: 'acceptors,user,requestElementsSumAmount' },
        filters,
      ),
    };

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get('/requests', true, false, config);
  }

  getInvoiceRequests(filters = {}, cancelToken = null) {
    const config = {
      params: _.merge(
        { types: 'invoice', with: 'acceptors,user,requestElementsSumAmount' },
        filters,
      ),
    };

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get('/requests', true, false, config);
  }

  getExpenseRequest(id) {
    return this.getTripRequest(id);
  }

  getSettlementRequests(filters = {}, cancelToken) {
    filters = _.merge(
      {
        types: 'expense,trip,invoice',
        with: 'user,requestElementsSumAmount,accountingUser,lumpSumsDeclaration',
        pagination: true,
      },
      filters,
    );
    const config = { params: filters };
    if (cancelToken !== null) {
      config.cancelToken = cancelToken.token;
    }
    return this._get('/requests/settlements', true, false, config);
  }

  uploadDocument(requestSlug, file, progressCallback = false, documentType = null) {
    let data = new FormData();
    data.append('request_slug', requestSlug);
    data.append('file', file);

    if (documentType) {
      data.append('type', documentType);
    }

    let config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (progressCallback !== false) {
          progressCallback(percentCompleted);
        }
      },
    };

    return this._post('/documents', data, true, false, config);
  }

  uploadElementDocument(
    requestSlug,
    elementType,
    elementId,
    file,
    progressCallback = null,
    type = null,
  ) {
    let data = new FormData();
    data.append('request_slug', requestSlug);
    data.append('request_element_type', elementType);
    data.append('request_element_id', elementId);

    if (type) {
      data.append('type', type);
    }

    data.append('file', file);

    let config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (_.isFunction(progressCallback)) {
          progressCallback(percentCompleted);
        }
      },
    };
    return this._post('/documents', data, true, false, config);
  }

  getAllDocuments(params = {}) {
    const { filters = {} } = params;

    return this._get(
      `/documents?${createURLparams({
        with: 'request,provider',
        ...filters,
      })}`,
    );
  }

  getAllTransactions(params = {}, cancelToken) {
    const { filters = {} } = params;
    let config = {};

    if (cancelToken !== null) {
      config = { cancelToken: cancelToken.token };
    }

    if (filters.sorter) {
      filters.sorter = JSON.stringify(filters.sorter);
    }

    return this._get(`/transactions?${createURLparams({ ...filters })}`, true, false, config);
  }

  getDocuments(requestId) {
    return this._get(`/documents?request_id=${requestId}`, true);
  }

  createComment(...fields) {
    return this._post('/comments', ...fields, true);
  }

  getComments(slug) {
    return this._get(`/requests/${slug}/comments`);
  }

  userLogout() {
    store.dispatch(resetInstance());
    store.dispatch(logoutUserAction());
  }

  getNotifications(offset = 0, limit = 100) {
    const queryParams = {
      offset,
      limit,
    };

    return this._get(`notifications?${createURLparams(queryParams)}`);
  }

  readNotifications(ids) {
    return this._post('notifications/read', { ids });
  }

  getAcceptors(filters = {}, cancelToken = null) {
    filters = _.merge(
      {
        pagination: true,
        limit: 100,
        offset: 0,
      },
      filters,
    );

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get(`/users/acceptors?${createURLparams({ ...filters })}`, true.false, config);
  }

  getSettlementAcceptors(filters = {}, cancelToken = null) {
    filters = _.merge(
      {
        pagination: true,
        limit: 100,
        offset: 0,
      },
      filters,
    );

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get(
      `/users/settlement-acceptors?${createURLparams({ ...filters })}`,
      true.false,
      config,
    );
  }

  addAcceptor(requestSlug, userSlug) {
    return this._post(`requests/${requestSlug}/acceptors`, { slug: userSlug });
  }

  removeAcceptor(requestSlug, userSlug) {
    return this._delete(`requests/${requestSlug}/acceptors/${userSlug}`);
  }

  addSettlementAcceptor(requestSlug, userSlug) {
    return this._post(`requests/${requestSlug}/settlement-acceptors`, { slug: userSlug });
  }

  removeSettlementAcceptor(requestSlug, userSlug) {
    return this._delete(`requests/${requestSlug}/settlement-acceptors/${userSlug}`);
  }

  getCurrencies() {
    return this._get('currencies');
  }

  getAccountingDocuments() {
    return this._get(`/documents?request_id=${requestId}`, true);
  }

  uploadAccountingDocument(requestSlug, file, progressCallback) {
    let data = new FormData();
    data.append('request_slug', requestSlug);
    data.append('type', 'accounting');
    data.append('file', file);

    let config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (_.isFunction(progressCallback)) {
          progressCallback(percentCompleted);
        }
      },
    };
    return this._post('/documents', data, true, false, config);
  }

  getCountries() {
    return this._get('countries');
  }

  getBorderCrossings(requestSlug) {
    return this._get(`requests/${requestSlug}/border-crossings`);
  }

  createBorderCrossing(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/border-crossings`, fields);
  }

  removeBorderCrossing(requestSlug, id) {
    return this._delete(`requests/${requestSlug}/border-crossings/${id}`);
  }

  updateBorderCrossing(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/border-crossings/${id}`, fields);
  }

  updateMealDeduction(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/meal-deductions/${id}`, fields);
  }

  createTravelExpense(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/meal-deductions`, fields);
  }

  removeTravelExpense(requestSlug, id) {
    return this._delete(`requests/${requestSlug}/meal-deductions/${id}`);
  }

  deleteFile(fileID) {
    return this._delete(`documents/${fileID}`);
  }

  createRentedCarTrip(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/rented-car-trips`, fields);
  }

  createPrivateCarTrip(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/private-car-trips`, fields);
  }

  createCompanyCarTrip(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/company-car-trips`, fields);
  }

  createReplacementCarTrip(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/replacement-car-trips`, fields);
  }

  createPassengerCarTrip(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/passenger-car-trips`, fields);
  }

  updateRentedCarTrip(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/rented-car-trips/${id}`, fields);
  }

  updatePrivateCarTrip(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/private-car-trips/${id}`, fields);
  }

  updateCompanyCarTrip(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/company-car-trips/${id}`, fields);
  }

  updateReplacementCarTrip(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/replacement-car-trips/${id}`, fields);
  }

  updatePassengerCarTrip(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/passenger-car-trips/${id}`, fields);
  }

  removeRentedCarTrip(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/rented-car-trips/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  removePrivateCarTrip(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/private-car-trips/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  removeCompanyCarTrip(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/company-car-trips/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  removeReplacementCarTrip(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/replacement-car-trips/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  removePassengerCarTrip(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/passenger-car-trips/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  updatePlaneTrip(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/plane-trips/${id}`, fields);
  }

  createPlaneTrip(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/plane-trips`, fields);
  }

  removePlaneTrip(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/plane-trips/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  updateAccommodation(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/accomodations/${id}`, fields);
  }

  createAccommodation(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/accomodations`, fields);
  }

  removeAccommodation(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/accomodations/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  updateAccommodationProvided(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/provided-accommodations/${id}`, fields);
  }

  createAccommodationProvided(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/provided-accommodations`, fields);
  }

  removeAccommodationProvided(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/provided-accommodations/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  createPrivateAccommodation(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/private-accomodations`, fields);
  }

  removePrivateAccommodation(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/private-accomodations/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  updatePrivateAccommodation(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/private-accomodations/${id}`, fields);
  }

  updateTrainTrip(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/train-trips/${id}`, fields);
  }

  createTrainTrip(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/train-trips`, fields);
  }

  removeTrainTrip(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/train-trips/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  updateFerry(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/ferry-boat-trips/${id}`, fields);
  }

  createFerry(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/ferry-boat-trips`, fields);
  }

  removeFerry(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/ferry-boat-trips/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  updateBus(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/bus-trips/${id}`, fields);
  }

  createBus(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/bus-trips`, fields);
  }

  removeBus(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/bus-trips/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  removeTravelElement(request, element, silent = false) {
    switch (element.type) {
      case TRAVEL_RENTED_CAR_TRIP:
        return this.removeRentedCarTrip(request.slug, element.id, silent);
      case TRAVEL_PRIVATE_CAR_TRIP:
        return this.removePrivateCarTrip(request.slug, element.id, silent);
      case TRAVEL_COMPANY_CAR_TRIP:
        return this.removeCompanyCarTrip(request.slug, element.id, silent);
      case TRAVEL_REPLACEMENT_CAR_TRIP:
        return this.removeReplacementCarTrip(request.slug, element.id, silent);
      case TRAVEL_PASSENGER_CAR_TRIP:
        return this.removePassengerCarTrip(request.slug, element.id, silent);
      case TRAVEL_PLANE_TRIP:
        return this.removePlaneTrip(request.slug, element.id, silent);
      case TRAVEL_TRAIN_TRIP:
        return this.removeTrainTrip(request.slug, element.id, silent);
      case TRAVEL_ACCOMODATION:
        return this.removeAccommodation(request.slug, element.id, silent);
      case TRAVEL_PRIVATE_ACCOMODATION:
        return this.removePrivateAccommodation(request.slug, element.id, silent);
      case TRAVEL_ACCOMMODATION_PROVIDED:
        return this.removeAccommodationProvided(request.slug, element.id, silent);
      case TRAVEL_TARGET_POINT:
        return this.removeTargetPoint(request.slug, element.id, silent);
      case TRAVEL_FERRY:
        return this.removeFerry(request.slug, element.id, silent);
      case TRAVEL_BUS_TRIP:
        return this.removeBus(request.slug, element.id, silent);
    }
  }

  createTravelElement(request, element) {
    switch (element.type) {
      case TRAVEL_RENTED_CAR_TRIP:
        return this.createRentedCarTrip(request.slug, element);
      case TRAVEL_PRIVATE_CAR_TRIP:
        return this.createPrivateCarTrip(request.slug, element);
      case TRAVEL_COMPANY_CAR_TRIP:
        return this.createCompanyCarTrip(request.slug, element);
      case TRAVEL_REPLACEMENT_CAR_TRIP:
        return this.createReplacementCarTrip(request.slug, element);
      case TRAVEL_PASSENGER_CAR_TRIP:
        return this.createPassengerCarTrip(request.slug, element);
      case TRAVEL_PLANE_TRIP:
        return this.createPlaneTrip(request.slug, element);
      case TRAVEL_TRAIN_TRIP:
        return this.createTrainTrip(request.slug, element);
      case TRAVEL_ACCOMODATION:
        return this.createAccommodation(request.slug, element);
      case TRAVEL_PRIVATE_ACCOMODATION:
        return this.createPrivateAccommodation(request.slug, element);
      case TRAVEL_ACCOMMODATION_PROVIDED:
        return this.createAccommodationProvided(request.slug, element);
      case TRAVEL_TARGET_POINT:
        return this.createTargetPoint(request.slug, element);
      case TRAVEL_FERRY:
        return this.createFerry(request.slug, element);
      case TRAVEL_BUS_TRIP:
        return this.createBus(request.slug, element);
    }
  }

  updateTravelElement(request, elementId, element) {
    switch (element.type) {
      case TRAVEL_RENTED_CAR_TRIP:
        return this.updateRentedCarTrip(request.slug, elementId, element);
      case TRAVEL_PRIVATE_CAR_TRIP:
        return this.updatePrivateCarTrip(request.slug, elementId, element);
      case TRAVEL_COMPANY_CAR_TRIP:
        return this.updateCompanyCarTrip(request.slug, elementId, element);
      case TRAVEL_REPLACEMENT_CAR_TRIP:
        return this.updateReplacementCarTrip(request.slug, elementId, element);
      case TRAVEL_PASSENGER_CAR_TRIP:
        return this.updatePassengerCarTrip(request.slug, elementId, element);
      case TRAVEL_PLANE_TRIP:
        return this.updatePlaneTrip(request.slug, elementId, element);
      case TRAVEL_TRAIN_TRIP:
        return this.updateTrainTrip(request.slug, elementId, element);
      case TRAVEL_ACCOMODATION:
        return this.updateAccommodation(request.slug, elementId, element);
      case TRAVEL_PRIVATE_ACCOMODATION:
        return this.updatePrivateAccommodation(request.slug, elementId, element);
      case TRAVEL_ACCOMMODATION_PROVIDED:
        return this.updateAccommodationProvided(request.slug, elementId, element);
      case TRAVEL_TARGET_POINT:
        return this.updateTargetPoint(request.slug, elementId, element);
      case TRAVEL_FERRY:
        return this.updateFerry(request.slug, elementId, element);
      case TRAVEL_BUS_TRIP:
        return this.updateBus(request.slug, elementId, element);
    }
  }

  getCostTypes(type) {
    return this._get(`cost-types?type=${type}`);
  }

  getCosts(requestSlug) {
    return this._get(
      `requests/${requestSlug}/costs?${Date.now()}&with=instance,request.instance,documents.accountDimensionItems,documents.ocrHints,documents.elements,documents.instance,documents.request.installments,documents.request.instance,documents.request.user.instance`,
    );
  }

  createCost(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/costs`, fields);
  }

  updateCost(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/costs/${id}`, fields);
  }

  removeCost(requestSlug, id) {
    return this._delete(`requests/${requestSlug}/costs/${id}`);
  }

  removeInstallment(requestSlug, id) {
    return this._delete(`requests/${requestSlug}/installments/${id}`);
  }

  createInstallment(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/installments`, fields);
  }

  updateInstallment(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/installments/${id}`, fields);
  }

  getDocument(id: number): Promise<HttpResponse<IDocument>> {
    return this._get<HttpResponse<IDocument>>(
      `documents/${id}?with=currency,provider,elements.type.group,elements.mpk,ocrHints,expenseTypes,request,project,elements.accountingAccount,elements.vatNumber.accountingAccount,suggested_document_element_type,settlementAcceptors,request.settlementAcceptors`,
    );
  }

  getDocumentForSettlement(id: number): Promise<HttpResponse<IDocument>> {
    return this._get<HttpResponse<IDocument>>(
      `documents/${id}?with=currency,provider,ocrHints,request,expenseTypes,mpk,project,request.settlementAcceptors`,
    );
  }

  getDocumentRules(id) {
    return this._get(`documents/${id}`);
  }

  saveDocument(id, fields) {
    return this._put(
      `documents/${id}?with=currency,provider,elements.type.group,ocrHints,request,elements.accountingAccount,elements.vatNumber.accountingAccount,suggested_document_element_type,expenseTypes`,
      fields,
    );
  }

  saveDocumentAccount(id, fields) {
    return this._put(
      `documents/${id}/account?with=currency,provider,elements.type.group,ocrHints,request,elements.accountingAccount,elements.vatNumber.accountingAccount,suggested_document_element_type`,
      fields,
    );
  }

  getExchangeRate(requestSlug, currencies) {
    return this._get(`${requestSlug}/exchange-rates?currencies=${currencies}`, true, true, {});
  }

  getKpiList() {
    return this._get('reports/kpis');
  }

  updateKpi(slug, data) {
    return this._put(`reports/kpis/${slug}`, data);
  }

  getUsers(companyId = null) {
    const url = companyId ? `users?companies=${companyId}` : `users`;

    return this._get(url);
  }

  createUser(user) {
    if (!user.supervisor_id) {
      delete user.supervisor_id;
    }

    return this._post('users', user);
  }

  getAllMeansOfTransports(slug: string): Promise<Record<string, string>> {
    return this._get<Record<string, string>>(
      `allowance/means-of-transports/${slug}/options`,
      true,
      true,
      {},
    );
  }

  getRequestMeansOfTransports(slug: string): Promise<string[]> {
    return this._get<string[]>(`allowance/means-of-transports/${slug}`, true, true, {});
  }

  saveRequestMeansOfTransports(slug: string, types: string[]) {
    return this._post(`allowance/means-of-transports/${slug}`, {
      types,
    });
  }

  getUsersForSelect(companyId = null, filters = {}, cancelToken = null) {
    filters = _.merge(
      {
        companies: companyId,
        pagination: true,
        limit: 100,
        offset: 0,
      },
      filters,
    );

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get(
      `users/for-select?${createURLparams({ ...filters })}&with=company`,
      true,
      false,
      config,
    );
  }

  getUsersForReportSelect(companyId = null, filters = {}, cancelToken = null) {
    filters = _.merge(
      {
        companies: companyId,
        pagination: true,
        limit: 100,
        offset: 0,
      },
      filters,
    );

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get(
      `reports/users?${createURLparams({ ...filters })}&with=company`,
      true,
      false,
      config,
    );
  }

  getGrades() {
    return this._get<HttpResponse<Grade[]>>('grades');
  }

  updateHint(documentID, id, fields) {
    return this._put(`documents/${documentID}/ocr-hints/${id}`, fields, true, false, {
      showAlerts: false,
    });
  }

  getExpenseGroups() {
    return this._get('document-element-groups');
  }

  updateExpenseGroup(slug, data) {
    return this._put(`/document-element-groups/${slug}`, data);
  }

  changeOrderOfExpenseGroups(data) {
    return this._post(`document-element-groups/change-order`, data);
  }

  getExpenseTypes() {
    return this._get('document-element-types?with=accountDimensionItems');
  }

  updateExpenseType(slug, data) {
    return this._put(`/document-element-types/${slug}?with=accountDimensionItems`, data);
  }

  changeOrderOfExpenseTypes(data) {
    return this._post(`document-element-types/change-order?with=accountDimensionItems`, data);
  }

  getDocumentElementGroups(document) {
    return this._get(`documents/${document['id']}/elements?with=type.group`);
  }

  saveDocumentElementType(document, element, type, data) {
    return this._put(
      `documents/${document['id']}/elements/${element['id']}?with=accountingAccount,vatNumber.accountingAccount`,
      {
        type_id: type ? type['id'] : undefined,
        ...data,
      },
    );
  }

  saveDocumentElementTypeAccount(document, element, type, data) {
    return this._put(
      `documents/${document['id']}/elements/${element['id']}/account?with=accountingAccount,vatNumber.accountingAccount,type.group`,
      {
        type_id: type ? type['id'] : undefined,
        ...data,
      },
    );
  }

  deleteDocumentElementType(document, element) {
    return this._delete(`documents/${document['id']}/elements/${element['id']}`);
  }

  addDocumentElementType(document, element, type, data) {
    return this._post(`documents/${document['id']}/elements?with=type,accountingAccount`, data);
  }

  saveExpenseType(document, element, type, data) {
    return this._put(`documents/${document}/elements/${element['id']}`, {
      type_id: type ? type['id'] : undefined,
      ...data,
    });
  }

  deleteExpenseType(document, element) {
    return this._delete(`documents/${document}/elements/${element['id']}`);
  }

  addExpenseType(document, data) {
    return this._post(`documents/${document}/elements`, data);
  }

  getProviders(filters = {}, cancelToken = null) {
    filters = _.merge(
      {
        with: 'country',
        pagination: true,
        limit: 100,
        offset: 0,
      },
      filters,
    );

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get(`providers?${createURLparams({ ...filters })}`, true, false, config);
  }

  getEditableVatNumbers() {
    return this._get('vat-numbers/editable?with=accountingAccount');
  }

  getVatNumbers() {
    return this._get('vat-numbers?with=accountingAccount');
  }

  updateVatNumber(slug, data) {
    return this._put(`/vat-numbers/${slug}`, data);
  }

  createVatNumber(data) {
    return this._post('/vat-numbers', data);
  }

  getDocumentElementTypes(documentId) {
    return this._get(`document-element-types?with=group&document_id=${documentId}`);
  }

  getCombinedTravelElements(requestSlug) {
    return this._get(`requests/${requestSlug}/combined-travel-elements?${Date.now()}`);
  }

  getAllRequestElements(requestSlug) {
    return this._get(`requests/${requestSlug}/all-request-elements?${Date.now()}`);
  }

  getRequestElementsForDocument(slug) {
    return this._get(`requests/${slug}/request-elements-for-documents`);
  }

  getAccountingAccounts(filters = {}) {
    return this._get(`accounting-accounts?${createURLparams(filters)}`);
  }

  createAccountingAccount(data) {
    return this._post('accounting-accounts', data);
  }

  updateAccountingAccount(slug, data) {
    return this._put(`accounting-accounts/${slug}`, data);
  }

  getRequestForMileageAllowance(slug) {
    return this._get(
      `/requests/${slug}?with=combinedTravelElements,mileageAllowances,borderCrossings.country,travelExpenses,summary,subsummary,accountingTravelExpenses,accountingMileageAllowances,accessLumpSums,lumpSumsDeclaration,targetPoints,accountingTravelExpenses.mpk,accountingMileageAllowances.mpk,accountingTravelExpenses.currency`,
      true,
    );
  }

  updateRequestMileageAllowance(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/mileage-allowances/${id}`, fields);
  }

  addRequestSettlementAcceptor(request, user) {
    return this._post(`/requests/${request['slug']}/acceptors`, {
      slug: user['slug'],
    });
  }

  getRequestsToAcceptanceDashboard(filters) {
    filters = _.merge(
      {
        types: 'expense,trip',
        with: 'summary,mpk,project,costs,lumpSumsDeclaration',
        statuses: STATUS_WAITING_FOR_ACCEPTANCE,
      },
      filters,
    );
    return this._get('/requests', true, false, { params: filters });
  }

  addRequestTraveler(request, traveler) {
    return this._post(`/trip-planner/${request['slug']}/travelers`, traveler);
  }

  removeRequestTraveler(request, traveler) {
    return this._delete(`/trip-planner/${request['slug']}/travelers/${traveler['slug']}`);
  }

  getTripRequestsDashboard(filters) {
    filters = _.merge(
      {
        types: 'trip',
        quick_filters: 'current_trips',
        with: 'combinedTravelElements,acceptors,user,requestElementsSumAmount,summary,documents,documents.elements,documents.elements.type,costs,lumpSumsDeclaration',
      },
      filters,
    );
    return this._get('/index-dashboard/requests', true, false, { params: filters });
  }

  getDashboardCounter(userSlug) {
    return this._get(`/index-dashboard/counter?userSlug=${userSlug}`, true, false);
  }

  getDashboardWaitingToSettlementRequests(filters) {
    filters = _.merge(
      {
        types: 'expense,trip',
        with: 'combinedTravelElements,acceptors,user,requestElementsSumAmount,summary,documents,documents.elements,documents.elements.type,costs,lumpSumsDeclaration',
      },
      filters,
    );
    return this._get('/index-dashboard/requests', true, false, { params: filters });
  }

  updateRequestStatus(slug, status) {
    return this._put(`requests/${slug}/update-status?with=acceptors,settlementAcceptors`, {
      status,
    });
  }

  addAccountingTravelExpense(request, values) {
    return this._post(
      `requests/${request['slug']}/accounting-travel-expenses?with=accountingAccount,mpk`,
      values,
    );
  }

  editAccountingTravelExpense(request, travelExpense, values) {
    return this._put(
      `requests/${request['slug']}/accounting-travel-expenses/${travelExpense['id']}?with=accountingAccount,mpk`,
      values,
    );
  }

  removeAccountingTravelExpense(request, travelExpense) {
    return this._delete(
      `requests/${request['slug']}/accounting-travel-expenses/${travelExpense['id']}`,
    );
  }

  getInstance() {
    return this._get(`/auth/instance?with=translations`, true);
  }

  getInstanceBeforeLogin(lang = 'pl') {
    return this._get(`/auth/instance?lang=${lang}`, false);
  }

  ping() {
    return this._get('/instance/ping', false);
  }

  searchAirport(phrase, source = undefined) {
    let config = {};

    if (!isUndefined(source)) {
      config = { cancelToken: source.token };
    }
    return this._get(`airports?phrase=${phrase}`, true, false, { ...config });
  }

  assignToUser(request) {
    return this._put(`/requests/${request.slug}/assign-to-me`);
  }

  resetPassword(email, lang = 'pl') {
    return this._post('/auth/reset-password?lang=' + lang, { email }, false);
  }

  updateEdgePoint(slug, ...fields) {
    return this._put(`/requests/${slug}`, ...fields, true);
  }

  sendToAcceptance(request, fields, showAlerts = true) {
    return this._put(`/request/${request.slug}/send-to-acceptance`, fields, true, false, {
      showAlerts,
    });
  }

  requestCancel(request, fields) {
    return this._put(`/request/${request.slug}/cancel`, fields);
  }

  requestSendToSettlement(request, fields) {
    return this._put(`/request/${request.slug}/change-status-to-settlement`, fields);
  }

  requestSendToAcceptance(request, fields) {
    return this._put(`/request/${request.slug}/send-to-acceptance`, fields);
  }

  requestSendToSettlementAcceptance(request, fields) {
    return this._put(`/request/${request.slug}/send-to-settlement-acceptance`, fields);
  }

  requestAccept(request, fields = {}) {
    return this._put(`/request/${request.slug}/accept`, fields);
  }

  requestReject(request, fields = {}) {
    return this._put(`/request/${request.slug}/reject`, fields);
  }

  requestReturnToImprovement(request, fields) {
    return this._put(`/request/${request.slug}/return-to-improvement`, fields);
  }

  requestSettlementAccept(request, fields = {}) {
    return this._put(`/request/${request.slug}/settlement-accept`, fields);
  }

  requestSettlementReturnToImprovement(request, fields = {}) {
    return this._put(`/request/${request.slug}/settlement-return-to-improvement`, fields);
  }

  requestSendToERP(request, fields) {
    return this._put(`/request/${request.slug}/send-to-erp`, fields);
  }

  // alias
  requestSendToERPWithDate(request, fields) {
    return this.requestSendToERP(request, fields);
  }

  requestSendDocumentsToERP(request, fields) {
    return this._put(`/send-multiple-to-erp`, fields);
  }

  exportClaims(fields) {
    const path = '/exports/request/claims';
    const query = _.isEmpty(fields) || queryString.stringify(fields);

    return this._blob(query ? `${path}/?${query}` : path, {}, false, false, {}, true);
  }

  printClaims(fields) {
    const path = '/requests/settlements/export';
    const query = _.isEmpty(fields) || queryString.stringify(fields);

    return this._blob(query ? `${path}/?${query}` : path, {}, true, false, {}, true);
  }

  requestAccountantReturnToImprovement(request, fields) {
    return this._put(`/request/${request.slug}/accountant-return-to-improvement`, fields);
  }

  requestAccountantReturnToDecree(request, fields) {
    return this._put(`/request/${request.slug}/accountant-return-to-decree`, fields);
  }

  settlementAccountingCancelAssignmentToAccountant(request, fields) {
    return this._delete(`/settlement-accounting/${request.slug}/assignment-to-accountant`, fields);
  }

  deleteRequest(request) {
    return this._delete(`/requests/${request.slug}`);
  }

  getPrivateAccommodationCost(element, requestSlug) {
    const { arrival_at, departure_at, location } = element;
    const arrival_at_date = moment(arrival_at).format(config.apiDateTimeFormat);
    const departure_at_date = moment(departure_at).format(config.apiDateTimeFormat);

    return this._get(
      `/${requestSlug}/private-accomodations/count-lump-sum/${arrival_at_date}/${departure_at_date}/${
        location.country_code
      }?${Date.now()}`,
      true,
      false,
      { showAlerts: false },
    );
  }

  getMealDeductionsWidget(slug) {
    return this._get(`/requests/${slug}/meal-deductions-widget`);
  }

  getAccommodationDriveLumpSumsWidget(slug) {
    return this._get(`/requests/${slug}/accommodation-drive-lump-sums-widget`);
  }

  updateAccommodationDriveLumpSums(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/accommodation-drive-lump-sums/${id}`, fields);
  }

  updateRequestAccessLumpSump({ slug }, from, to) {
    return this._put(`/requests/${slug}/access-lump-sums`, [from, to]);
  }

  addRequestAccessLumpSump({ slug }, from, to) {
    return this._post(`/requests/${slug}/access-lump-sums`, [from, to]);
  }

  deleteRequestAccessLumpSump({ slug }, ids) {
    return this._delete(`/requests/${slug}/access-lump-sums?ids=${ids.join(',')}`);
  }

  removeTargetPoint(requestSlug, id, silent = false) {
    return this._delete(`requests/${requestSlug}/target-points/${id}`, true, false, {
      showAlerts: !silent,
    });
  }

  createTargetPoint(requestSlug, fields) {
    return this._post(`requests/${requestSlug}/target-points`, fields);
  }

  updateTargetPoint(requestSlug, id, fields) {
    return this._put(`requests/${requestSlug}/target-points/${id}`, fields);
  }

  updateTimelineWeight(requestSlug, order) {
    return this._put(`requests/${requestSlug}/update-timeline-weight`, order);
  }

  updateBorderCrossingsWeights(request, order) {
    return this._put(`requests/${request.slug}/update-border-crossings-weights`, order);
  }

  changeDeductionsWidgetState(slug, fields) {
    return this._put(`requests/${slug}/change-deductions-widget-state`, fields);
  }

  reGenerateAllowanceEntries(slug) {
    return this._put(`requests/${slug}/re-generate-allowances-entries`);
  }

  settlementsToAccept(user) {
    return this._get(
      `requests?with=summary,mpk,project,costs&statuses=acceptance_of_settlement&settlementAcceptors=${user.slug}`,
    );
  }

  getLastRequests() {
    return this._get('requests?quick_filters=latest,mine&with=summary,requestElementsSumAmount');
  }

  financialDashboardData(filters) {
    let params = Object.assign(
      { from: null, to: null, company_id: null, mpk_id: null, user_id: null },
      filters,
    );

    if (filters.from) {
      params.from = filters.from.format(config.apiDateFormat);
    }

    if (filters.to) {
      params.to = filters.to.format(config.apiDateFormat);
    }

    Object.keys(params).forEach((key) => {
      if (params[key] === null) {
        delete params[key];
      }
    });
    const path = '/reports/cockpit';
    const query = _.isEmpty(params) || queryString.stringify(params);
    return this._get(query ? `${path}/?${query}` : path);
  }

  createSimilar(request, date) {
    if (!date) {
      return this._get(`requests/${request.slug}/create-similar`);
    }

    return this._get(`requests/${request.slug}/create-similar/${date}`);
  }

  searchHotels(
    request,
    {
      date_from,
      date_to,
      lat,
      long,
      standard,
      formatted_address = '',
      city,
      country_code,
      range = 5,
      rooms,
      request_travelers,
    },
    { with_breakfasts },
  ) {
    if (isEndOfDay(date_from)) {
      date_from = moment(date_from).startOf('day');
    }

    date_from = moment(date_from).format(config.apiDateTimeFormat);
    date_to = moment(date_to).format(config.apiDateTimeFormat);

    return this._post(`request/${request.slug}/start-hotels-search`, {
      arrival_at: date_from,
      departure_at: date_to,
      location: {
        lat,
        long,
        formatted_address,
        country_code,
        city,
      },
      standard: 5,
      rooms,
      request_travelers,
      range,
      with_breakfasts,
    });
  }

  researchHotels(hotelsFilterRequest) {
    const body = hotelsFilterRequest.request.toJSON();

    return this._post(
      `/research-hotels/${hotelsFilterRequest.request.searchUUID}`,
      body,
      true,
      false,
      { cancelToken: hotelsFilterRequest.cancelToken.token },
    );
  }

  chooseHotelOffer(
    request,
    { search_uuid, offer_uuid, option_uuid, request_element },
  ): Promise<ChooseHotelOfferResponse> {
    const data = { search_uuid, offer_uuid, option_uuid };

    if (request_element) {
      data['request_element'] = request_element;
    }

    return this._post<ChooseHotelOfferResponse>(`request/${request.slug}/choose-offer`, data);
  }

  getOffers(request, { element_id, element_type }) {
    return this._get(
      `request/${request.slug}/get-offers?id=${element_id}&type=${element_type}`,
      true,
      false,
      {
        showAlerts: false,
      },
    );
  }

  getOffersByUuid(uuid) {
    return this._get(`/get-offers-by-search-uuid/${uuid}`, true, false, {
      showAlerts: false,
    });
  }

  getTrainStations(phrase, smart, cancelToken = null) {
    let config = {};

    if (cancelToken !== null) {
      config = { cancelToken: cancelToken.token };
    }

    return this._get(
      '/train-stations-vocabulary?phrase=' + phrase + (smart ? '&smart' : ''),
      true,
      false,
      config,
    );
  }

  searchTrains(
    request,
    {
      date_from,
      date_to,
      location_from,
      location_from_type,
      location_to,
      location_to_type,
      request_travelers,
    },
  ) {
    date_from = moment(date_from).format(config.apiDateTimeFormat);

    return this._post(`request/${request.slug}/start-trains-search`, {
      departure_at: date_from,
      date_to,
      location_from,
      location_from_type,
      location_to,
      location_to_type,
      request_travelers,
    });
  }

  chooseTrainOffer(request, { search_uuid, offer_uuid, option_uuid, request_element, attributes }) {
    const data = { search_uuid, offer_uuid, option_uuid, attributes };

    if (request_element) {
      data['request_element'] = request_element;
    }

    return this._post(`request/${request.slug}/choose-offer`, data);
  }

  bookOffer(request, uuid, data = {}) {
    return this._post(`/request/${request.slug}/book/${uuid}`, data);
  }

  valuateOffer(request, uuid) {
    return this._get(`/request/${request.slug}/valuate/${uuid}`);
  }

  cancelSingleBookedOffer(request, uuid) {
    return this._get(`/request/${request.slug}/cancel-single-booked-offer/${uuid}`);
  }

  getSingleBookedOffer(request, uuid) {
    return this._get(`/request/${request.slug}/get-single-booked-offer/${uuid}`);
  }

  getInstallments(filters = {}, cancelToken = null) {
    const config = {
      params: _.merge({ with: 'request.user', pagination: true }, filters),
    };

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    if (filters.sorter) {
      filters.sorter = JSON.stringify(filters.sorter);
    }

    return this._get(`/installments?${createURLparams({ ...filters })}`, true, false, config);
  }

  setInstallmentStatus = (installment, status) => {
    return this._post(`installments/${installment.id}/set-status`, { status });
  };

  setInstallmentAccountingStatus = (installment, status) => {
    return this._post(`installments/${installment.id}/set-accounting-status`, {
      accounted: status,
    });
  };

  setInstallmentPaidDate = (installment, newDate) => {
    return this._post(`installments/${installment.id}/set-paid-date`, { date: newDate });
  };

  setInstallmentAccountingDate = (installment, newDate) => {
    return this._post(`installments/${installment.id}/set-accounting-date`, { date: newDate });
  };

  setInstallmentExchangeRate = (installment, exchangeRate) => {
    return this._post(`installments/${installment.id}/set-exchange-rate`, {
      exchangeRate: exchangeRate,
    });
  };

  setInstallmentCurrency = (installment, amount, currency) => {
    return this._post(`installments/${installment.id}/set-paid-amount`, { amount, currency });
  };

  setInstallmentErpId = (installment, id) => {
    return this._post(`installments/${installment.id}/set-erp-id`, { erpId: id });
  };

  getUserProfile = (slug = null) => {
    return this._get(
      `/user/${
        slug ? `${slug}/` : ''
      }profile?with=mpk,hasMpks,nationality,citizenship,loyaltyCards,workLocation,supervisor`,
    );
  };

  updateUserProfile = (user) => {
    return this._put(
      `/users/${user.slug}?with=mpk,instance,nationality,citizenship,loyaltyCards,workLocation,availableIdentities`,
      { ...user },
    );
  };

  changePassword = (values) => {
    return this._post('/user/profile/change-password', values);
  };

  changePinCode = (values) => {
    return this._post('/user/profile/change-pin-code', values);
  };

  uploadAvatar = (user, file) => {
    const data = new FormData();
    data.append('avatar', file, file.name);
    return this._post(
      `/users/${user.slug}/change-avatar?with=mpk,instance,nationality,citizenship,loyaltyCards,workLocation,availableIdentities`,
      data,
    );
  };

  disableSearcher(request, element) {
    return this._post(`/disable-searcher`, {
      request_slug: request.slug,
      element_id: element.id,
      element_type: element.type,
    });
  }

  getLanguages = () => {
    return this._get('/available-languages');
  };

  getRequestExportURI = (request) => {
    return this._blob(`requests/${request.slug}/export`, {}, true, false, {}, true);
  };

  getReportsCockpit(args) {
    return this._blob(
      `reports/cockpit/xls?${createURLparams({ ...args })}`,
      {},
      true,
      false,
      {},
      true,
    );
  }

  getRequestsReport(args) {
    return this._blob('requests/report', { args }, true, false, {}, true);
  }

  getDocumentAsBlob(fetchEndpoint) {
    return this._blob(fetchEndpoint, {}, true, false, {}, false);
  }

  getAvatarAsBlob(fileName, userSlug, fileType = 'thumb', width = 300, height = 300) {
    return this._blob(
      'storage/avatars/' + userSlug + '/' + fileName + `/${fileType}/` + width + '/' + height,
      {},
      true,
      false,
      {},
      false,
    );
  }

  searchFlights(request, flightsSearchRequest) {
    const queryString = flightsSearchRequest.toString();

    return this._get(`request/${request.slug}/start-flights-search?${queryString}`);
  }

  getAirports(phrase, smart, cancelToken = null) {
    let config = {};

    if (cancelToken !== null) {
      config = { cancelToken: cancelToken.token };
    }

    return this._get(
      '/airports-vocabulary?phrase=' + phrase + (smart ? '&smart' : ''),
      true,
      false,
      config,
    );
  }

  chooseFlightOffer(
    request,
    { search_uuid, offer_uuid, option_uuid, request_element, attributes, paxes },
  ) {
    const data = { search_uuid, offer_uuid, option_uuid, attributes, paxes };

    if (request_element) {
      data['request_element'] = request_element;
    }

    return this._post(`request/${request.slug}/choose-offer`, data);
  }

  getPaymentCards(userSlug = null) {
    return this._get('/cards' + (userSlug ? `?userSlug=${userSlug}` : ''));
  }

  getCorporatePaymentCards() {
    return this._get('/cards/corporate');
  }

  getExportOptionsForStatements(tenant: string): Promise<Record<string, string>> {
    return this._get<Record<string, string>>(`/mycard/statements/export`, true, false, {
      headers: {
        Tenant: tenant,
      },
    });
  }

  exportStatements(statementIds: string[], entries: string[], type: string, tenant: string) {
    return this._blob(
      `/mycard/statements/export`,
      { statement_ids: statementIds, entry_ids: entries },
      true,
      false,
      {
        method: 'post',
        headers: {
          Accept: `${type}`,
          Tenant: tenant,
        },
      },
      true,
    );
  }

  createPaymentCard(type = 'individual', companies, cardholderName: string, userSlug = null) {
    const redirectUrl =
      type === 'individual' ? '/user/profile/charge-cards' : '/settings/instance/cards';

    const data = {
      type,
      successUrl: redirectUrl,
      errorUrl: redirectUrl,
      cardholderName,
    };

    if (companies && companies.length > 0) {
      data.companies = companies;
    }

    if (userSlug) {
      let redirectUrl = `/settings/instance/user/${userSlug}/charge-cards`;
      data.successUrl = redirectUrl;
      data.errorUrl = redirectUrl;

      return this._post(`/cards?userSlug=${userSlug}`, data);
    }

    return this._post('/cards', data);
  }

  deletePaymentCard(slug, userSlug = null) {
    return this._delete(`/cards/${slug}` + (userSlug ? `?userSlug=${userSlug}` : ''));
  }

  updateCardsPriority(cards, userSlug = null) {
    return this._post('/cards/update-cards-priority' + (userSlug ? `?userSlug=${userSlug}` : ''), {
      cards,
    });
  }

  getCompanies() {
    return this._get('/companies', true);
  }

  getTableConfigs() {
    return this._get<TableConfigResponse>('/table-config/options', true);
  }

  getAllRequests(filters = {}, cancelToken) {
    const config = {
      params: _.merge({ with: 'acceptors,user,requestElementsSumAmount' }, filters),
    };

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get('/requests/index-instance', true, false, config);
  }

  getLoggableTo(filters = {}, cancelToken = null) {
    filters = _.merge(
      {
        pagination: true,
        limit: 20,
        offset: 0,
      },
      filters,
    );

    if (cancelToken) {
      config.cancelToken = cancelToken.token;
    }

    return this._get(
      `/user/loggable-to?${createURLparams({ ...filters })}&with=company,groups`,
      true,
      false,
      config,
    );
  }

  relogin(slug) {
    return this._get(`auth/relogin/${slug}`);
  }

  loginAs(user) {
    return this._get(`auth/login-as/${user.slug}/${user.type || 'original_user'}`);
  }

  logoutAssistant() {
    return this._get('logout-assistant');
  }

  getFlights(flightsFilterRequest) {
    const queryString = flightsFilterRequest.request.toString();
    return this._get(
      `/get-flights/${flightsFilterRequest.request.searchUUID}?${queryString}`,
      true,
      false,
      { cancelToken: flightsFilterRequest.cancelToken.token },
    );
  }

  researchFlights(flightsFilterRequest) {
    const queryString = flightsFilterRequest.request.toString();
    return this._get(
      `/research-flights/${flightsFilterRequest.request.searchUUID}?${queryString}`,
      true,
      false,
      { cancelToken: flightsFilterRequest.cancelToken.token },
    );
  }

  getReturnFlights(flightsFilterRequest) {
    const queryString = flightsFilterRequest.toString();
    return this._get(
      `/get-return-flights/${flightsFilterRequest.searchUUID}/${flightsFilterRequest.targetOffer.uuid}?${queryString}`,
      true,
      false,
    );
  }

  fetchImportTypes(): Promise<HttpResponse<IImportOptions[]>> {
    return this._get<HttpResponse<IImportOptions[]>>('/imports/types', true, true);
  }

  resetInstance() {
    return this._get('instance/clear');
  }

  sendWelcomeEmailToUser(slug) {
    return this._post(`users/${slug}/notifications/welcome`, {});
  }
  sendWelcomeEmailToEveryone() {
    return this._post('users/notifications/welcome', {});
  }
  getOrganizationalStructure(slug = null) {
    return this._get('/organizational-structure' + (slug ? `?slug=${slug}` : ''));
  }

  updateRequestDelegation(request, delegation) {
    return this.updateRequestWithoutDependency(request.slug, { delegation });
  }

  getUserDeputies(slug = null) {
    if (slug) {
      return this._get(`/user-deputies?slug=${slug}`);
    }

    return this._get('/user-deputies');
  }

  addUserDeputy(deputyId, from, to, slug = null) {
    if (slug) {
      return this._post('/user-deputies', {
        from,
        to,
        deputy_id: deputyId,
        slug: slug,
      });
    }

    return this._post('/user-deputies', {
      from,
      to,
      deputy_id: deputyId,
    });
  }

  deleteUserDeputy(deputyId, slug = null) {
    if (slug) {
      return this._delete(`/user-deputies/${deputyId}?slug=${slug}`);
    }
    return this._delete(`/user-deputies/${deputyId}`);
  }

  unrealizeTrip(slug, value) {
    return this._put(
      `request/${slug}/set-as-unrealized?with=comments,comments.user,lumpSumsDeclaration,accountingTravelExpenses`,
    );
  }

  switchTripDidNotHavePlace(slug, value) {
    return this._put(
      `request/${slug}/set-trip-did-not-have-place?with=comments,comments.user,lumpSumsDeclaration,accountingTravelExpenses`,
      {
        tripDidNotHavePlace: value,
      },
    );
  }

  getGroups() {
    return this._get('/groups');
  }

  updateUserGroups(slug, groupIds) {
    return this._post(
      `/user/${slug}/groups?with=mpk,nationality,citizenship,loyaltyCards,workLocation,supervisor`,
      {
        groups: groupIds,
      },
    );
  }

  getInstanceUsers(filters, cancelToken) {
    filters = _.merge({ with: 'mpk,supervisor' }, filters);
    const config = { params: filters };
    if (cancelToken !== null) {
      config.cancelToken = cancelToken.token;
    }
    return this._get('/users/instance', true, false, config);
  }

  getUserAssistants(slug = null) {
    if (slug) {
      return this._get(`user-assistants?slug=${slug}`);
    }

    return this._get(`user-assistants`);
  }

  addUserAssistant(assistantId, slug = null) {
    return this._post('/user-assistants', {
      assistant_id: assistantId,
      slug: slug,
    });
  }

  deleteUserAssistant(deputyId, slug = null) {
    if (slug) {
      return this._delete(`/user-assistants/${deputyId}?slug=${slug}`);
    }

    return this._delete(`/user-assistants/${deputyId}`);
  }

  createRequestFor(user) {
    return this._post('/requests/create-for', {
      slug: user.slug,
    });
  }

  validateAccommodationDriveLumpSums(requestSlug, id) {
    return this._get(`requests/${requestSlug}/accommodation-drive-lump-sums/${id}/validate`);
  }

  setBlockUser(slug, blockingDate) {
    return this._post(`/users/${slug}/blocking`, {
      blockingDate,
    });
  }

  setSensitiveData(slug, payload) {
    if (!payload.supervisor_id) {
      delete payload.supervisor_id;
    }

    if (!payload.mpk_id) {
      delete payload.mpk_id;
    }

    if (payload.supervisor) {
      payload.supervisor_id = payload.supervisor.id;
    }

    if (payload.mpk) {
      payload.mpk_id = Array.isArray(payload.mpk)
        ? payload.mpk.find((mpk) => mpk.main)?.id
        : payload.mpk.id;
    }

    return this._post(`users/${slug}/sensitive-data?with=supervisor,mpk,hasMpks`, payload);
  }

  sendUnaccountedReminder(ids) {
    return this._post(`requests/send-unaccounted-reminder`, ids);
  }

  createProvider(values) {
    return this._post(`providers`, values);
  }

  logout() {
    return this._get('/auth/logout');
  }

  preValidateBorderCrossings(slug) {
    return this._get(`requests/${slug}/border-crossings/pre-validate`);
  }

  searchProvider(nip) {
    return this._get(`providers/search?nip=${nip}`, true, false);
  }

  getRequestAccountingDocuments(requestSlug) {
    return this._get(`/requests/${requestSlug}/accountingDocuments`);
  }

  getAccountDimensionsForRequestHeader(requestSlug) {
    return this._get(`/requests/${requestSlug}/account-dimensions/request_header`);
  }

  getAccountDimensionsForAllowanceHeader(requestSlug) {
    return this._get(`/requests/${requestSlug}/allowances/account-dimensions/header`);
  }

  getAccountDimensionsForDocumentElement(documentId) {
    return this._get(`/documents/${documentId}/account-dimensions/accounting`);
  }

  getAccountDimensionsForDocument(documentId) {
    return this._get(`/documents/${documentId}/account-dimensions/header`);
  }

  getAccountDimensionsForRequestAccounting(requestSlug) {
    return this._get(`/requests/${requestSlug}/account-dimensions/accounting`);
  }

  getAccountDimensionsForReports() {
    return this._get('/reports/account-dimensions');
  }

  getAccountDimensionForDocumentTypes() {
    return this._get('/document-element-types/account-dimensions');
  }

  updateRequestAccountDimension = (slug, dimension_id, dimension_item_id, headers) => {
    return this._post(
      `/requests/${slug}/account-dimension-items/${dimension_id}/${dimension_item_id}`,
      null,
      true,
      null,
      { headers },
    );
  };

  deleteRequestAccountDimension = (slug, dimension_item_id) => {
    return this._delete(
      `/requests/${slug}/account-dimension-items/${dimension_item_id}`,
      null,
      true,
    );
  };

  updateMileageSummaryAccountDimension = (slug, dimension_id, dimension_item_id) => {
    return this._post(
      `/requests/${slug}/mileage-allowance-summary/account-dimension-items/${dimension_id}/${dimension_item_id}`,
      null,
      true,
    );
  };

  deleteMileageSummaryAccountDimension = (slug, dimension_item_id) => {
    return this._delete(
      `/requests/${slug}/mileage-allowance-summary/account-dimension-items/${dimension_item_id}`,
      null,
      true,
    );
  };

  deleteMileageAllowanceAccountDimension = (
    slug,
    accounting_mileage_allowance_id,
    dimension_item_id,
  ) => {
    return this._delete(
      `/requests/${slug}/accounting-mileage-allowance/${accounting_mileage_allowance_id}/account-dimension-items/${dimension_item_id}`,
      null,
    );
  };

  updateMileageAllowanceAccountDimension = (
    slug,
    accounting_mileage_allowance_id,
    dimension_id,
    dimension_item_id,
  ) => {
    return this._post(
      `/requests/${slug}/accounting-mileage-allowance/${accounting_mileage_allowance_id}/account-dimension-items/${dimension_id}/${dimension_item_id}`,
      null,
      true,
    );
  };

  deleteAllowanceAccountDimension = (slug, dimension_item_id) => {
    return this._delete(
      `/requests/${slug}/allowances/account-dimension-items/${dimension_item_id}`,
      null,
    );
  };

  updateAllowanceAccountDimension = (slug, dimension_id, dimension_item_id) => {
    return this._post(
      `/requests/${slug}/allowances/account-dimension-items/${dimension_id}/${dimension_item_id}`,
      null,
      true,
    );
  };

  createMileageAllowance = (slug) => {
    return this._post(`/requests/${slug}/accounting-mileage-allowance?with=mpk`, null, true);
  };

  deleteMileageAllowance = (slug, mileage_id) => {
    return this._delete(`/requests/${slug}/accounting-mileage-allowance/${mileage_id}`, null);
  };

  updateMileageAllowance = (slug, mileage_id, data) => {
    return this._put(
      `/requests/${slug}/accounting-mileage-allowance/${mileage_id}?with=mpk`,
      data,
      true,
    );
  };

  updateTravelExpenseAccountDimension = (
    slug,
    travel_expense_id,
    dimension_id,
    dimension_item_id,
  ) => {
    return this._post(
      `/requests/${slug}/accounting-travel-expenses/${travel_expense_id}/account-dimension-items/${dimension_id}/${dimension_item_id}`,
      null,
      true,
    );
  };
  requestCardForSelectedUsers = (
    ids: string[],
    data: { limit: number; validity_period: number },
  ) => {
    return this._post(`/mycard/card-issue`, { ids, ...data }, true);
  };

  deleteTravelExpenseAccountDimension = (slug, travel_expense_id, dimension_item_id) => {
    return this._delete(
      `/requests/${slug}/accounting-travel-expenses/${travel_expense_id}/account-dimension-items/${dimension_item_id}`,
      null,
      true,
    );
  };

  updateDocumentElementAccountDimension = (
    document_id,
    document_element_id,
    dimension_id,
    dimension_item_id,
  ) => {
    return this._post(
      `/documents/${document_id}/elements/${document_element_id}/account-dimension-items/${dimension_id}/${dimension_item_id}`,
      null,
      true,
    );
  };

  deleteDocumentElementAccountDimension = (document_id, document_element_id, dimension_item_id) => {
    return this._delete(
      `/documents/${document_id}/elements/${document_element_id}/account-dimension-items/${dimension_item_id}`,
      null,
      true,
    );
  };

  updateDocumentAccountDimension = (document_id, dimension_id, dimension_item_id) => {
    return this._post(
      `/documents/${document_id}/account-dimension-items/${dimension_id}/${dimension_item_id}`,
      null,
      true,
    );
  };

  deleteDocumentAccountDimension = (document_id, dimension_item_id) => {
    return this._delete(
      `/documents/${document_id}/account-dimension-items/${dimension_item_id}`,
      null,
      true,
    );
  };

  getImports() {
    return this._get(`imports`);
  }

  createImport(payload) {
    return this._post(`imports`, payload);
  }

   createExport(payload) {
    return this._post(`exports`, payload)
  }

  getImportErrorsReport(importSlug) {
    return this._blob(`imports/${importSlug}/error-report`, {}, true, false, {}, true);
  }

  getExportFile(importSlug) {
    return this._blob(`imports/${importSlug}/download-export`, {}, true, false, {}, true);
  }

  makeRequestFromHttpLink<T, Y>(link: HttpLink<Y>, options: any = {}): Promise<T> {
    const headers = {
      ...link.headers,
      ...(options.headers || {}),
    };

    return this._call<T>(link.method, link.href, link.body, true, false, {
      headers,
    });
  }

  getReconciliations(
    link: HttpLink<[]>,
    pagination: Partial<Paginator> = {},
  ): Promise<ITransactionSuggestionResponse> {
    const headers = {
      Pagination: queryString.stringify(pagination),
    };

    return this.makeRequestFromHttpLink<ITransactionSuggestionResponse, []>(link, { headers });
  }

  getTransactions(
    link: HttpLink<[]>,
    pagination: Partial<Paginator> = {},
  ): Promise<ITransactionSuggestionResponse> {
    const headers = {
      Pagination: queryString.stringify(pagination),
    };

    return this.makeRequestFromHttpLink<ITransactionSuggestionResponse, []>(link, { headers });
  }

  getOrderCarEnumValues() {
    return this._get(`/rented-car/order-car-enum-values`);
  }

  getAvailableReports() {
    return this._get<HttpResponse<Report[]>>(`/reports`);
  }

  sendReportToEmail(id: number, filters) {
    return this._blob(
      `reports/${id}/generate?${createURLparams({ ...filters })}`,
      {},
      false,
      false,
      {},
      true,
    );
  }

  getMyCardStatements(link: HttpLink<any>, pagination: Partial<Paginator> = {}): Promise<any> {
    const headers = {
      Pagination: queryString.stringify(pagination),
    };

    return this.makeRequestFromHttpLink(link, { headers });
  }
}

export default new APIClient();
