import React, { Fragment } from 'react'
import { config } from '../../config'
import { Aside, Main, Wrapper } from '../../components/ui/Layout'
import { Helmet } from 'react-helmet'
import { TripRequestManager } from '../../containers/TripRequestManager'
import { RequestBreadcrumb } from './RequestBreadcrumb'
import trans from '../../trans'
import { RouteManager } from '../../containers/RouteManager'
import { RequestHeader } from '../RequestPageCommon/RequestHeader'
import { AccountingDocuments } from '../AccountingDocuments'
import { TripRequestSummary } from '../TripRequestPage/TripRequestSummary'
import { RequestComments } from '../RequestPageCommon/RequestComments'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { AddComment } from '../RequestPageCommon/AddComment'
import Button from '../ui/ButtonComponent'
import { Link } from 'react-router-dom'
import { TripRequestRightPanel } from '../TripRequestPage/TripRequestRightPanel'
import { TimelineElementsCompliance } from '../TripRequestPage/TimelineElementsCompliance'
import { Loader } from '../ui/LoadingOverlay/Loader'
import Paper from '@material-ui/core/Paper'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Icon from '../ui/IconComponent'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { sendToSettlement } from '../../store/app/trip-request'
import RequestCommentManager from '../../containers/RequestCommentManager/RequestCommentManager'
import BasicRequestInfoRenderer from '../RequestBasicInfoRenderer/RequestBasicInfoRenderer'
import { RequestType } from '../../types/request'

function Transition(props) {
  return <Slide direction='up' {...props} />
}

class TripRequestSummaryPage extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isSetAsUnrealized: false,
      type: undefined,
    }
  }

  openPopup = () => {
    this.setState({ isSetAsUnrealized: true })
  }

  closePopup = (isUnrealizeInProgress) => {
    if (isUnrealizeInProgress) {
      return
    }

    this.setState({ isSetAsUnrealized: false })
  }

  process = (request, unrealizeTrip) => {
    return unrealizeTrip(request.slug).then(() => {
      this.closePopup(false)
    })
  }

  renderContent = (
    request,
    sendToAcceptanceOfSettlement,
    changeRequestValue,
    fetchRequest,
    push,
    getRouteByName,
    unrealizeTrip,
    sendToSettlement,
    isModuleActive,
    isUnrealizeInProgress,
  ) => {
    return (
      <Fragment>
        <Main>
          <RequestBreadcrumb request={request} />

          <RequestHeader request={request} />

          <BasicRequestInfoRenderer />

          <AccountingDocuments
            request={request}
            fetchRequest={fetchRequest}
            showMileageAllowance={request.abilities.can_account_delegation}
            disableUpload
          />

          {request.type !== RequestType.INVOICE && (
            <AccountingDocuments
              request={request}
              fetchRequest={fetchRequest}
              disableUpload={true}
              disableDelete={true}
              showMileageAllowance={!request.abilities.can_account_delegation}
              readOnly={true}
              showSummary={true}
              types={['travel', 'confirmation']}
            />
          )}

          <TripRequestSummary request={request} />

          <TimelineElementsCompliance />

          <Ability ability={['sendToAcceptanceOfSettlement']}>
            <section className='section section--no-border section--no-border-top'>
              <AddComment request={request} changeRequestValue={changeRequestValue} />
            </section>
          </Ability>

          <div className='request-button-group'>
            <div style={{ display: 'flex' }}>
              <Link
                to={getRouteByName('main', request['type'] + 'RequestShow', {
                  id: request['slug'],
                })}
              >
                <Button link>{trans('request.summary-back-to-settlement')}</Button>
              </Link>
            </div>
            <Ability ability={['sendToAcceptanceOfSettlement']}>
              <Button
                key='save-draft'
                onClick={(e) => {
                  e.preventDefault()
                  return sendToAcceptanceOfSettlement().then(() => {
                    push(getRouteByName('main', `${request.type}RequestsList`))
                  })
                }}
                data-test='save-draft'
                primary
                pull_end
              >
                {trans('ui.send-to-acceptance-of-settlement')}
              </Button>
            </Ability>
          </div>

          <RequestCommentManager request={request}>
            {({ comments }) => {
              return <RequestComments comments={comments} />
            }}
          </RequestCommentManager>
        </Main>
        <Aside>
          <TripRequestRightPanel
            request={request}
            fetchRequest={fetchRequest}
            disableUploadTravelDocuments
          />
        </Aside>

        {/* TODO: the same dialog is in TripRequestPage, extract it as separate component */}
        <Dialog
          open={this.state.isSetAsUnrealized}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.closePopup(isUnrealizeInProgress)}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          className='last-requests__dialog'
        >
          <Paper square>
            <DialogTitle id='alert-dialog-slide-title'>
              {trans('global.finish-trip-confirm')}
            </DialogTitle>

            <DialogContent className='last-requests__dialog-content'>
              {trans('global.finish-trip-confirm-text')}
            </DialogContent>

            <DialogActions className='accommodation-confirm__dialog-actions'>
              <Icon
                className='last-requests__dialog-close'
                type='close'
                onClick={() => this.closePopup(isUnrealizeInProgress)}
              />
              <Button
                danger
                xxs
                onClick={() =>
                  this.process(request, unrealizeTrip, sendToSettlement, changeRequestValue)
                }
                color='primary'
              >
                {trans('deductions-widget.confirm-cancel-trip')}
              </Button>
              <Button
                outline
                xxs
                onClick={() => this.closePopup(isUnrealizeInProgress)}
                disabled={isUnrealizeInProgress}
                color='primary'
              >
                {trans('default.close')}
              </Button>
            </DialogActions>
          </Paper>
        </Dialog>
      </Fragment>
    )
  }

  renderLoader = () => {
    return (
      <Main>
        <div className='has-loader' style={{ height: '100%' }}>
          <Loader />
        </div>
      </Main>
    )
  }

  render() {
    return (
      <TripRequestManager fetchInitial={this.props.match.params.id}>
        {({
          request,
          isRequestLoaded,
          sendToAcceptanceOfSettlement,
          changeRequestValue,
          fetchRequest,
          unrealizeTrip,
          isModuleActive,
          isUnrealizeInProgress,
        }) => {
          return (
            <RouteManager>
              {({ push, getRouteByName }) => (
                <Wrapper>
                  <Helmet title={trans(config.titles.tripRequestSummaryShow)} />
                  {isRequestLoaded
                    ? this.renderContent(
                        request,
                        sendToAcceptanceOfSettlement,
                        changeRequestValue,
                        fetchRequest,
                        push,
                        getRouteByName,
                        unrealizeTrip,
                        sendToSettlement,
                        isModuleActive,
                        isUnrealizeInProgress,
                      )
                    : this.renderLoader()}
                </Wrapper>
              )}
            </RouteManager>
          )
        }}
      </TripRequestManager>
    )
  }
}

export { TripRequestSummaryPage }
export default { TripRequestSummaryPage }
