import { useCallback, useEffect, useState } from 'react'
import APIClient from '../../services/APIClient'
import moment from 'moment'
import { config } from '../../config'
import { useSelector } from 'react-redux'
import { getFilters } from '../../store/app/reports'
import { isAccountDimensionField } from '../../store/app/account-dimensions/form'

export interface Report {
  id: number;
  name: string;
}

export const LEGACY_REPORT_ID = -1;

export function useReports() {
  const filters = useSelector(getFilters);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    APIClient.getAvailableReports().then(res => {
      setReports(res.data)
    });
  }, [])

  const generateReport = useCallback((id: number) => {
    const args = { ...filters }

    Object.keys(filters)
      .filter(isAccountDimensionField)
      .forEach((key) => {
        args[key] = args[key].value
      })

    if (id === LEGACY_REPORT_ID) {
      args.from = moment(args.from).toString()
      args.to = moment(args.to).toString()

      return APIClient.getReportsCockpit(args)
    } else {
      args.from = moment(args.from).format(config.apiDateFormat)
      args.to = moment(args.to).format(config.apiDateFormat)

      return APIClient.sendReportToEmail(id, args);
    }
  }, [filters]);

  return { reports, generateReport };
}
