import React, { useCallback, useMemo, useRef, useState } from 'react';
import { HotelOfferEntryOption, HotelOfferEntryRaw } from '../../../types/hotel-offers';
import { AdvancedMarker, useAdvancedMarkerRef, useMap } from '@vis.gl/react-google-maps';
import classNames from 'classnames';
import { OfferMapImage } from '../OfferMapImage/OfferMapImage';

import './OfferMapMarker.scss';
import { OfferMapInfo } from '../OfferMapInfo/OfferMapInfo';
import { useInfoBoxPositioning } from './useInfoBoxPositioning';
import { SvgMapMarker } from './SvgMapMarker';
import { useSelector } from 'react-redux';
import { getFilter } from '../../../store/app/hotels-booking/selectors';

export function OfferMapMarker({
  offer,
  onClick,
  onHover,
}: {
  offer: HotelOfferEntryRaw;
  onClick: () => void;
  onHover: (hovered: boolean) => void;
}) {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const infoBoxRef = useRef<HTMLDivElement>(null);
  const [hovered, setHovered] = useState(false);
  const filters: any = useSelector(getFilter);
  const map = useMap();
  const memoizedPosition = useMemo(
    () => ({
      lat: offer.attributes.location.latitude,
      lng: offer.attributes.location.longitude,
    }),
    [offer.attributes.location],
  );

  const option = useMemo(() => findCheapestOption(offer, filters.refundable), [offer]);
  const markerClass = classNames({
    'offer-map-marker__icon': true,
    'offer-map-marker__icon--warning': !!option.rules.length,
  });

  const setHoveredState = useCallback(
    (hovered: boolean) => {
      setHovered(hovered);
      onHover(hovered);
    },
    [onHover],
  );

  useInfoBoxPositioning(marker, infoBoxRef, map, hovered);

  return (
    <AdvancedMarker
      ref={markerRef}
      position={memoizedPosition}
      onMouseEnter={() => setHoveredState(true)}
      onMouseLeave={() => setHoveredState(false)}
      className={classNames('offer-map-marker', { hovered })}
      zIndex={hovered ? 999 : 1}
    >
      <div className={markerClass} onClick={onClick}>
        <SvgMapMarker size={30} />
      </div>

      <div className='offer-map-marker__content' ref={infoBoxRef}>
        <div className='offer-map-marker__gallery'>
          <OfferMapImage photos={offer.attributes.photos} />
        </div>

        <div className='offer-map-marker__offer-container'>
          <OfferMapInfo offer={offer} option={option} />
        </div>
      </div>
    </AdvancedMarker>
  );
}

function findCheapestOption(
  offer: HotelOfferEntryRaw,
  refundable: boolean,
): HotelOfferEntryOption | null {
  if (!offer.options || offer.options.length === 0) {
    return null;
  }

  return offer.options
    .filter((option) => !refundable || option.attributes.refundable === refundable)
    .reduce((cheapest, current) => {
      const cheapestAmount = parseFloat(cheapest.amount.amount);
      const currentAmount = parseFloat(current.amount.amount);

      return currentAmount < cheapestAmount ? current : cheapest;
    });
}
