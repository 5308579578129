// initial state
import APIClient from '../../../services/APIClient'
import { push } from 'connected-react-router'
import { getRouteByName } from '../../../routes'
import { TYPE_EXPENSE } from '../../../constants/request'
import { config } from '../../../config'
import moment from 'moment'

const getInitialState = () => {
  return {
    comment: '',
    completion_date: moment(),
    decision: null,
  }
}

// constants
export const RESET = 'request-action::reset'
export const CHANGE = 'request-action::change'
export const MOUNT_POINT = 'request-action'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET:
      return getInitialState()
    case CHANGE:
      const { field, value } = action.payload
      return {
        ...state,
        [field]: value,
      }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const change = (field, value) => (dispatch) => {
  dispatch({
    type: CHANGE,
    payload: { field, value },
  })
}

export const action = (request, values) => (dispatch) => {
  const { comment, decision, completion_date } = values
  const fields = {}

  if (decision === SEND_TO_ERP_WITH_DATE) {
    fields.completion_date = completion_date.format(config.apiDateTimeFormat)
  }

  if (comment) {
    fields.comment = { content: comment }
  }

  if (decision === PRINT) {
    return APIClient.getRequestExportURI(request)
  }

  return APIClient[decision](request, fields).then(() => {
    let route = `${request.type}RequestsList`

    if (
      decision === SEND_TO_ERP ||
      decision === SEND_TO_ERP_WITH_DATE ||
      decision === ACCOUNTANT_RETURN_TO_IMPROVEMENT ||
      decision === CANCEL_ASSIGNMENT_TO_ACCOUNTANT
    ) {
      route = 'settlementRequestsList'
    }

    dispatch(push(getRouteByName('main', route)))
  })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getComment = (state) => {
  return getState(state).comment
}

export const getDecision = (state) => {
  return getState(state).decision
}

export const getCompletionDate = (state) => {
  return getState(state).completion_date
}

const CANCEL = 'requestCancel'
const SEND_TO_SETTLEMENT = 'requestSendToSettlement'
const SEND_TO_ACCEPTANCE = 'requestSendToAcceptance'
const SEND_TO_SETTLEMENT_ACCEPTANCE = 'requestSendToSettlementAcceptance'
const ACCEPT = 'requestAccept'
const REJECT = 'requestReject'
const RETURN_TO_IMPROVEMENT = 'requestReturnToImprovement'
const SETTLEMENT_ACCEPT = 'requestSettlementAccept'
const SETTLEMENT_RETURN_TO_IMPROVEMENT = 'requestSettlementReturnToImprovement'
const SEND_TO_ERP = 'requestSendToERP'
const ACCOUNTANT_RETURN_TO_IMPROVEMENT = 'requestAccountantReturnToImprovement'
const ACCOUNTANT_RETURN_TO_DECREE = 'requestAccountantReturnToDecree'
const CANCEL_ASSIGNMENT_TO_ACCOUNTANT = 'settlementAccountingCancelAssignmentToAccountant'
const SEND_TO_ERP_WITH_DATE = 'requestSendToERPWithDate'
const PRINT = 'print'

export const REQUEST_DECISIONS = {
  CANCEL,
  SEND_TO_SETTLEMENT,
  SEND_TO_ACCEPTANCE,
  SEND_TO_SETTLEMENT_ACCEPTANCE,
  ACCEPT,
  REJECT,
  RETURN_TO_IMPROVEMENT,
  SETTLEMENT_ACCEPT,
  SETTLEMENT_RETURN_TO_IMPROVEMENT,
  SEND_TO_ERP,
  ACCOUNTANT_RETURN_TO_IMPROVEMENT,
  ACCOUNTANT_RETURN_TO_DECREE,
  CANCEL_ASSIGNMENT_TO_ACCOUNTANT,
  SEND_TO_ERP_WITH_DATE,
  PRINT,
}
