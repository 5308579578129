import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { getMyCardTenantId } from '../store/app/user-profile/userProfileSlice';
import { MyCardResponse, Paginator } from '../types/response';
import APIClient from '../services/APIClient';
import queryString from 'query-string';
import moment from 'moment';
import { config } from '../config';

export default function useMyCardDataSource<T>(url: string) {
  const [data, setData] = useState<T[]>([]);
  const [paginator, setPaginator] = useState<Partial<Paginator>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<Record<string, unknown>>({});
  const filtersRef = useRef(filters);
  const tenant = useSelector(getMyCardTenantId);

  const loadItems = useCallback(
    (page: number) => {
      setLoading(true);

      const headers = {
        Tenant: tenant,
        Pagination: queryString.stringify({
          page,
          per_page: 20,
        }),
      };

      return APIClient.makeRequestFromHttpLink<MyCardResponse<T[]>, []>({
        body: [],
        headers,
        href: applyQueryParams(url, filtersRef.current), // Use ref here
        method: 'GET',
      })
        .then((response) => {
          setData(response.data);
          setPaginator(response.paginator);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setPaginator({ page: 1 });
        });
    },
    [url, tenant],
  );

  const debouncedLoadItems = useCallback(
    debounce((page: number) => loadItems(page), 300),
    [loadItems],
  );

  const setFilter = useCallback(
    (key: string, value: string) => {
      setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
      debouncedLoadItems(paginator.page);
    },
    [debouncedLoadItems, paginator.page],
  );

  const setPage = useCallback(
    (page: number) => {
      if (paginator.page !== page) {
        debouncedLoadItems(page);
      }
    },
    [paginator.page, debouncedLoadItems],
  );

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  useEffect(() => {
    debouncedLoadItems(1);
    // Cleanup debounced function
    return () => {
      debouncedLoadItems.cancel();
    };
  }, []);

  return {
    data,
    paginator,
    loading,
    setPage,
    load: debouncedLoadItems,
    filters,
    setFilter,
  };
}

function applyQueryParams(url: string, params?: Record<string, unknown>): string {
  let newUrl = url;

  if (params && Object.keys(params).length > 0) {
    const parsedUrl = queryString.parseUrl(url);

    const processedParams = Object.entries(params).reduce(
      (acc, [key, value]) => {
        if (isMomentObject(value)) {
          if (value.isValid()) {
            acc[key] = value.format(config.apiDateFormat);
          }
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, unknown>,
    );

    const mergedParams = {
      ...parsedUrl.query,
      ...processedParams,
    };

    newUrl = `${parsedUrl.url}?${queryString.stringify(mergedParams)}`;
  }

  return newUrl;
}

function isMomentObject(value: unknown): value is moment.Moment {
  return moment.isMoment(value);
}
