import React, { Fragment } from 'react'
import * as constants from '../../constants/request'
import { Aside, Main, Wrapper } from '../../components/ui/Layout'
import { Helmet } from 'react-helmet'
import { TripRequestManager } from '../../containers/TripRequestManager'
import { ExpenseRequestPageDraft } from './StatusPages/ExpenseRequestPageDraft'
import { ExpenseRequestPageWaitingForAcceptance } from './StatusPages/ExpenseRequestPageWaitingForAcceptance'
import { ExpenseRequestPageSettlement } from './StatusPages/ExpenseRequestPageSettlement'
import { ExpenseRequestPageAccounting } from './StatusPages/ExpenseRequestPageAccounting'
import { RequestBreadcrumb } from './RequestBreadcrumb'
import { ExpenseRequestPath } from './ExpenseRequestPath'
import { ExpenseRequestPageRejected } from './StatusPages/ExpenseRequestPageRejected'
import { ExpenseRequestPageCancelled } from './StatusPages/ExpenseRequestPageCancelled'
import { ExpenseRequestPageFinish } from './StatusPages/ExpenseRequestPageFinish'
import { ExpenseRequestPageTransferred } from './StatusPages/ExpenseRequestPageTransferred'
import { ExpenseRequestPageTransferError } from './StatusPages/ExpenseRequestPageTransferError'
import trans from '../../trans'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { userProfile } from '../../store/app/user-profile/providers/userProfile'
import { compose } from 'redux'
import { ExpenseRequestPageAcceptanceSettlement } from './StatusPages/ExpenseRequestPageAcceptanceSettlement'

class ExpenseRequestPage extends React.Component<any, any> {
  renderDraft(
    request,
    {
      currencies,
      instanceCurrency,
      fetchRequest,
      sendToAcceptance,
      currentUser,
      deleteRequest,
      changeRequestValue,
      updateRequest,
      isModuleActive,
      saveDecision,
    },
  ) {
    return (
      <ExpenseRequestPageDraft
        request={request}
        instanceCurrency={instanceCurrency}
        fetchRequest={fetchRequest}
        currencies={currencies}
        sendToAcceptance={sendToAcceptance}
        currentUser={currentUser}
        deleteRequest={deleteRequest}
        changeRequestValue={changeRequestValue}
        updateRequest={updateRequest}
        isModuleActive={isModuleActive}
        saveDecision={saveDecision}
      />
    )
  }

  renderWaitingForAcceptance(request, { cancelRequest, currentUser, isModuleActive, currencies }) {
    return (
      <ExpenseRequestPageWaitingForAcceptance
        request={request}
        cancelRequest={cancelRequest}
        currentUser={currentUser}
        isModuleActive={isModuleActive}
        currencies={currencies}
      />
    )
  }

  renderSettlement(
    request,
    {
      sendToAcceptanceOfSettlement,
      fetchRequest,
      changeRequestValue,
      instanceCurrency,
      cancelRequest,
      isModuleActive,
    },
  ) {
    return (
      <ExpenseRequestPageSettlement
        request={request}
        sendToAcceptanceOfSettlement={sendToAcceptanceOfSettlement}
        fetchRequest={fetchRequest}
        changeRequestValue={changeRequestValue}
        instanceCurrency={instanceCurrency}
        cancelRequest={cancelRequest}
        isModuleActive={isModuleActive}
      />
    )
  }

  renderAcceptanceOfSettlement(request) {
    return <ExpenseRequestPageAcceptanceSettlement request={request} />
  }

  renderAccounting(request, changeRequestValue, fetchRequest) {
    return (
      <ExpenseRequestPageAccounting
        request={request}
        changeRequestValue={changeRequestValue}
        fetchRequest={fetchRequest}
      />
    )
  }

  renderRejected(request, { currentUser, isModuleActive }) {
    return (
      <ExpenseRequestPageRejected
        request={request}
        currentUser={currentUser}
        isModuleActive={isModuleActive}
      />
    )
  }

  renderCancelled(request, { currentUser, isModuleActive }) {
    return (
      <ExpenseRequestPageCancelled
        request={request}
        currentUser={currentUser}
        isModuleActive={isModuleActive}
      />
    )
  }

  renderFinish(request, { currentUser, isModuleActive, fetchRequest, changeRequestValue }) {
    return (
      <ExpenseRequestPageFinish
        request={request}
        currentUser={currentUser}
        isModuleActive={isModuleActive}
        fetchRequest={fetchRequest}
        changeRequestValue={changeRequestValue}
      />
    )
  }

  renderTransferError(request, { fetchRequest, instanceCurrency }) {
    return (
      <ExpenseRequestPageTransferError
        request={request}
        fetchRequest={fetchRequest}
        instanceCurrency={instanceCurrency}
      />
    )
  }

  renderTransferred(request, { fetchRequest, instanceCurrency, changeRequestValue }) {
    return (
      <ExpenseRequestPageTransferred
        request={request}
        fetchRequest={fetchRequest}
        instanceCurrency={instanceCurrency}
        changeRequestValue={changeRequestValue}
      />
    )
  }

  render() {
    const {
      userProfile: {
        selectors: { data: user },
      },
    } = this.props
    const createPeriodic = this.props.match.path.includes('add-periodic')
    const type = this.props.match.path.includes('invoice') ? 'invoice' : 'expense'

    return (
      <Ability
        ability={['showExpense']}
        abilities={user.abilities}
        fallback={() => trans('default.deny')}
      >
        <TripRequestManager
          fetchInitial={this.props.match.params.id}
          requestType={type}
          createPeriodic={createPeriodic}
        >
          {({
            request,
            isRequestLoading,
            isRequestLoaded,
            currencies,
            areCurrenciesLoading,
            areCurrenciesLoaded,
            sendToAcceptance,
            sendToSettlement,
            sendToAcceptanceOfSettlement,
            instanceCurrency,
            currentUser,
            deleteRequest,
            cancelRequest,
            changeRequestValue,
            updateRequest,
            isModuleActive,
            saveDecision,
            fetchRequest,
          }) => {
            if (!isRequestLoaded) {
              return null
            }

            return (
              <Wrapper>
                <Helmet title={trans(`global.request-type-${request.type}`)} />
                <Main>
                  <RequestBreadcrumb request={request} />

                  {request['status'] === constants.STATUS_DRAFT &&
                    this.renderDraft(request, {
                      currencies,
                      instanceCurrency,
                      fetchRequest,
                      sendToAcceptance,
                      currentUser,
                      deleteRequest,
                      changeRequestValue,
                      updateRequest,
                      isModuleActive,
                      saveDecision,
                    })}
                  {request['status'] === constants.STATUS_WAITING_FOR_ACCEPTANCE &&
                    this.renderWaitingForAcceptance(request, {
                      cancelRequest,
                      currentUser,
                      isModuleActive,
                      currencies,
                    })}
                  {request['status'] === constants.STATUS_SETTLEMENT &&
                    this.renderSettlement(request, {
                      sendToAcceptanceOfSettlement,
                      fetchRequest,
                      changeRequestValue,
                      instanceCurrency,
                      cancelRequest,
                      isModuleActive,
                    })}
                  {request['status'] === constants.STATUS_ACCEPTANCE_OF_SETTLEMENT &&
                    this.renderAcceptanceOfSettlement(request)}
                  {request['status'] === constants.STATUS_ACCOUNTING &&
                    this.renderAccounting(request, changeRequestValue, fetchRequest)}

                  {request['status'] === constants.STATUS_TRANSFERRED &&
                    this.renderTransferred(request, {
                      currentUser,
                      isModuleActive,
                      instanceCurrency,
                      fetchRequest,
                      changeRequestValue,
                    })}

                  {request['status'] === constants.STATUS_TRANSFER_ERROR &&
                    this.renderTransferError(request, {
                      currentUser,
                      isModuleActive,
                      instanceCurrency,
                      fetchRequest,
                    })}

                  {request['status'] === constants.STATUS_REJECTED &&
                    this.renderRejected(request, {
                      currentUser,
                      isModuleActive,
                    })}
                  {request['status'] === constants.STATUS_CANCELED &&
                    this.renderCancelled(request, {
                      currentUser,
                      isModuleActive,
                    })}
                  {request['status'] === constants.STATUS_FINISH &&
                    this.renderFinish(request, {
                      currentUser,
                      isModuleActive,
                      fetchRequest,
                      changeRequestValue,
                    })}
                </Main>
                <Aside>
                  <ExpenseRequestPath request={request} fetchRequest={fetchRequest} />
                </Aside>
              </Wrapper>
            )
          }}
        </TripRequestManager>
      </Ability>
    )
  }
}

const withProfile = userProfile(false, false)

ExpenseRequestPage = compose(withProfile)(ExpenseRequestPage)

export { ExpenseRequestPage }
export default { ExpenseRequestPage }
