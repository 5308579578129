import React from 'react';

import moment from 'moment/moment';

import trans from '../../trans';
import ToggleableBottomFilters from '../ToggleableFilters/ToggleableBottomFilters';
import ToggleableFilters from '../ToggleableFilters/ToggleableFilters';
import ToggleableInputWrapper from '../ToggleableFilters/ToggleableInputWrapper';
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters';
import ToggleableQuickFiltersContainer from '../ToggleableFilters/ToggleableQuickFiltersContainer';
import ToggleableSearchField from '../ToggleableFilters/ToggleableSearchField';
import { DatePickerField } from '../ui/Form';
import { StateTextButton } from '../ui/StateTextButton';

interface Props {
  filters: Record<string, unknown>;
  setFilter: (key: string, value: unknown) => void;
}

function StatementsPageFilters({ filters, setFilter }: Props) {
  const filtersCount = Object.keys(filters).length;

  const bottomFilters = (
    <>
      <ToggleableInputWrapper label={trans('global.filter-period')}>
        <div className='filters__period-container'>
          <DatePickerField
            placeholder={trans('global.datepicker-placeholder')}
            onChange={(value) => setFilter('booked_at_from', moment(value))}
            isClearable={true}
            value={filters['booked_at_from']}
          />
          <span className='filters__separator'>-</span>
          <DatePickerField
            placeholder={trans('global.datepicker-placeholder')}
            onChange={(value) => setFilter('booked_at_to', moment(value))}
            isClearable={true}
            minDate={filters['booked_at_from']}
            value={filters['booked_at_to']}
          />
        </div>
      </ToggleableInputWrapper>
    </>
  );

  return (
    <form>
      <ToggleableFilters filtersCount={filtersCount}>
        {({ isOpen }) => (
          <>
            <ToggleableQuickFilters>
              <ToggleableSearchField
                value={filters['phrase']}
                onChange={(value) => {
                  setFilter('phrase', value);
                }}
              />

              <ToggleableQuickFiltersContainer>
                <StateTextButton
                  onClick={(activate) => {
                    setFilter('quick_filters', activate ? 'mine' : null);
                  }}
                  active={filters['quick_filters'] === 'mine'}
                >
                  {trans('my-card.statement_quick_filter_mine')}
                </StateTextButton>

                <StateTextButton
                  onClick={(activate) => {
                    setFilter('quick_filters', activate ? 'opened' : null);
                  }}
                  active={filters['quick_filters'] === 'opened'}
                >
                  {trans('my-card.statement_quick_filter_opened')}
                </StateTextButton>

                <StateTextButton
                  onClick={(activate) => {
                    setFilter('quick_filters', activate ? 'closed' : null);
                  }}
                  active={filters['quick_filters'] === 'closed'}
                >
                  {trans('my-card.statement_quick_filter_closed')}
                </StateTextButton>
              </ToggleableQuickFiltersContainer>
            </ToggleableQuickFilters>

            <ToggleableBottomFilters isOpen={isOpen}>{bottomFilters}</ToggleableBottomFilters>
          </>
        )}
      </ToggleableFilters>
    </form>
  );
}

export default StatementsPageFilters;
