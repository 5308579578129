import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '../Tooltip';
import Icon from '../ui/IconComponent';
import { get } from 'lodash';
import { trans } from '../../trans';

class OptionBrokenRules extends Component<any, any> {
  render() {
    const { option } = this.props;
    const rules = get(option, 'rules', []).map((rule, key) => (
      <div key={key}>{trans(rule.message.name, rule.message.params)}</div>
    ));

    if (!rules.length) {
      return null;
    }

    return (
      <Tooltip html={rules}>
        <Icon className='is-color-warning selected-hotel__price-icon' type='warning' />
      </Tooltip>
    );
  }
}

OptionBrokenRules.propTypes = {
  option: PropTypes.object.isRequired,
};

export default OptionBrokenRules;
export { OptionBrokenRules };
