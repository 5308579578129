import PropTypes from 'prop-types';
import React from 'react';
import { formValueSelector } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { AccountDocumentElementForm as FormComponent } from '../../components/AccountDocumentElementForm';
import { bindActionCreators } from 'redux';
import {
  getDocumentElementsTypes,
  isDocumentElementDeletingInProgress,
  removeDocumentElement,
  saveDocumentElement,
  updateAddFormVisible,
} from '../../store/app/document-account-page';
import { createAutoSaveForm, getFormValues } from '../../utils/forms';
import { getActiveAccountingAccountSelectOptions } from '../../store/app/accounting-account';
import { getActiveMpkSelectOptions } from '../../store/app/mpks';
import { getVatNumbersSelector } from '../../store/app/vat';
import { getActiveProjectsSelectOptions } from '../../store/app/projects';
import { getCache, setCache } from '../../store/app/account-dimensions';
import {
  generateAccountDimensionFormValues,
  isAccountDimensionField,
  submitAccountDimension,
} from '../../store/app/account-dimensions/form';
import APIClient from '../../services/APIClient';
import { getInstance, isFeatureEnabled } from '../../store/app/instance';
import {
  FEATURE_DEDUCTIBILITY_ENABLED,
  FEATURE_MANAGE_MPK_ENABLED,
} from '../../constants/features';
import { getTypes } from '../../store/app/request-other-costs';

const AccountDocumentElementFormBase = createAutoSaveForm(FormComponent, {
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  save: (name, value, dispatch, props) => {
    const {
      document,
      element,
      saveDocumentElement,
      accountDimensionItems,
      accountDimensions,
      setCache,
      form,
    } = props;

    if (isAccountDimensionField(name)) {
      return submitAccountDimension({
        name,
        value,
        setCache,
        dimensions: accountDimensions,
        items: accountDimensionItems,
        updateMethod: (dim_id) =>
          APIClient.updateDocumentElementAccountDimension(
            document.id,
            element.id,
            dim_id,
            value.id,
          ),
        deleteMethod: (dim_id) =>
          APIClient.deleteDocumentElementAccountDimension(document.id, element.id, dim_id),
      });
    }

    if (name === 'mpk') {
      name = 'mpk_id';
      value = value ? value.id : null;
    }

    return saveDocumentElement(document, element, null, { [name]: value }).then((response) => {
      if (response.net !== element.net) {
        props.change('net', response.net);
      }
    });
  },
});

AccountDocumentElementFormBase.propTypes = {
  document: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  instanceCurrency: PropTypes.string.isRequired,
  instance: PropTypes.string.isRequired,
  getExchangeRate: PropTypes.func.isRequired,
  isDeductibilityEnabled: PropTypes.bool.isRequired,
};

const getFormName = (element) => `document-element-form-${element['id'] || 'new'}`;

const mapStateToProps = (state, props) => {
  const {
    element,
    document,
    element: { accountDimensionItems = [] },
    accountDimensions = [],
  } = props;

  const currentVatId = element['vatNumber'] && element['vatNumber']['id'];
  const vatNumbers = getVatNumbersSelector(state, [currentVatId]);
  const documentElementTypes = getDocumentElementsTypes(state);
  const mpks = getActiveMpkSelectOptions(state, [element.mpk_id, document.request.mpk_id]);
  const projects = getActiveProjectsSelectOptions(state, [
    element.project_id,
    document.request.project_id,
  ]);
  const accountingAccounts = getActiveAccountingAccountSelectOptions(
    state,
    element['accountingAccount'],
  );
  const isDeletingInProgress = isDocumentElementDeletingInProgress(state, element);
  const instanceCurrency = props['instanceCurrency'];
  const types = getDocumentElementsTypes(state);
  const selector = formValueSelector(getFormName(element), (state) => {
    return state.get('form').toJS();
  });

  return {
    initialValues: fromJS({
      net: element['net'],
      gross: element['gross'],
      vat_number_id: currentVatId,
      deductibility: element['deductibility'],
      description: element['description'],
      type_id: element['type_id'],
      mpk: element['mpk'],
      project_id: element['project_id'],
      accounting_account_id: element['accountingAccount']
        ? element['accountingAccount']['id']
        : null,
      cost_of_earning: element['cost_of_earning'] === true,
      asset: element['asset'] === true,
      exchange_rate:
        element['exchange_rate'] !== null
          ? element['exchange_rate']
          : element['default_exchange_rate'],
      pristine_exchange_rate: element['exchange_rate'],
      default_exchange_rate: element['default_exchange_rate'],
      ...generateAccountDimensionFormValues(accountDimensions, accountDimensionItems),
    }),
    form: getFormName(element),
    vatNumbers,
    projects,
    documentElementTypes,
    mpks,
    accountingAccounts,
    element,
    isDeletingInProgress,
    document,
    net: selector(state, 'net'),
    gross: selector(state, 'gross'),
    vat_number_id: selector(state, 'vat_number_id'),
    data: getFormValues(getFormName(element), state),
    deductibility: selector(state, 'deductibility'),
    instanceCurrency,
    accountDimensions,
    types: getTypes(state),
    accountDimensionItems: getCache(state)(getFormName(element)),
    instance: getInstance(state),
    isDeductibilityEnabled: isFeatureEnabled(FEATURE_DEDUCTIBILITY_ENABLED)(state),
    hasMpkFeatureEnabled: isFeatureEnabled(FEATURE_MANAGE_MPK_ENABLED)(state),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return bindActionCreators(
    {
      saveDocumentElement,
      removeDocumentElement,
      updateAddFormVisible,
      setCache: (data) => setCache({ name: getFormName(props.element), data }),
    },
    dispatch,
  );
};

const AccountDocumentElementForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountDocumentElementFormBase);

export { AccountDocumentElementForm };
export default { AccountDocumentElementForm };
