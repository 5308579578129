import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import { ASYNC_SELECT_DEFAULT_LIMIT } from '../../../components/ui/Form/AsyncSelectField'
import trans from '../../../trans'
import { getFormValues } from '../../../utils/forms'
import { FORM_NAME as NEW_USER_FORM_NAME } from '../../../containers/UserCreateForm/UserCreateForm'
import { FORM_NAME as UPDATE_USER_FORM_NAME } from '../../../components/UserProfilePage/SensitiveData/SensitiveDataForm'
import store from '../../../store'
import { SelectLoadingOptions, SelectOption } from '../../../types/form'

export const USERS_FOR_SELECT_MOUNT_POINT = 'users-for-select'

const getInitialState = () => ({
  byCompanyId: {},
  error: null,
  isLoading: false,
  isLoaded: false,
})

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const usersForSelectSlice = createSlice({
  name: USERS_FOR_SELECT_MOUNT_POINT,
  initialState: getInitialState(),
  reducers: {
    resetUsersForSelect(state) {
      state = getInitialState()
    },
    setUsersForSelect(state, action) {
      const { companyId, data } = action.payload

      state.byCompanyId[companyId] = data
    },
    startLoading(state) {
      state.isLoading = true
      state.isLoaded = false
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.isLoaded = true
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.isLoaded = false
      state.error = action.payload
    },
  },
})

export const {
  resetUsersForSelect,
  setUsersForSelect,
  startLoading,
  setLoadingSuccess,
  setLoadingFailed,
} = usersForSelectSlice.actions

export default usersForSelectSlice.reducer

// selectors
const getState = (state) => state.get(USERS_FOR_SELECT_MOUNT_POINT)

export const getUsersForSelect = (state) => getState(state).byCompanyId
export const isLoading = (state) => getState(state).isLoading
export const isLoaded = (state) => getState(state).isLoaded

// memoized selectors, improve performance
export const getUsersForSelectByCompanyIdSelector = createSelector(
  [(state, _companyId) => getUsersForSelect(state), (_state, companyId) => companyId],
  (items, companyId) => items[companyId] || [],
)

export const makeGetUsersForSelectByCompanyIdOptionsSelector = () => {
  return createSelector(
    (state, companyId, _excludeIds) => getUsersForSelectByCompanyIdSelector(state, companyId),
    (state, companyId, excludeIds) => excludeIds,
    (items, excludeIds = []) =>
      items
        .filter((item) => !excludeIds.includes(item.id))
        .map((item) => ({
          value: item.id,
          label: item.full_name,
        })),
  )
}

// action thunks
export const fetchUsersForSelect =
  (companyId = null) =>
  async (dispatch) => {
    dispatch(startLoading())

    try {
      const { data } = await APIClient.getUsersForSelect(companyId)
      dispatch(setUsersForSelect({ data, companyId }))
      dispatch(setLoadingSuccess())
    } catch (err) {
      dispatch(setLoadingFailed(err))
    }
  }

export const fetchUsersForSelectIfNeeded =
  (companyId = null) =>
  async (dispatch, state) => {
    const { byCompanyId, isLoading } = getState(state())

    if (byCompanyId[companyId] || isLoading) {
      return
    }

    return dispatch(fetchUsersForSelect(companyId))
  }

export const loadOptionsForUsers = async (
  search: string,
  prevOptions: SelectOption[],
): Promise<SelectLoadingOptions> => {
  const response = await APIClient.getUsersForSelect(null, {
    status: 'active',
    phrase: search,
    limit: ASYNC_SELECT_DEFAULT_LIMIT,
    offset:
      !search && prevOptions.length > 0
        ? prevOptions.length + ASYNC_SELECT_DEFAULT_LIMIT
        : prevOptions.length,
  })
  let data = response.data.map((user) => {
    return {
      value: user.slug,
      label: `${user.first_name} ${user.last_name}`,
      ...user,
    }
  })

  const hasPlaceholder =
    prevOptions.filter((item) => item.label === trans('global.filter-placeholder-everyone'))
      .length > 0

  if (!hasPlaceholder) {
    data = [
      {
        label: trans('global.filter-placeholder-everyone'),
        value: null,
        onSelectResetsInput: true,
      },
      ...data,
    ]
  }

  return {
    options: data,
    hasMore: response.pagination.total > response.pagination.offset,
  }
}

export const loadOptionsForReportsUsers = async (
  search: string,
  prevOptions: SelectOption[],
): Promise<SelectLoadingOptions> => {
  const response = await APIClient.getUsersForReportSelect(null, {
    status: 'active',
    phrase: search,
    limit: ASYNC_SELECT_DEFAULT_LIMIT,
    offset:
      !search && prevOptions.length > 0
        ? prevOptions.length + ASYNC_SELECT_DEFAULT_LIMIT
        : prevOptions.length,
  })
  let data = response.data.map((user) => {
    return {
      value: user.slug,
      label: `${user.first_name} ${user.last_name}`,
      ...user,
    }
  })

  const hasPlaceholder =
    prevOptions.filter((item) => item.label === trans('global.filter-placeholder-everyone'))
      .length > 0

  if (!hasPlaceholder) {
    data = [
      {
        label: trans('global.filter-placeholder-everyone'),
        value: null,
        onSelectResetsInput: true,
      },
      ...data,
    ]
  }

  return {
    options: data,
    hasMore: response.pagination.total > response.pagination.offset,
  }
}

export const loadOptionsForAcceptors = async (search, prevOptions) => {
  const response = await APIClient.getAcceptors({
    phrase: search,
    limit: ASYNC_SELECT_DEFAULT_LIMIT,
    offset:
      !search && prevOptions.length > 0
        ? prevOptions.length + ASYNC_SELECT_DEFAULT_LIMIT
        : prevOptions.length,
  })
  let data = response.data.map((user) => {
    return {
      value: user.slug,
      label: `${user.first_name} ${user.last_name}`,
      ...user,
    }
  })

  return {
    options: data,
    hasMore: response.pagination.total > response.pagination.offset,
  }
}

export const loadOptionsForSettlementAcceptors = async (search, prevOptions) => {
  const response = await APIClient.getSettlementAcceptors({
    phrase: search,
    limit: ASYNC_SELECT_DEFAULT_LIMIT,
    offset:
      !search && prevOptions.length > 0
        ? prevOptions.length + ASYNC_SELECT_DEFAULT_LIMIT
        : prevOptions.length,
  })
  let data = response.data.map((user) => {
    return {
      value: user.slug,
      label: `${user.first_name} ${user.last_name}`,
      ...user,
    }
  })

  return {
    options: data,
    hasMore: response.pagination.total > response.pagination.offset,
  }
}

export const loadOptionsForRequestTravelers = async (search, prevOptions, companyId = null) => {
  const response = await APIClient.getUsersForSelect(companyId, {
    status: 'active',
    phrase: search,
    limit: ASYNC_SELECT_DEFAULT_LIMIT,
    offset:
      !search && prevOptions.length > 0
        ? prevOptions.length + ASYNC_SELECT_DEFAULT_LIMIT
        : prevOptions.length,
  })
  let data = response.data.map((user) => {
    return {
      value: user.slug,
      label: `${user.first_name} ${user.last_name}`,
      ...user,
    }
  })

  return {
    options: data,
    hasMore: response.pagination.total > response.pagination.offset,
  }
}

export const loadOptionsForGuSupervisorForNewUser = async (search, prevOptions) => {
  let companyId = getFormValues(NEW_USER_FORM_NAME, store.getState()).company_id

  return fetchAndTransformUsersForGuInternal(null, search, prevOptions)
}

export const loadOptionsForGuSupervisorForUpdateUser = async (search, prevOptions) => {
  let companyId = getFormValues(UPDATE_USER_FORM_NAME, store.getState()).company_id

  return fetchAndTransformUsersForGuInternal(null, search, prevOptions)
}

const fetchAndTransformUsersForGuInternal = async (companyId, search, prevOptions) => {
  const response = await APIClient.getUsersForSelect(companyId, {
    status: 'active',
    phrase: search,
    limit: ASYNC_SELECT_DEFAULT_LIMIT,
    offset:
      !search && prevOptions.length > 0
        ? prevOptions.length + ASYNC_SELECT_DEFAULT_LIMIT
        : prevOptions.length,
  })
  let data = response.data.map((user) => {
    return {
      value: user.slug,
      label: `${user.first_name} ${user.last_name}`,
      name: `${user.first_name} ${user.last_name}`,
      id: user.slug,
      ...user,
    }
  })

  return {
    options: [
      {
        label: trans('instance-users.none'),
        full_name: trans('instance-users.none'),
        name: trans('instance-users.none'),
        value: null,
        onSelectResetsInput: true,
      },
      ...data,
    ],
    hasMore: response.pagination.total > response.pagination.offset,
  }
}
