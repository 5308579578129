import React from 'react';
import { noop } from 'lodash/util';
import ToggleableInputWrapper from '../ToggleableFilters/ToggleableInputWrapper';
import trans from '../../trans';
import { DatePickerField } from '../ui/Form';
import ToggleableFilters from '../ToggleableFilters/ToggleableFilters';
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters';
import ToggleableSearchField from '../ToggleableFilters/ToggleableSearchField';
import ToggleableQuickFiltersContainer from '../ToggleableFilters/ToggleableQuickFiltersContainer';
import { StateTextButton } from '../ui/StateTextButton';
import ToggleableBottomFilters from '../ToggleableFilters/ToggleableBottomFilters';

const QUICK_FILTER_ACCOUNTED = 'accounted';
const QUICK_FILTER_TO_ACCOUNT = 'to_account';

interface Props {
  filters: Record<string, string>;
  setFilter: (key: string, value: string) => void;
}

function AccountStatementsPageFilters({ filters, setFilter }: Props) {
  const filtersCount = Object.keys(filters).length;

  const bottomFilters = (
    <>
      <ToggleableInputWrapper label={trans('global.filter-period')}>
        <div className='filters__period-container'>
          <DatePickerField
            placeholder={trans('global.datepicker-placeholder')}
            onChange={(value) => setFilter('bookedAtFrom', value)}
            isClearable={true}
            value={filters['bookedAtFrom']}
          />
          <span className='filters__separator'>-</span>
          <DatePickerField
            placeholder={trans('global.datepicker-placeholder')}
            onChange={(value) => setFilter('bookedAtTo', value)}
            isClearable={true}
            minDate={filters['bookedAtFrom']}
            value={filters['bookedAtTo']}
          />
        </div>
      </ToggleableInputWrapper>
    </>
  );

  return (
    <form>
      <ToggleableFilters filtersCount={filtersCount}>
        {({ isOpen }) => (
          <>
            <ToggleableQuickFilters>
              <ToggleableSearchField
                value={filters['description']}
                onChange={(value) => {
                  setFilter('description', value);
                }}
              />

              <ToggleableQuickFiltersContainer>
                <StateTextButton
                  onClick={(activate) => {
                    setFilter('quick_filters', activate ? QUICK_FILTER_ACCOUNTED : null);
                  }}
                  active={filters['quick_filters'] === QUICK_FILTER_ACCOUNTED}
                >
                  {trans('my-card.account_quick_filter_to_account')}
                </StateTextButton>

                <StateTextButton
                  onClick={(activate) => {
                    setFilter('quick_filters', activate ? QUICK_FILTER_TO_ACCOUNT : null);
                  }}
                  active={filters['quick_filters'] === QUICK_FILTER_TO_ACCOUNT}
                >
                  {trans('my-card.account_quick_filter_accounted')}
                </StateTextButton>
              </ToggleableQuickFiltersContainer>
            </ToggleableQuickFilters>

            <ToggleableBottomFilters isOpen={isOpen}>{bottomFilters}</ToggleableBottomFilters>
          </>
        )}
      </ToggleableFilters>
    </form>
  );
}

export default AccountStatementsPageFilters;
