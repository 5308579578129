import React, { FunctionComponent, useMemo, useState } from 'react';
import { HotelOfferEntryRaw } from '../../../types/hotel-offers';
import { AdvancedMarker, Map } from '@vis.gl/react-google-maps';
import { OfferMapMarker } from '../OfferMapMarker/OfferMapMarker';
import Button from '../../ui/ButtonComponent';

import './OffersMap.scss';
import { calculateGoogleMapsZoomLevel } from './calculate-google-maps-zoom-level';
import trans from '../../../trans';
import classNames from 'classnames';
import { SvgMapMarker } from '../OfferMapMarker/SvgMapMarker';

interface OffersMapProps {
  coordinates: {
    lat: number;
    lng: number;
  };
  radius: number;
  offers: HotelOfferEntryRaw[];
  onClose: () => void;
  onSelect: (offer: HotelOfferEntryRaw) => void;
}

export const OffersMap: FunctionComponent<OffersMapProps> = ({
  coordinates,
  onSelect,
  radius,
  offers,
  onClose,
}: OffersMapProps) => {
  const [hover, setHover] = useState(false);
  const memoizedMarkers = useMemo(
    () =>
      offers.map((offer) => (
        <OfferMapMarker
          key={offer.uuid}
          offer={offer}
          onHover={setHover}
          onClick={() => onSelect(offer)}
        />
      )),
    [offers, onSelect, setHover],
  );

  return (
    <>
      <Map
        mapId={'d47d7c6440b3d271'}
        defaultZoom={14}
        defaultCenter={coordinates}
        fullscreenControl={false}
        streetViewControl={false}
        mapTypeControl={true}
        mapTypeControlOptions={{
          style: google.maps.MapTypeControlStyle.DEFAULT,
          mapTypeIds: ['roadmap', 'satellite'],
        }}
        scrollwheel={!hover}
      >
        {memoizedMarkers}

        <AdvancedMarker position={coordinates} zIndex={2}>
          <SvgMapMarker size={42} color={'#e04b42'} />
        </AdvancedMarker>

        <div className='offer-map__close'>
          <Button secondary outline onClick={onClose}>
            {trans('accommodation.close-map')}
          </Button>
        </div>
      </Map>
    </>
  );
};
