import { useSelector } from 'react-redux';
import { getUserAssistantOrCurrentUserLink } from '../../../store/app/user-profile';
import useMyCardDataSource from '../../../hooks/useMyCardDataSource';
import { ITransactionSuggestion } from '../../../types/transaction-suggestion';

export default function useTransactions() {
  const link = useSelector(getUserAssistantOrCurrentUserLink('transactions'));

  return useMyCardDataSource<ITransactionSuggestion>(link.href);
}
