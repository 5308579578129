import React from 'react'
import queryString from 'query-string'
import { SlackIntegration } from './SlackIntegration'
import AlertsController from '../../services/alerts-controller'

export function IntegrationsPage() {
  const query = queryString.parse(window.location.search)

  if (query.alerts) {
    const alerts = JSON.parse(atob(query.alerts))

    AlertsController.displayAlerts(alerts)
  }

  return (
    <div>
      <SlackIntegration />
    </div>
  )
}
