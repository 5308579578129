import React, { Component } from 'react'
import trans from '../../trans'
import { DatePickerField, FormField, SelectField } from '../ui/Form/index'
import withReports, { changeFilters } from '../../store/app/reports'
import { AsyncSelectField } from '../ui/Form/AsyncSelectField'
import { loadOptionsForReportsUsers } from '../../store/app/users-for-select'
import { fetchMpkForAsyncSelect } from '../../store/app/mpks'
import ToggleableInputWrapper from '../ToggleableFilters/ToggleableInputWrapper'
import UserField from '../../form/UserField/UserField'
import ToggleableFilters from '../ToggleableFilters/ToggleableFilters'
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters'
import ToggleableQuickFiltersContainer from '../ToggleableFilters/ToggleableQuickFiltersContainer'
import { StateTextButton } from '../ui/StateTextButton'
import ToggleableBottomFilters from '../ToggleableFilters/ToggleableBottomFilters'
import { getStatusesForSelect } from '../../constants/request'
import { AccountDimensionManager } from '../../containers/AccountDimensionManager'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { AccountDimensionField } from '../ui/Form/AccountDimensionField'
import { AccountDimension, AccountDimensionFormItem } from '../../types/account-dimension'

enum QuickFilters {
  ACTUAL_YEAR = 'actual_year',
  POSTED = 'posted',
}

class ReportsFilters extends Component<any, any> {
  state = {
    MPKs: [],
    companies: [],
    isLoading: true,
  }

  optionAll = {
    label: trans('global.all'),
    value: null,
    onSelectResetsInput: true,
  }

  constructor(props) {
    super(props)

    this.fetchMpkForAsyncSelect = fetchMpkForAsyncSelect()
  }

  getCompaniesOptions() {
    const { companies } = this.props

    return [this.optionAll, ...companies]
  }

  componentDidMount() {
    const { fetchMpk, fetchCompanies } = this.props

    Promise.all([fetchMpk(), fetchCompanies()]).then((results) => {
      this.setState({ isLoading: false })
    })

    this.props.changeFilters('users', null)
  }

  componentWillUnmount() {
    this.props.changeFilters('users', null)
  }

  getBottomFilters(accountDimensions: AccountDimensionFormItem[]): JSX.Element {
    const { changeFilters, filters } = this.props

    return (
      <>
        <ToggleableInputWrapper label={trans('global.filter-period')}>
          <div className='filters__period-container'>
            <DatePickerField
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => changeFilters('from', value)}
              isClearable={true}
              value={filters['from']}
            />
            <span className='filters__separator'>-</span>
            <DatePickerField
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => changeFilters('to', value)}
              isClearable={true}
              minDate={filters['from']}
              value={filters['to']}
            />
          </div>
        </ToggleableInputWrapper>

        <ToggleableInputWrapper label={trans('global.company')}>
          <SelectField
            options={this.getCompaniesOptions()}
            value={filters['company_id']}
            onChange={(value) => changeFilters('company_id', value)}
            placeholder={trans('global.filter-placeholder-all')}
            clearable
          />
        </ToggleableInputWrapper>

        <ToggleableInputWrapper label={trans('global.filter-mpk')}>
          <AsyncSelectField
            loadOptions={this.fetchMpkForAsyncSelect}
            value={filters['mpks']}
            onChange={(value) => changeFilters('mpks', value)}
            placeholder={trans('global.filter-placeholder-all')}
            noOptionsMessage={() => {
              return trans('user.no-results-for-search-message')
            }}
            loadingMessage={() => {
              return trans('user.searching-message')
            }}
            delay={500}
            returnFullItemValueOnChange
            clearable
            allowCreateNewOption
          />
        </ToggleableInputWrapper>

        <ToggleableInputWrapper label={trans('global.employee')}>
          <UserField
            value={filters['users']}
            onChange={(value) => changeFilters('users', value)}
            loadItems={loadOptionsForReportsUsers}
            clearable
          />
        </ToggleableInputWrapper>

        <ToggleableInputWrapper label={trans('global.filter-status')}>
          <SelectField
            options={getStatusesForSelect()}
            value={filters['statuses']}
            onChange={(value) => changeFilters('statuses', value)}
            placeholder={trans('global.filter-placeholder-all')}
            isMulti
            clearable={true}
          />
        </ToggleableInputWrapper>

        {accountDimensions.map((dimension) => (
          <ToggleableInputWrapper label={dimension.label}>
            <AccountDimensionField
              className={'full-width'}
              accountDimension={dimension}
              value={filters[dimension.fieldName]}
              onChange={(value) => {
                changeFilters(dimension.fieldName, value)
              }}
              asField
            />
          </ToggleableInputWrapper>
        ))}
      </>
    )
  }

  render() {
    const { changeFilters, filters } = this.props
    const filtersCount = Object.keys(filters).filter((key) => filters[key]).length

    return (
      <form>
        <AccountDimensionManager fetchMethod={'reports'}>
          {({ accountDimensions, isLoaded }) => {
            if (!isLoaded) {
              return <Loader />
            }

            return (
              <ToggleableFilters filtersCount={filtersCount}>
                {({ isOpen }) => (
                  <>
                    <ToggleableQuickFilters>
                      <ToggleableQuickFiltersContainer>
                        <StateTextButton
                          onClick={(activate) => {
                            changeFilters(
                              'quick_filters',
                              activate ? QuickFilters.ACTUAL_YEAR : null,
                            )
                          }}
                          active={filters['quick_filters'] === QuickFilters.ACTUAL_YEAR}
                        >
                          {trans('reports.quick-filter-actual-year')}
                        </StateTextButton>

                        <StateTextButton
                          onClick={(activate) => {
                            changeFilters('quick_filters', activate ? QuickFilters.POSTED : null)
                          }}
                          active={filters['quick_filters'] === QuickFilters.POSTED}
                        >
                          {trans('reports.quick-filter-posted')}
                        </StateTextButton>
                      </ToggleableQuickFiltersContainer>
                    </ToggleableQuickFilters>

                    <ToggleableBottomFilters isOpen={isOpen}>
                      {this.getBottomFilters(accountDimensions)}
                    </ToggleableBottomFilters>
                  </>
                )}
              </ToggleableFilters>
            )
          }}
        </AccountDimensionManager>
      </form>
    )
  }
}

ReportsFilters = withReports()(ReportsFilters)

export default ReportsFilters
export { ReportsFilters }
