import React, { Component, Fragment } from 'react';

import { Map } from 'immutable';
import { get, has, values } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';

import {
  BOOKING_CANCELLATION_FAILED,
  BOOKING_EXPIRED,
  BOOKING_FAILED,
  BOOKING_PROCESS_TOO_LONG,
  BOOKING_SUCCESS,
  BOOKING_WITHOUT_TICKET,
} from '../../../store/app/trains-booking';
import { trans } from '../../../trans';
import { getFormErrors, getFormValues } from '../../../utils/forms';
import { Ability } from '../../RequestPageCommon/Ability/Ability';
import { Tooltip } from '../../Tooltip';
import Button from '../../ui/ButtonComponent';
import Icon from '../../ui/IconComponent';
import { Loader } from '../../ui/LoadingOverlay/Loader';
import NoticeLoader from '../../ui/LoadingOverlay/NoticeLoader';
import Availability from '../Availability';
import CommissionMessage from '../CommissionMessage';
import OfferDate from '../OfferDate';
import OfferTitle from '../OfferTitle';
import OperatorLogo from '../OperatorLogo';

import { Agreement } from './Agreement';
import CarriageTypeField from './CarriageTypeField';
import SeatCoachNumberField from './SeatCoachNumberField';
import SeatLocationField from './SeatLocationField';
import SeatPlaceNumberField from './SeatPlaceNumberField';
import { SeatTypeField } from './SeatTypeField';
import Status from './Status';

const bookOffer = async (values, dispatch, props) => {
  const {
    trainsBooking: { actions, selectors },
  } = props;

  if (values instanceof Map) {
    values = values.toJS();
  }

  const isNumber = (value) => !isNaN(Number(value));

  if (values.seat_type === 'NEXTTO' || values.seat_type === 'SPECIFIED') {
    if (!values.seat_coach_number || !isNumber(values.seat_coach_number)) {
      throw new SubmissionError({
        seat_coach_number: trans('trains-booking.please-provide-seat_coach_number'),
      });
    }

    if (!values.seat_place_number || !isNumber(values.seat_place_number)) {
      throw new SubmissionError({
        seat_place_number: trans('trains-booking.please-provide-seat_place_number'),
      });
    }
  }

  if (values.seat_type !== 'ANY') {
    values.seat_location = null;
    values.carriage_type = null;
  }

  actions.bookOffer(selectors.uuid, { attributes: values });
};

const hasAvailableOption = (option, key) => {
  const value = get(option, 'availableOptions.' + key, []);

  return value.length > 0 || (_.isBoolean(value) && value);
};

class SelectedOffer extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const status = this.getStatus(this.props);
    const subscribe =
      status !== null && !this.isReservationSuccess(status) && !this.isReservationFailed(status);

    this.listenReservationStatus(subscribe);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevStatus = this.getStatus(prevProps);
    const curStatus = this.getStatus(this.props);
    const prevSubscribe =
      prevStatus !== null &&
      !this.isReservationSuccess(prevStatus) &&
      !this.isReservationFailed(prevStatus);
    const subscribe =
      curStatus !== null &&
      !this.isReservationSuccess(curStatus) &&
      !this.isReservationFailed(curStatus);

    if (prevSubscribe !== subscribe) {
      this.listenReservationStatus(subscribe);
    }
  }

  componentWillUnmount() {
    const {
      trainsBooking: {
        actions: { unsubscribeReservationStatus },
      },
    } = this.props;

    unsubscribeReservationStatus();
  }

  listenReservationStatus(subscribe) {
    const {
      trainsBooking: {
        actions: { subscribeReservationStatus, unsubscribeReservationStatus },
      },
    } = this.props;

    if (subscribe) {
      subscribeReservationStatus();
    } else {
      unsubscribeReservationStatus();
    }
  }

  searchAnotherOne = (e) => {
    e.preventDefault();
    const {
      trainsBooking: {
        actions: { searchAnotherOne },
        selectors: { uuid },
      },
    } = this.props;
    searchAnotherOne({ search_uuid: uuid });
  };

  getStatus = (props) => {
    const {
      trainsBooking: {
        selectors: { selectedOffer },
      },
    } = props;
    return get(selectedOffer, 'option.booking', null);
  };

  isReservationSuccess = (status) => {
    return status === BOOKING_WITHOUT_TICKET || status === BOOKING_SUCCESS;
  };

  isReservationFailed = (status) => {
    return (
      status === BOOKING_FAILED ||
      status === BOOKING_EXPIRED ||
      status === BOOKING_CANCELLATION_FAILED
    );
  };

  renderReservationNotice = () => {
    {
      return (
        <div className='train-trip__selected-ticket-place-reservation-notice'>
          <NoticeLoader
            loaderText={trans('trains-booking.reservation-waiting-loader')}
            description={trans('trains-booking.reservation-waiting-notice')}
          />
        </div>
      );
    }
  };

  renderBookingButton = () => {
    const {
      trainsBooking: {
        selectors: { hasAgreement },
      },
    } = this.props;

    const button = (
      <Button disabled={!hasAgreement} primary className='train-trip__selected-save-button'>
        {trans('trains-booking.book')}
      </Button>
    );

    if (!hasAgreement) {
      return (
        <Tooltip html={<span>{trans('global.booking-agreement-tooltip')}</span>}>{button}</Tooltip>
      );
    }

    return button;
  };

  renderActionButtons = () => {
    const {
      trainsBooking: {
        selectors: { selectedOffer: offer, waitingForAttributes, hasAgreement },
      },
    } = this.props;
    const status = this.getStatus(this.props);

    if (
      null !== status &&
      (this.isReservationSuccess(status) ||
        (!this.isReservationSuccess(status) && !this.isReservationFailed(status)))
    ) {
      return null;
    }

    if (status === BOOKING_SUCCESS) {
      return trans('hotels-booking.cancel-message');
    }

    if (status === BOOKING_PROCESS_TOO_LONG) {
      return (
        <div className='train-trip__reservation-message train-trip__reservation-message--long'>
          <Icon type='warning' className='is-gradient-warning' />
          <span>{trans('trains-booking.booking-process-too-long')}</span>
        </div>
      );
    }

    return (
      <Fragment>
        {waitingForAttributes && <Loader />}
        {get(offer, 'reservationMessage', null) && (
          <div className='train-trip__reservation-message train-trip__reservation-message--long'>
            <Icon type='warning' className='is-gradient-warning' />
            <span>{get(offer, 'reservationMessage', '')}</span>
          </div>
        )}

        <Button
          outline
          className='train-trip__selected-change-button'
          onClick={this.searchAnotherOne}
        >
          {trans('trains-booking.search-another-one')}
        </Button>

        {!this.isReservationFailed(status) && this.renderBookingButton()}
      </Fragment>
    );
  };

  areSeatPreferencesVisible = () => {
    const {
      trainsBooking: {
        selectors: { selectedOffer: offer },
        request: { abilities },
      },
    } = this.props;
    const { data } = this.props;

    const option = offer.option;
    const readOnly = abilities.view && !abilities.edit && !abilities.bookOffers;
    const preferencesDisabled = option.booking !== null || readOnly;

    const seat_type = get(data, 'seat_type', null);

    if (seat_type === 'ANY' || seat_type === null || !hasAvailableOption(option, 'seat_type')) {
      return false;
    }

    if (!preferencesDisabled) {
      return true;
    }

    const seat_place_number = get(data, 'seat_place_number', null);
    const seat_coach_number = get(data, 'seat_coach_number', null);

    return seat_type && (seat_place_number || seat_coach_number);
  };

  renderAgreement = () => {
    if (this.getStatus(this.props) === null) {
      return <Agreement trainsBooking={this.props.trainsBooking} />;
    }

    return null;
  };

  onSubmit(e) {
    if (this.state.submitting) {
      e.preventDefault();
      return false;
    }

    this.setState({ submitting: true });
    this.props.handleSubmit(e);
  }

  render() {
    const {
      trainsBooking: {
        selectors: { selectedOffer: offer, totalPaxes },
        request: { abilities },
      },
    } = this.props;
    const { errors, data } = this.props;

    const option = offer.option;

    const arrival = moment(get(offer, 'attributes.arrivalDate', null));
    const departure = moment(get(offer, 'attributes.departureDate', null));
    const operator = get(offer, 'attributes.equipmentCode', '');
    const trainNumber = get(offer, 'attributes.trainNumber', '');
    const travelTime = get(offer, 'attributes.travelTime', '');
    const amount = get(offer, 'option.amount.formatted', '0,00 zł').toLowerCase();
    const travelClass =
      get(option, 'attributes.service_class', null) === '1'
        ? trans('trains-booking.travel-class-item-first')
        : trans('trains-booking.travel-class-item-second');

    const status = this.getStatus(this.props);
    // need to check for accept ability, as manager during acceptation should have preferences disabled
    const readOnly = abilities.view && !abilities.edit && !abilities.bookOffers;
    const preferencesDisabled = option.booking !== null || readOnly;

    return (
      <form onSubmit={this.onSubmit}>
        <div className='train-trip__selected-ticket train-trip__selected-ticket--reservation'>
          <div className='train-trip__selected-ticket-title-wrapper'>
            <div className='train-trip__selected-ticket-title'>
              <span className='train-trip__selected-ticket-title-cities'>
                <OfferTitle offer={offer} />
              </span>
              <span>
                <OfferDate offer={offer} />
              </span>
            </div>
            <Status offer={offer} />
          </div>

          <div className='train-trip__selected-ticket-details-container'>
            <div className='train-trip__selected-ticket-intro'>
              <OperatorLogo offer={offer} />

              <div className='train-trip__selected-ticket-details'>
                <span className='train-trip__selected-ticket-departure-hours'>
                  {departure.format('HH:mm')} - {arrival.format('HH:mm')}
                </span>
                <div className='train-trip__dialog-ticket-provider'>
                  <span>{operator}&nbsp;</span>
                  <span>{trainNumber}</span>
                </div>
              </div>
            </div>

            <div className='train-trip__selected-ticket-travel-details'>
              <div className='train-trip__selected-ticket-travel-detail'>
                <div className='train-trip__selected-ticket-row'>
                  <span className='train-trip__selected-ticket-travel-detail-title'>
                    {trans('trains-booking.travel-time')}
                  </span>
                  <span className='train-trip__selected-ticket-travel-detail-value'>
                    {travelTime}
                  </span>
                </div>

                <div className='train-trip__selected-ticket-row'>
                  <span className='train-trip__selected-ticket-travel-detail-title'>
                    {trans('trains-booking.travel-class')}
                  </span>
                  <span className='train-trip__selected-ticket-travel-detail-value'>
                    {travelClass}
                  </span>
                </div>
              </div>

              <Availability offer={offer} />

              {hasAvailableOption(option, 'seat_type') && (
                <div className='train-trip__selected-ticket-place-detail'>
                  {hasAvailableOption(option, 'seat_location') && data.seat_type === 'ANY' && (
                    <div className='train-trip__selected-ticket-row'>
                      <Field
                        name='seat_location'
                        component={SeatLocationField}
                        offer={offer}
                        disabled={preferencesDisabled}
                      />
                    </div>
                  )}

                  <div className='train-trip__selected-ticket-row'>
                    <Field
                      name='seat_type'
                      component={SeatTypeField}
                      offer={offer}
                      disabled={preferencesDisabled}
                    />
                  </div>
                </div>
              )}

              {((hasAvailableOption(option, 'carriage_type') && data.seat_type === 'ANY') ||
                hasAvailableOption(option, 'seat_type')) && (
                <div className='train-trip__selected-ticket-place-detail'>
                  {hasAvailableOption(option, 'seat_type') && this.areSeatPreferencesVisible() && (
                    <div className='train-trip__selected-ticket-row'>
                      {hasAvailableOption(option, 'seat_coach_number') && (
                        <Fragment>
                          <Field
                            name='seat_coach_number'
                            component={SeatCoachNumberField}
                            offer={offer}
                            disabled={preferencesDisabled}
                          />

                          {hasAvailableOption(option, 'seat_place_number') && (
                            <Field
                              name='seat_place_number'
                              component={SeatPlaceNumberField}
                              offer={offer}
                              disabled={preferencesDisabled}
                            />
                          )}
                        </Fragment>
                      )}

                      {(has(errors, 'seat_place_number') || has(errors, 'seat_coach_number')) && (
                        <div className='train-trip__seat-validation'>
                          {values(errors).map((error) => (
                            <span className='form-group__error'>{error}</span>
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  {hasAvailableOption(option, 'carriage_type') && data.seat_type === 'ANY' && (
                    <div className='train-trip__selected-ticket-row'>
                      <Field
                        name='carriage_type'
                        component={CarriageTypeField}
                        offer={offer}
                        disabled={preferencesDisabled}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className='train-trip__selected-ticket-save-details'>
            <span className='train-trip__selected-ticket-price plane-offer__price-wrapper'>
              {offer.option.rules.length > 0 && (
                <div className='plane-trip__offer-detail-price-warning'>
                  <Icon
                    type='warning'
                    className='summary-warnings__icon is-color-warning is-gradient-warning'
                  />
                </div>
              )}
              <span className='plane-offer__price'>{amount}</span>
            </span>

            <Ability ability={['edit', 'bookOffers']} comparator='or'>
              <div className='train-trip__selected-ticket-messages'>
                <div className='train-trip__selected-ticket-agreement-message'>
                  {!readOnly && this.renderAgreement()}
                </div>
                <div>
                  <div className='train-trip__selected-buttons'>
                    {totalPaxes > 1 && (
                      <span className='train-trip__selected-ticket-message'>
                        {trans('trains-booking.reservation-short-info', { paxes: totalPaxes })}
                      </span>
                    )}

                    <CommissionMessage offer={offer} />
                    <div className='train-trip__selected-ticket-buttons'>
                      {!readOnly && this.renderActionButtons()}
                    </div>
                  </div>
                </div>
                {!readOnly &&
                  status !== null &&
                  !this.isReservationSuccess(status) &&
                  !this.isReservationFailed(status) &&
                  this.renderReservationNotice()}
              </div>
            </Ability>
          </div>
        </div>
      </form>
    );
  }
}

SelectedOffer.propTypes = {};

const initValue = (option, key, initValue) => {
  if (hasAvailableOption(option, key)) {
    return get(option, 'requestedAttributes.' + key, initValue);
  } else {
    return initValue;
  }
};

const mapStateToProps = (state, props) => {
  const {
    trainsBooking: {
      selectors: { selectedOffer: offer },
    },
  } = props;
  const option = offer.option;
  return {
    initialValues: {
      seat_location: initValue(option, 'seat_location', 'WINDOW'),
      seat_type: initValue(option, 'seat_type', 'ANY'),
      seat_place_number: initValue(option, 'seat_place_number', null),
      seat_coach_number: initValue(option, 'seat_coach_number', null),
      carriage_type: initValue(option, 'carriage_type', null),
    },
    errors: getFormErrors(props.form, state),
    data: getFormValues(props.form, state),
  };
};

const withForm = reduxForm({
  onSubmit: bookOffer,
});

const withConnect = connect(mapStateToProps);

SelectedOffer = compose(withConnect, withForm)(SelectedOffer);

export default SelectedOffer;
export { SelectedOffer };
