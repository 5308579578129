import React, { Fragment } from 'react';
import { Field } from 'redux-form/immutable';
import { FormField } from '../ui/Form';
import { trans } from '../../trans';
import { Col, Row } from '../../ui/Grid';
import Icon from '../ui/IconComponent';
import { RequestTravelerManager } from '../../containers/RequestTravelerManager/RequestTravelerManager';
import { RequestTravelerSelect } from '../RequestTravelerForm/RequestTravelerSelect';
import { RequestTravelerList } from '../RequestTravelerForm/RequestTravelerList';
import { fetchMpkForAsyncSelect } from '../../store/app/mpks';
import { submitAccountDimension } from '../../store/app/account-dimensions/form';
import APIClient from '../../services/APIClient';

class BasicInfoForm extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isMPKEditing: false,
      isProjectEditing: false,
    };
  }

  _onMPKEdit() {
    this.setState({ isMPKEditing: true });
  }

  _onProjectEdit() {
    this.setState({ isProjectEditing: true });
  }

  renderAllocationLabel() {
    const { data } = this.props;

    if (data.mpk) {
      return data.mpk.name;
    }

    return '-';
  }

  renderMPKLabel() {
    const { request } = this.props;
    const requestMpk = request.mpk;

    if (typeof requestMpk === 'object') {
      return requestMpk.name;
    } else {
      return null;
    }
  }

  canRenderProject() {
    const { projects } = this.props;

    return projects.length > 0;
  }

  canEditAllowances() {
    const { disabled, currentUser } = this.props;

    return !disabled && currentUser.abilities.assignAllowancesToRequest;
  }

  canEditProject() {
    const { disabled, projects } = this.props;

    return !disabled && projects.length > 0;
  }

  canEditPurpose() {
    return this.props.request.abilities.canEditPurpose;
  }

  canEditAccountDimensions(accountDimension) {
    const { disabled, accountDimensions } = this.props;

    if (null !== accountDimension.disabled) {
      return !accountDimension.disabled;
    }

    return !disabled && accountDimensions.length > 0;
  }

  getCurrentProject() {
    const { projects, data } = this.props;

    return projects.find((item) => item.value === data.project_id);
  }

  renderProjectLabel() {
    const project = this.getCurrentProject();
    const canEditProject = this.canEditProject();

    const label = project ? project.label : '-';

    return (
      <Fragment>
        <div className='react-select-container__placeholder'>{label}</div>
        {canEditProject && (
          <Icon
            type='edit'
            lg
            className='react-select__edit-icon'
            onClick={this._onProjectEdit.bind(this)}
          />
        )}
      </Fragment>
    );
  }

  renderAccountDimensions() {
    const { accountDimensions } = this.props;

    return (
      <Fragment>
        {accountDimensions.map((item) => (
          <Col xs={4}>
            <div>{item.label}</div>
          </Col>
        ))}
      </Fragment>
    );
  }

  onProjectChange = (e, value) => {
    if (!value) {
      this.setState({ isProjectEditing: false });
    }
  };

  onAccountDimensionChange = (name, value, confirm) => {
    const {
      setCache,
      accountDimensionItems,
      accountDimensions,
      request: { slug },
    } = this.props;

    return submitAccountDimension({
      name,
      value,
      setCache,
      dimensions: accountDimensions,
      items: accountDimensionItems,
      updateMethod: (dim_id) =>
        APIClient.updateRequestAccountDimension(
          slug,
          dim_id,
          value.id,
          confirm ? { 'Accept-confirmation': 'accepted' } : {},
        ),
      deleteMethod: (dim_id) =>
        APIClient.deleteRequestAccountDimension(
          slug,
          dim_id,
          confirm ? { 'Accept-confirmation': 'accepted' } : {},
        ),
    });
  };

  render() {
    const { request, accountDimensions, projects, data, hasMpkFeatureEnabled } = this.props;

    return (
      <form data-test='basic-info-form'>
        <Row>
          {hasMpkFeatureEnabled && (
            <Col sm={4} className='col--select'>
              <label className='form-group form-group--label-top'>
                <span className='form-group__label'>{trans('trip-request.mpk-label')}</span>
                <div className='form-group__input-wrapper react-select-container'>
                  <div className='react-select-container__placeholder'>{this.renderMPKLabel()}</div>
                </div>
              </label>
            </Col>
          )}

          {hasMpkFeatureEnabled && (
            <Col sm={4} className='col--select'>
              {!this.state.isMPKEditing ? (
                <label className='form-group form-group--label-top'>
                  <span className='form-group__label'>
                    {trans('trip-request.allocation-label')}
                  </span>
                  <div className='form-group__input-wrapper react-select-container'>
                    <div className='react-select-container__placeholder'>
                      {this.renderAllocationLabel()}
                    </div>
                    {this.canEditAllowances() && (
                      <Icon
                        type='edit'
                        lg
                        className='react-select__edit-icon'
                        onClick={this._onMPKEdit.bind(this)}
                      />
                    )}
                  </div>
                </label>
              ) : (
                <Field
                  name='mpk'
                  type='async-select'
                  label={trans('trip-request.allocation-label')}
                  component={FormField}
                  loadOptions={fetchMpkForAsyncSelect()}
                  labeltop
                  selectedOption={data.mpk}
                  placeholder={trans('instance-users.none')}
                  noOptionsMessage={() => {
                    return trans('user.no-results-for-search-message');
                  }}
                  loadingMessage={() => {
                    return trans('user.searching-message');
                  }}
                  isSearchable={true}
                  returnFullItemValueOnChange
                />
              )}
            </Col>
          )}

          {this.canRenderProject() && (
            <Col sm={4} className='col--select'>
              {!this.state.isProjectEditing ? (
                <label className='form-group form-group--label-top'>
                  <span className='form-group__label'>{trans('trip-request.project-label')}</span>
                  <div className='form-group__input-wrapper react-select-container'>
                    {this.renderProjectLabel()}
                  </div>
                </label>
              ) : (
                <Field
                  name='project_id'
                  type='select'
                  component={FormField}
                  options={projects}
                  placeholder={trans('trip-request.select-project')}
                  label={trans('trip-request.project-label')}
                  onChange={this.onProjectChange}
                  labeltop
                  clearable={true}
                />
              )}
            </Col>
          )}

          {accountDimensions.map((dimension) => (
            <Col xs={4} className='col--select'>
              <Field
                name={dimension.fieldName}
                type='account-dimensions'
                component={FormField}
                disabled={!this.canEditAccountDimensions(dimension)}
                accountDimension={dimension}
                selectedDimensions={request.accountDimensionItems}
                placeholder={trans('account-dimensions.placeholder')}
                onAccountDimensionChange={(fieldName, value, confirm) => {
                  return this.onAccountDimensionChange(fieldName, value, confirm);
                }}
                label={dimension.label}
                labeltop
                key={dimension.id}
              />
            </Col>
          ))}
        </Row>

        <RequestTravelerManager request={request}>
          {({
            disabled,
            travelers,
            companyId,
            addRequestTraveler,
            removeRequestTraveler,
            setPrivateTrip,
            isPrivate,
            delegationTripSwitch,
            setNonDelegation,
            featurePrivateTripRequestsEnabled,
          }) => {
            return (
              <div>
                <Row>
                  {!disabled && (
                    <Col sm={4}>
                      <RequestTravelerSelect
                        onSelect={addRequestTraveler}
                        onSetPrivateTrip={setPrivateTrip}
                        setNonDelegation={setNonDelegation}
                        isPrivate={isPrivate}
                        featurePrivateTripRequestsEnabled={featurePrivateTripRequestsEnabled}
                        delegationTripSwitch={delegationTripSwitch}
                        showDelegationOptions={!request.delegation_was_switched}
                        companyId={companyId}
                        delegation={request.delegation}
                      />
                    </Col>
                  )}

                  {travelers.length > 1 && (
                    <Col sm={4}>
                      <RequestTravelerList
                        travelers={travelers}
                        onRemove={removeRequestTraveler}
                        disabled={disabled}
                      />
                    </Col>
                  )}

                  {request.delegation_was_switched && (
                    <Col sm={4}>
                      <div className='form-group form-group--label-top'>
                        <span className='form-group__label'>{trans('trip-request.trip-type')}</span>

                        <div className='form-group__input-wrapper react-select-container'>
                          <div className='react-select-container__placeholder react-select-container__placeholder--no-padding-left'>
                            {trans(
                              request.delegation
                                ? 'trip-request.trip-type-delegation'
                                : 'trip-request.trip-type-non-delegation',
                            )}
                          </div>

                          {!disabled && (
                            <Icon
                              key='delete'
                              type='trash'
                              className='react-select__edit-icon'
                              onClick={() => setNonDelegation(!request.delegation)}
                              lg
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
                <Row>
                  {isPrivate && (
                    <Col sm={4}>
                      <div className='form-group__input-wrapper react-select-container'>
                        <div className='react-select-container__placeholder react-select-container__placeholder--no-padding-left'>
                          {trans('trip-request.private-trip')}
                        </div>
                        {!disabled && (
                          <Icon
                            key='delete'
                            type='trash'
                            className='react-select__edit-icon'
                            onClick={() => setPrivateTrip(false)}
                            lg
                          />
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            );
          }}
        </RequestTravelerManager>

        <Row>
          <Col sm={12} className='col--textarea'>
            <Field
              name='purpose'
              type='textarea'
              component={FormField}
              label={trans('trip-request.trip-substantiation-label')}
              labeltop
              disabled={!this.canEditPurpose()}
            />

            {this.props.request.purpose_edited ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <span>{trans('request.purpose-edited')}</span>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

export { BasicInfoForm };
export default { BasicInfoForm };
