import React, { FunctionComponent } from 'react';

export const SvgMapMarker: FunctionComponent<{ color?: string; size?: number }> = ({
  color = '#4fd9de',
  size = 32,
}) => {
  return (
    <svg viewBox='-4 0 36 36' width={size} height={size}>
      <g id='Vivid.JS' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='Vivid-Icons' transform='translate(-125.000000, -643.000000)'>
          <g id='Icons' transform='translate(37.000000, 169.000000)'>
            <g id='map-marker' transform='translate(78.000000, 468.000000)'>
              <g transform='translate(10.000000, 6.000000)'>
                <path
                  d='M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z'
                  className='marker-shape'
                  fill={color}
                ></path>
                <circle
                  className='marker-circle'
                  fill='#fff'
                  fill-rule='nonzero'
                  cx='14'
                  cy='14'
                  r='7'
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
