import PropTypes from 'prop-types'
import React from 'react'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import BreadCrumbs from '../../containers/BreadCrumbs/index'
import { Link } from 'react-router-dom'
import { RouteManager } from '../../containers/RouteManager'
import pluralize from 'pluralize'
import { capitalize } from 'lodash'
import { RequestState, RequestType } from '../../types/request'

class RequestBreadcrumb extends React.Component<any, any> {
  getLastBreadcrumbLabel() {
    const { request } = this.props
    const { state, type } = request

    if (type === RequestType.INVOICE) {
      if (state === RequestState.STATE_SETTLEMENT) {
        return 'request.invoice_settlement'
      }

      return 'request.invoice'
    }

    return `request.${state}`
  }

  renderBreadcrumbs(match) {
    const { request } = this.props
    const { state, uid } = request
    const isSettlement =
      match.path === getRouteByName('main', `show${capitalize(request.type)}Settlement`, false)
    const isInvoice = request.type === RequestType.INVOICE

    return (
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>

        {isSettlement ? (
          <Link to={getRouteByName('main', 'settlementRequestsList')}>
            {trans('global.settlements')}
          </Link>
        ) : isInvoice ? (
          <Link to={getRouteByName('main', 'invoiceRequestsList')}>{trans('global.invoice')}</Link>
        ) : (
          <Link to={getRouteByName('main', `${request.type}RequestsList`)}>
            {trans('global.expenses')}
          </Link>
        )}
        {isSettlement && (
          <Link
            to={
              getRouteByName('main', 'settlementRequestsList') +
              '?filters=' +
              encodeURIComponent('types=' + request.type)
            }
          >
            {trans('global.' + pluralize.plural(request.type))}
          </Link>
        )}

        <Link to={window.location.href}>
          {trans(this.getLastBreadcrumbLabel())} {uid}
        </Link>
      </BreadCrumbs>
    )
  }

  render() {
    return <RouteManager>{({ match }) => this.renderBreadcrumbs(match)}</RouteManager>
  }
}

RequestBreadcrumb.propTypes = {
  request: PropTypes.object.isRequired,
}

export { RequestBreadcrumb }
export default { TripRequestBreadcrumb: RequestBreadcrumb }
