import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../ui/IconComponent';

class ToggleableFilters extends React.Component<any, any> {
  state = {
    isOpen: false,
  };

  render() {
    const { children, filtersCount, hideIcon } = this.props;
    const renderProps = {
      isOpen: this.state.isOpen,
    };

    const classes = classnames({
      filters__toggler: true,
      'filters__toggler--has-filters': filtersCount > 0,
    });

    return (
      <div className={'filters__main'}>
        {children(renderProps)}

        {!hideIcon && (
          <button className={classes} type='button' data-count={filtersCount} onClick={this.toggle}>
            <Icon type='filter' className='filters__toggler-icon' />
          </button>
        )}
      </div>
    );
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
}

ToggleableFilters.propTypes = {
  children: PropTypes.func.isRequired,
  filtersCount: PropTypes.number.isRequired,
};

export { ToggleableFilters };
export default ToggleableFilters;
