import PropTypes from 'prop-types'
import React from 'react'
import { AccountingDocumentsManager } from '../../containers/AccountingDocumentsManager'
import { Col, Row } from '../../ui/Grid'
import { DocumentCell, UploadCell } from '../DocumentCell'
import { RouteManager } from '../../containers/RouteManager'
import { MileageAllowanceCell } from '../MileageAllowanceCell'
import { TYPE_TRIP } from '../../constants/request'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import trans from '../../trans'
import AccountingDocumentsManageProvider from '../../containers/AccountingDocumentsManager/AccountingDocumentsManageProvider'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import { Request } from '../../types/request'
import { AmountFormatter } from '../AmountFormatter'

interface AccountingDocumentsProps {
  request: Request
  types?: ('accounting' | 'travel' | 'confirmation')[]
  showSummary?: boolean
  [key: string]: unknown
}

class AccountingDocuments extends React.Component<AccountingDocumentsProps, any> {
  renderMileageAllowanceCell() {
    const { push, getRouteByName, request, showMileageAllowance } = this.props

    if (request.type !== TYPE_TRIP) {
      return null
    }

    if (!showMileageAllowance) {
      return null
    }

    if (!request.abilities.showTripRequestLumpSum) {
      return null
    }

    return (
      <MileageAllowanceCell
        width='217px'
        push={push}
        getRouteByName={getRouteByName}
        request={request}
      />
    )
  }

  render() {
    const { fetchRequest, request, disableUpload, disableDelete, types, readOnly, showSummary } =
      this.props
    const headerTitle = types.includes('accounting')
      ? 'document.accounting-travel-list'
      : 'document.other-documents'

    return (
      <Section>
        <SectionHeader caption={trans(headerTitle)} />

        <RouteManager>
          {({ getRouteByName, push }) => (
            <>
              <AccountingDocumentsManageProvider request={request} types={types}>
                {({ documents }) => (
                  <AccountingDocumentsManager request={request} documents={documents}>
                    {({ instanceCurrency, uploadDocument, deleteDocument, currentUser }) => (
                      <Row>
                        {this.renderMileageAllowanceCell()}

                        {documents.map((document) => {
                          return (
                            <DocumentCell
                              width='217px'
                              document={document}
                              key={`document-cell-${document['id']}`}
                              push={push}
                              getRouteByName={getRouteByName}
                              instanceCurrency={instanceCurrency}
                              deleteDocument={
                                request.abilities.addAndDeleteDocuments ? deleteDocument : null
                              }
                              request={request}
                              currentUser={currentUser}
                              onDeleteDocument={() => fetchRequest(request['slug'])}
                              disableDelete={disableDelete}
                              readOnly={readOnly}
                            />
                          )
                        })}

                        {!disableUpload && (
                          <Ability ability={['settle']}>
                            <Col
                              width='210px'
                              style={{
                                display: 'flex',
                                paddingTop: '15px',
                                paddingBottom: '15px',
                              }}
                            >
                              <UploadCell uploadDocument={uploadDocument} request={request} />
                            </Col>
                          </Ability>
                        )}
                      </Row>
                    )}
                  </AccountingDocumentsManager>
                )}
              </AccountingDocumentsManageProvider>
            </>
          )}
        </RouteManager>

        {showSummary && (
          <Row>
            <Col>
              <p>
                {trans('document.sum_of_other_documents')}:{' '}
                <AmountFormatter amount={request.valueOfOtherDocuments.amount} />{' '}
                {request.valueOfOtherDocuments.currency}
              </p>
            </Col>
          </Row>
        )}
      </Section>
    )
  }
}

AccountingDocuments.propTypes = {
  fetchRequest: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  disableUpload: PropTypes.bool,
  showMileageAllowance: PropTypes.bool,
  types: PropTypes.array,
  readOnly: PropTypes.bool,
}

AccountingDocuments.defaultProps = {
  showMileageAllowance: true,
  readOnly: false,
  types: ['accounting'],
}

export { AccountingDocuments }
export default { AccountingDocuments }
