import React from 'react'
import Icon from '../../ui/IconComponent'
import isPromise from 'is-promise'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Map } from 'immutable'
import { AsyncSelectField } from './AsyncSelectField'
import { fetchAdditionalDimensionOptions } from '../../../store/app/account-dimensions/form'
import { ConfirmationModal } from '../../ConfirmationModal'
import trans from '../../../trans'
import { Transition } from 'react-transition-group'
import Paper from '@material-ui/core/Paper'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '../ButtonComponent'
import Dialog from '@material-ui/core/Dialog'

class AccountDimensionField extends React.PureComponent<any, any> {
  state = {
    editing: false,
    accept: '',
    decline: '',
    title: '',
    confirm: false,
    onChangeData: null,
    message: '',
  }

  constructor(props) {
    super(props)

    this.onSearch = fetchAdditionalDimensionOptions(
      props.accountDimension.slug,
      props.accountDimension.required,
    )
  }

  onChange = (fieldName, value, confirm) => {
    if (typeof this.props.onAccountDimensionChange === 'function') {
      const result = this.props.onAccountDimensionChange(fieldName, value, confirm)

      if (isPromise(result)) {
        result
          .then(() => {
            this.props.onChange(value)
            this.setState({ confirm: false, onChangeData: null })
          })
          .catch((error) => {
            if ('confirmation' in error) {
              const {
                buttons: { accept, decline },
                title,
              } = error.confirmation

              this.setState({
                accept,
                decline,
                title,
                confirm: true,
                onChangeData: {
                  value,
                  fieldName,
                },
              })
            }
          })
      }
    } else {
      this.props.onChange(value)
    }
  }

  onReject = () => {
    this.setState({ confirm: false, onChangeData: null })
  }

  onConfirm = () => {
    const { value } = this.state.onChangeData
    const { name } = this.props

    this.onChange(name, value, true)
  }

  onEditClick = () => {
    this.setState({ editing: !this.state.editing })
  }

  onChangeCallback = (item) => {
    this.onChange(this.props.name, item, false)
    this.setState({ editing: true, message: item ? item.message : null })
  }

  search = (search, prevOptions) => {
    return this.onSearch(search, prevOptions)
  }

  onMessageClose = () => {
    this.setState({ message: '' })
  }

  render() {
    const {
      accountDimension: { id, required } = {},
      selectedDimensions = [],
      className,
      dimensionItem,
      asField,
      disabled,
      error,
      name,
      value,
      placeholder,
    } = this.props

    const selectedDimension =
      dimensionItem || selectedDimensions.find((item) => item.account_dimension_id === id) || {}
    const option = selectedDimension.accountDimensionItem
    const label = option ? `${option.code} - ${option.name}` : '-'

    const classes = classNames({
      'account-dimension--required': required,
      [className]: !!className,
    })

    if (this.state.editing || asField || error) {
      return (
        <div className={classes}>
          <AsyncSelectField
            name={name}
            value={value instanceof Map ? value.toJS() : value}
            placeholder={placeholder}
            onChange={this.onChangeCallback}
            disabled={disabled}
            loadOptions={this.search}
            noOptionsMessage={() => {
              return trans('user.no-results-for-search-message')
            }}
            loadingMessage={() => {
              return trans('user.searching-message')
            }}
            delay={500}
            returnFullItemValueOnChange
            isClearable={true}
            menuPortalTarget={document.body}
          />

          {this.state.confirm && (
            <ConfirmationModal
              title={this.state.title}
              decline={this.state.decline}
              accept={this.state.accept}
              onConfirm={this.onConfirm}
              onReject={this.onReject}
            />
          )}

          {this.state.message && (
            <Dialog
              open={!!this.state.message}
              TransitionComponent={Transition}
              disableBackdropClick
              disableEscapeKeyDown
              onClose={this.onMessageClose}
              className='last-requests__dialog'
            >
              <Paper square>
                <DialogTitle id='alert-dialog-slide-title'>
                  {trans('account-dimensions.modal-message-title')}
                </DialogTitle>

                <DialogContent className='last-requests__dialog-content'>
                  {this.state.message}
                </DialogContent>

                <DialogActions className='accommodation-confirm__dialog-actions'>
                  <Button primary xxs onClick={this.onMessageClose}>
                    {trans('account-dimensions.modal-message-btn')}
                  </Button>
                </DialogActions>
              </Paper>
            </Dialog>
          )}
        </div>
      )
    }

    return (
      <div className={'react-select-container ' + classes}>
        <div className='react-select-container__placeholder'>{label || '-'}</div>

        {!disabled && (
          <Icon type='edit' lg className='react-select__edit-icon' onClick={this.onEditClick} />
        )}
      </div>
    )
  }
}

AccountDimensionField.propTypes = {
  onAccountDimensionChange: PropTypes.func,
}
export { AccountDimensionField }
export default AccountDimensionField
