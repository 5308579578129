import React, { PureComponent } from 'react'
import { trans } from '../../trans'
import { Tooltip } from '../Tooltip'
import Icon from '../ui/IconComponent'
import { MEAL_TYPES } from './index'

class Maintenance extends PureComponent<any, any> {
  renderMeals = (types) => {
    return types.map((meal) => <div key={meal}>{trans(MEAL_TYPES[meal])}</div>)
  }

  render() {
    const { mealTypes, tooltip = true } = this.props

    const types = mealTypes.filter((type) => type.toUpperCase() !== 'RO')

    if (!types.length) {
      return null
    }

    return (
      <Tooltip
        disabled={!tooltip}
        className='selected-hotel__offer-icon'
        stickyDuration={0}
        html={this.renderMeals(types)}
      >
        <Icon type='restaurant' />
      </Tooltip>
    )
  }
}

export { Maintenance }
export default Maintenance
