import moment from 'moment';

import { Request } from './Request';

class FlightsRequest extends Request {
  static CEST_OFFSET = 60;
  static minutesToHour(minutes) {
    if (minutes > 1439 - this.CEST_OFFSET) {
      minutes = 1439 - this.CEST_OFFSET;
    }

    return moment().hour(0).minute(0).add(minutes, 'm').format('HH:mm');
  }

  static stringBoolToNumber(bool) {
    return bool ? 1 : 0;
  }

  static arrayToString(array) {
    return Array.isArray(array) ? array.join(',') : array;
  }
}

export default FlightsRequest;
export { FlightsRequest };
