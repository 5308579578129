import { Element } from './Element'
import { TRAVEL_ACCOMODATION_SUGGESTION } from '../../constants/travel'

class AccommodationSuggestion extends Element {
  date: any;
  endDate: any;

  constructor(init) {
    super({
      ...init,
      type: TRAVEL_ACCOMODATION_SUGGESTION,
      virtual: false,
      draft: true,
    })

    this.date = init.date
    this.endDate = init.endDate
  }

  getAllAmounts() {
    return []
  }

  getStartLocation() {
    return null
  }

  getEndLocation() {
    return null
  }

  getStartDate() {
    return this.date
  }

  getEndDate() {
    return this.endDate ? this.endDate : this.date
  }
}

export { AccommodationSuggestion }
export default AccommodationSuggestion
