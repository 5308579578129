import { Element } from './Element'
import { Location } from './index'
import { TRAVEL_PLANE_TRIP } from '../../constants/travel'

class Plane extends Element {
  flight_from: Location;
  flight_to: Location;
  flight_class: string;
  arrival_at: any;
  return_at: any;
  round_trip: boolean;
  amount: any;
  amount_currency: any;
  calculated_amount: any;
  calculated_amount_currency: any;
  suggested_amount: string;
  direct_only: boolean;
  restrict_to_fba: boolean;
  searcher_disabled: boolean;
  request_travelers: any[];

  constructor(init) {
    super({ ...init, type: TRAVEL_PLANE_TRIP })

    const defaults = {
      flight_from: {},
      flight_to: {},
      flight_class: 'class_economy',
      arrival_at: null,
      return_at: null,
      round_trip: true,
      amount: null,
      amount_currency: null,
      calculated_amount: null,
      calculated_amount_currency: null,
      suggested_amount: '1435',
      direct_only: false,
      searcher_disabled: false,
      restrict_to_fba: false,
      request_travelers: [],
      offer_uuid: null,
      target_real_departure_at: null,
      target_real_arrival_at: null,
      return_real_departure_at: null,
      return_real_arrival_at: null,
    }

    const opts = Object.assign({}, defaults, init)

    let travelers = opts.request_travelers
    if (!travelers.length && opts.travelers && opts.travelers.length) {
      travelers = opts.travelers.map((item) => item.slug)
    }

    this.flight_from = new Location(opts.flight_from)
    this.flight_to = new Location(opts.flight_to, opts.flight_to.city)
    this.flight_class = opts.flight_class
    this.arrival_at = opts.arrival_at
    this.return_at = opts.return_at
    this.round_trip = opts.round_trip
    this.amount = opts.amount
    this.amount_currency = opts.amount_currency
    this.calculated_amount = opts.calculated_amount
    this.calculated_amount_currency = opts.calculated_amount_currency
    this.suggested_amount = opts.suggested_amount
    this.direct_only = opts.direct_only
    this.restrict_to_fba = opts.restrict_to_fba
    this.searcher_disabled = opts.searcher_disabled
    this.request_travelers = travelers
    this.offer_uuid = opts.offer_uuid
    this.target_real_departure_at = opts.target_real_departure_at
    this.target_real_arrival_at = opts.target_real_arrival_at
    this.return_real_departure_at = opts.return_real_departure_at
    this.return_real_arrival_at = opts.return_real_arrival_at
  }

  getStartLocation() {
    return this.flight_from
  }

  getEndLocation() {
    return this.flight_to
  }

  getStartDate() {
    if (this.virtual) {
      return this.return_real_departure_at
    }

    return this.target_real_departure_at || this.arrival_at
  }

  getEndDate() {
    if (this.virtual) {
      return this.return_real_arrival_at
    }

    return this.return_real_arrival_at || this.return_at
  }

  getAllAmounts() {
    return [
      {
        currency: this.amount_currency,
        value: this.amount,
      },
    ]
  }

  isRoundTrip() {
    return this.round_trip
  }
}

export { Plane }
export default Plane
