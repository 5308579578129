import React, { FunctionComponent } from 'react';
import { StaticMap as GoogleStaticMap, createStaticMapsUrl } from '@vis.gl/react-google-maps';

import './SwitchToMap.scss';
import { config } from '../../../config';
import { findAllByDisplayValue } from '@testing-library/react';
import Button from '../../ui/ButtonComponent';
import Icon from '../../ui/IconComponent';
import trans from '../../../trans';

interface Props {
  coordinates: { lat: number; lng: number };
  width: number;
  height: number;
  onClick: () => void;
}

export const SwitchToMap: FunctionComponent<Props> = ({ coordinates, width, height, onClick }) => {
  const staticMapsUrl = createStaticMapsUrl({
    apiKey: config.googleMapsKey,
    scale: 2,
    width,
    height,
    center: coordinates,
    zoom: 16,
    language: 'en',
    style: [
      { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
      { featureType: 'transit', stylers: [{ visibility: 'off' }] },
    ],
  });

  return (
    <>
      <div className='switch-to-map' onClick={onClick}>
        <div className='switch-to-map__map-backdrop' />

        <GoogleStaticMap className={'switch-to-map__map'} url={staticMapsUrl} />

        <Button className={'switch-to-map__button'} icon={'pin_1'} primary onClick={onClick}>
          {trans('hotels-booking.show-on-the-map')}
        </Button>
      </div>
    </>
  );
};
