import { MODULE_MOUNT_POINT, SEARCH_STATUS_PENDING, BOOKING_SUCCESS } from './index';
import { get } from 'lodash';
import moment from 'moment';
import minuteOfDay from '../../../utils/minuteOfDay';
import { createSelector } from '@reduxjs/toolkit';

//selectors
export const getState = (state) => {
  return state.get(MODULE_MOUNT_POINT);
};

export const getOffers = (name) => (state) => {
  let offers = get(getState(state), `${name}.offers`, []);
  const filters = getFilters(name)(state);

  offers = applyNoEmptyOptionsFilter(offers);
  offers = applyTravelClassFilter(offers, filters);
  offers = applyTimeFilter(offers, filters);

  return offers;
};

export const getStatus = (name) => (state) => {
  return get(getState(state), `${name}.status`, SEARCH_STATUS_PENDING);
};

export const getTotalPaxes = (name) => (state) => {
  return get(getState(state), `${name}.totalPaxes`, 1);
};

export const getSelectedOffer = (name) =>
  createSelector([getState], (state) => {
    const offers = get(state, `${name}.offers`, []);
    let selected = offers.find((offer) => offer.chosen === true);

    if (selected) {
      selected.option = selected.options.find((option) => option.chosen === true);
      return selected;
    }
    return null;
  });

export const getSelectedOption = (name) =>
  createSelector([getSelectedOffer(name)], (offer) => offer.option);

export const getTotalAmount = (name) =>
  createSelector(
    [getSelectedOption(name)],
    (option) => Number(option.amount.amount) + Number(option.reservation_fee.amount),
  );

export const getOfferCurrency = (name) =>
  createSelector([getSelectedOption(name)], (option) => option.amount.currency);

export const getSearchSubscription = (name) => (state) => {
  return get(getState(state), `${name}.subscriptions.search`, false);
};

export const getReservationSubscription = (name) => (state) => {
  return get(getState(state), `${name}.subscriptions.reservation`, false);
};

export const getSearchUuid = (name) => (state) => {
  return get(getState(state), `${name}.uuid`, null);
};

export const initialized = (name) => (state) => {
  return get(getState(state), `${name}.initialized`, false);
};

export const getQuery = (name) => (state) => {
  return get(getState(state), `${name}.query`, {});
};

export const isLoading = (name) => (state) => {
  return get(getState(state), `${name}.loading`, false);
};

export const isIntegrity = (name) => (state) => {
  return get(getState(state), `${name}.integrity`, false);
};

const applyTravelClassFilter = (offers, filters) => {
  const travelClass = get(filters, 'travelClass', '2');

  return offers.map((offer) => {
    offer.option = offer.options.find(
      (option) => get(option, 'attributes.service_class', null) === travelClass,
    );

    if (!offer.option) {
      offer.option = offer.options[0];
      offer.option.has_another_service_class = false;
    } else {
      offer.option.has_another_service_class = true;
    }

    return offer;
  });
};

const applyTimeFilter = (offers, filters) => {
  const arrive = get(filters, 'time.arrive', {
    min: 0,
    max: 1440,
  });

  const departure = get(filters, 'time.departure', {
    min: 0,
    max: 1440,
  });

  return offers.filter((offer) => {
    let arriveDateMinutes = minuteOfDay(moment(offer.attributes.arrivalDate));
    let departureMinutes = minuteOfDay(moment(offer.attributes.departureDate));

    return (
      arriveDateMinutes >= arrive.min &&
      arriveDateMinutes <= arrive.max &&
      departureMinutes >= departure.min &&
      departureMinutes <= departure.max
    );
  });
};

const applyNoEmptyOptionsFilter = (offers) => {
  return offers.filter((offer) => offer.options.length > 0);
};

export const getFilters = (name) => (state) => {
  return get(getState(state), `${name}.filter`, null);
};

export const isOptionBooked = (name) => (state) => {
  return get(getSelectedOffer(name)(state), 'option.booking', BOOKING_SUCCESS);
};

export const isSearchEnabled = (name) => (state) => {
  return get(getState(state), `${name}.enabled`, false);
};

export const selector =
  (name) =>
  (state) =>
  (field, defaultValue = null) => {
    return get(getState(state), `${name}.${field}`, defaultValue);
  };
