import React, { useCallback } from 'react'
import { Panel, PanelContent, PanelTitle } from '../ui/Panel'
import { useSelector } from 'react-redux'
import { getUserAssistantOrCurrentUserLink } from '../../store/app/user-profile'
import { getSlackIntegration } from '../../store/app/instance'
import Button from '../ui/ButtonComponent'
import APIClient from '../../services/APIClient'
import trans from '../../trans'

const INSTALL_LINK = 'slack_install'

export function SlackIntegration() {
  const link = useSelector(getUserAssistantOrCurrentUserLink(INSTALL_LINK))
  const integration = useSelector(getSlackIntegration)

  const onInstall = useCallback(() => {
    return APIClient.makeRequestFromHttpLink<{ redirect: string }, []>(link).then((response) => {
      window.location.assign(response.redirect)
    })
  }, [link])

  return (
    <div className='row slack-integration'>
      <div className='xs-12 md-6'>
        <Panel>
          <PanelTitle>
            <h1 className={'has-icon'}>
              <img
                className={'integration-icon'}
                src='/images/slack.svg'
                width={18}
                height={18}
                alt={'Slack'}
              />
              <span>Slack</span>
            </h1>
          </PanelTitle>

          <PanelContent>
            <p>{integration.message}</p>

            {!integration.installed && (
              <Button onClick={onInstall} disabled={!link} primary xs>
                {trans('integrations.slack-install')}
              </Button>
            )}
          </PanelContent>
        </Panel>
      </div>
    </div>
  )
}
