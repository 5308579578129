import React, { FunctionComponent, useState, useEffect } from 'react';
import { Loader } from '../../ui/LoadingOverlay/Loader';
import classNames from 'classnames';
import './OfferMapImage.scss';
import trans from '../../../trans';
import { HotelIcon } from '../../../shared/icons/HotelIcon';

interface OfferGalleryProps {
  photos: string[];
  maxAttempts?: number;
}

export const OfferMapImage: FunctionComponent<OfferGalleryProps> = ({
  photos,
  maxAttempts = 5,
}) => {
  const [current, setCurrent] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [validImage, setValidImage] = useState<string | null>(null);
  const [attempts, setAttempts] = useState(0);

  // Efficient image loading strategy
  useEffect(() => {
    if (validImage || attempts >= maxAttempts || current >= photos.length) return;

    if (photos[current]) {
      const img = new Image();
      img.onload = () => {
        setValidImage(photos[current]);
        setLoaded(true);
      };
      img.onerror = () => {
        setAttempts((prevAttempts) => prevAttempts + 1);
        setCurrent((prevCurrent) =>
          prevCurrent < photos.length - 1 ? prevCurrent + 1 : prevCurrent,
        );
      };
      img.src = photos[current];
    }
  }, [current, photos, validImage, attempts, maxAttempts]);

  const imageClassNames = classNames({
    'offer-map-image__image': true,
    'offer-map-image__image--hidden': !loaded,
  });

  return (
    <div className={`offer-map-image__container`}>
      {validImage ? (
        <img className={imageClassNames} src={validImage} alt='Offer image' />
      ) : (
        <div className='offer-map-image__placeholder'>
          {attempts < maxAttempts && current < photos.length ? (
            <Loader />
          ) : (
            <HotelIcon color={'#ccc'} size={100} />
          )}
        </div>
      )}
    </div>
  );
};
