import React from 'react';

import { Link } from 'react-router-dom';

import { BreadCrumbs } from '../../containers';
import { RouteManager } from '../../containers/RouteManager';
import { getRouteByName } from '../../routes';
import trans from '../../trans';
import AccountsPageFilters from '../AccountsPageFilters';
import AccountsPageTable from '../AccountsPageTable';
import { LoadingOverlay } from '../ui/LoadingOverlay';
import { Section } from '../ui/Section';
import { SectionHeader } from '../ui/SectionHeader';

import useAccounts from './hooks/useAccounts';

function AccountsPage() {
  const { data, paginator, loading, setPage, filters, setFilter } = useAccounts();

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'my-card-accounts')}>
          {trans('main-menu.my-card-accounts')}
        </Link>
      </BreadCrumbs>

      <Section className={'my-cards account-page'} noBorder>
        <SectionHeader
          className={'my-cards__header'}
          caption={trans('main-menu.my-card-accounts')}
        ></SectionHeader>

        <RouteManager>
          {({ getRouteByName, push }) => (
            <div>
              <AccountsPageFilters filters={filters} setFilter={setFilter} />
              {loading && <LoadingOverlay />}
              {!loading && (
                <AccountsPageTable data={data} paginator={paginator} setPage={setPage} />
              )}
            </div>
          )}
        </RouteManager>
      </Section>
    </div>
  );
}

export default AccountsPage;
