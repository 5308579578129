import React, { FunctionComponent, useMemo, useRef } from 'react';

import './OfferMapInfo.scss';
import { HotelOfferEntryOption, HotelOfferEntryRaw } from '../../../types/hotel-offers';
import { Scrollbars } from 'react-custom-scrollbars-2';

import trans from '../../../trans';
import Facilities from '../Facilities';
import Stars from '../Stars';
import { CorporateRate } from '../CorporateRate';
import Maintenance from '../Maintenance';
import Icon from '../../ui/IconComponent';
import { get } from 'lodash';
import classNames from 'classnames';
import { OptionBrokenRules } from '../OptionBrokenRules';
import { useSelector } from 'react-redux';
import { getFilter } from '../../../store/app/hotels-booking/selectors';
import DollarIcon from '../../../ui/Icons/DollarIcon';

interface Props {
  offer: HotelOfferEntryRaw;
  option: HotelOfferEntryOption;
}

export const OfferMapInfo: FunctionComponent<Props> = ({ offer, option }) => {
  const descriptionRef = useRef<HTMLDivElement>();
  const roomType = get(option, 'attributes.roomType.0', null);
  const refundable = get(option, 'cancelDate.refundable', null);
  const cancellableTooltipClassName = classNames({
    'hotel-dialog__offer-detail-icon': true,
    'hotel-dialog__offer-detail-icon--cancellable': refundable,
    'hotel-dialog__offer-detail-icon--non-cancellable': !refundable,
  });

  return (
    <div className={'offer-map-info'}>
      <div className={'offer-map-info__header'}>
        <div className='offer-map-info__header-title'>{offer.attributes.name}</div>

        <div className='offer-map-info__header-stars'>
          <Stars stars={offer.attributes.stars} />
        </div>
      </div>
      <div className={'offer-map-info__address'}>{offer.attributes.address}</div>

      <div className='offer-map-info__offer-icons'>
        <span className='offer-map-info__offer-cancelation-icon'>
          <span className={cancellableTooltipClassName}>
            <Icon type='calendar_cancel' />
          </span>
        </span>

        {option.corporateRate && (
          <span className={'offer-map-info__offer-corporate-rate'}>
            <DollarIcon width={16} />
          </span>
        )}

        <span className='offer-map-info__offer-gray-icons'>
          <Maintenance tooltip={false} mealTypes={offer.mealTypes} />
          <Facilities facilities={offer.attributes.facilities.facilities} />
        </span>
      </div>

      {roomType && <div className='hotel-dialog__offer-detail'>{roomType}</div>}

      <div className={'offer-map-info__description'} ref={descriptionRef}>
        <div className={'offer-map-info__description-label h4'}>
          {trans('hotels-booking.description')}
        </div>

        <div className={'offer-map-info__description-content'}>
          <Scrollbars>
            <div dangerouslySetInnerHTML={{ __html: offer.attributes.description }} />
          </Scrollbars>
        </div>
      </div>

      <div className='offer-map-info__amount'>
        <OptionBrokenRules option={option} />
        <span>{option.amount.formatted}</span>
      </div>
    </div>
  );
};
