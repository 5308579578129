import { useCallback, useEffect, useState } from 'react';
import APIClient from '../../../services/APIClient';
import { Paginator } from '../../../types/response';
import { Statement } from '../../../types/statements';
import { useSelector } from 'react-redux';
import { getUserAssistantOrCurrentUserLink } from '../../../store/app/user-profile/userProfileSlice';
import useMyCardDataSource from '../../../hooks/useMyCardDataSource';
import { IAccountPayment } from '../../../types/account';

export default function useStatements() {
  const link = useSelector(getUserAssistantOrCurrentUserLink('statements'));

  return useMyCardDataSource<Statement[]>(link.href);
}
