import { template } from 'lodash'

class GoogleMapsProvider {
  static SCRIPT_ID = 'google-maps'
  static CALLBACK_NAME = 'onMapLoaded'
  static instance = null

  lang = 'pl'
  demand = 0
  src = template(
    'https://maps.googleapis.com/maps/api/js?' +
      'v=weekly' +
      '&key=AIzaSyCLV7XTb4dvfnIXhWc4uYSlVJlKWeULOE8' +
      '&libraries=places,marker' +
      '&language=<%= language %>&callback=<%= callback %>',
  )
  callbacks = []
  isInjecting = true
  directionsService = null

  constructor() {
    if (GoogleMapsProvider.instance !== null) {
      throw new Error('Cannot construct other instance of GoogleMapsProvider')
    }

    window.onMapLoaded = this.onMapLoaded
    // console.log('GoogleMapsProvider created.');
  }

  static getInstance() {
    if (!GoogleMapsProvider.instance) {
      GoogleMapsProvider.instance = new GoogleMapsProvider()
    }

    return GoogleMapsProvider.instance
  }

  onMapLoaded = () => {
    // console.log('Google maps script loaded');

    this.isInjecting = false
    this.directionsService = new google.maps.DirectionsService()
    this.runCallbacks()
    this.clearCallbacks()
  }

  runCallbacks() {
    for (let callback of this.callbacks) {
      callback()
    }
  }

  clearCallbacks() {
    this.callbacks = []
  }

  setLang(lang = 'pl') {
    this.lang = lang
  }

  setCallback(callback) {
    // console.log('Callback pushed into stack');
    this.callbacks.push(callback)
  }

  enqueue() {
    this.demand++

    if (this.demand === 1) {
      this.injectScripts()
    }

    if (this.demand > 1 && !this.isInjecting) {
      this.runCallbacks()
      this.clearCallbacks()
    }
  }

  dequeue() {
    this.demand--

    if (this.demand === 0) {
      this.removeScripts()
    }
  }

  injectScripts() {
    const script = document.createElement('script')
    script.src = this.src({
      language: this.lang,
      callback: GoogleMapsProvider.CALLBACK_NAME,
    })
    script.id = GoogleMapsProvider.SCRIPT_ID
    document.body.appendChild(script)
  }

  removeScripts() {
    // console.log('Scripts have been removed');

    this.removed = true

    delete window.google.maps

    const script = document.querySelector('#' + GoogleMapsProvider.SCRIPT_ID)
    script.parentNode.removeChild(script)
  }

  _callDirectionsServiceRoute(
    { lat: latFrom, long: longFrom },
    { lat: latTo, long: longTo },
    travelMode = google.maps.TravelMode.DRIVING,
  ) {
    return new Promise((resolve, reject) => {
      const origin = new google.maps.LatLng(latFrom, longFrom)
      const destination = new google.maps.LatLng(latTo, longTo)

      this.directionsService.route(
        {
          origin,
          destination,
          travelMode,
        },
        (response, status) => {
          if (status === 'OK') {
            resolve([
              response,
              status,
              {
                origin,
                destination,
              },
            ])
          } else {
            reject([response, status])
          }
        },
      )
    })
  }

  getDistance(from, to, travelMode = google.maps.TravelMode.DRIVING) {
    return new Promise((resolve, reject) => {
      this._callDirectionsServiceRoute(from, to, travelMode)
        .then(([response, status]) => {
          try {
            const {
              routes: [_route, ...restRoutes],
            } = response
            const {
              legs: [_leg, ...restLegs],
            } = _route
            const {
              distance: { text, value },
            } = _leg

            const result = {
              text,
              meters: value,
              kilometers: value / 1000,
            }
            resolve(result)
          } catch (e) {
            reject()
          }
        })
        .catch(() => {
          reject()
        })
    })
  }

  getRoute(from, to, travelMode = google.maps.TravelMode.DRIVING) {
    return new Promise((resolve, reject) => {
      this._callDirectionsServiceRoute(from, to, travelMode)
        .then(([response, status, { origin, destination }]) => {
          resolve({
            response,
            origin,
            destination,
          })
        })
        .catch(([response, status]) => {
          reject()
        })
    })
  }
}

const instance = GoogleMapsProvider.getInstance()

export default instance
export { instance }
