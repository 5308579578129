import { useEffect, useMemo, useState } from 'react';
import { GridApi, GridOptions } from 'ag-grid-community';
import trans from '../../../trans';
import defaultFormatter from '../../AgGrid/ValueFormatter/defaultFormatter';
import { IAccountPayment } from '../../../types/account';
import dateFormatter from '../../AgGrid/ValueFormatter/dateFormatter';

export function useGridOptions() {
  const [api, setApi] = useState<GridApi>(null);
  const [gridOptions, setGridOptions] = useState<GridOptions<IAccountPayment>>({
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressSizeToFit: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    enableBrowserTooltips: true,
    onGridReady(event) {
      setApi(event.api);
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    columnDefs: [
      {
        headerName: '',
        field: 'id',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        valueFormatter: () => '',
        minWidth: 50,
        maxWidth: 50,
      },
      {
        headerName: trans('payments.payments_th_description'),
        field: 'description',
        valueFormatter: defaultFormatter,
        minWidth: 150,
      },
      {
        headerName: trans('payments.payments_th_card_number'),
        field: 'card_number',
        valueFormatter: defaultFormatter,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        headerName: trans('payments.payments_th_booked_at'),
        field: 'booked_at',
        valueFormatter: defaultFormatter,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        headerName: trans('payments.payments_th_employee'),
        field: 'employee',
        valueFormatter: defaultFormatter,
        minWidth: 100,
        maxWidth: 200,
      },
      {
        headerName: trans('payments.payments_th_amount'),
        field: 'amount',
        cellRenderer: defaultFormatter,
        minWidth: 200,
        maxWidth: 200,
        type: 'numericColumn',
      },
      {
        headerName: trans('payments.payments_th_balance'),
        field: 'balance',
        cellRenderer: defaultFormatter,
        minWidth: 200,
        maxWidth: 200,
        type: 'numericColumn',
      },
      {
        headerName: trans('payments.payments_th_download_at'),
        field: 'exported_at',
        cellRenderer: dateFormatter,
        minWidth: 140,
        maxWidth: 140,
        type: 'numericColumn',
      },
    ],
  });

  const onResize = useMemo(() => {
    return () => {
      if (api) {
        api.sizeColumnsToFit();
      }
    };
  }, [api]);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [api]);

  return gridOptions;
}
