import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '../ui/ButtonComponent'
import Icon from '../ui/IconComponent'
import Slider from 'react-slick'
import { trans } from '../../trans'
import { get } from 'lodash'
import { OptionBrokenRules } from './OptionBrokenRules'
import { Tooltip } from '../Tooltip'
import classNames from 'classnames'
import { CorporateRate } from './CorporateRate'

const PrevArrow = (props) => {
  return (
    <button
      className='hotel-dialog__offers-slider-btn hotel-dialog__offers-slider-btn--prev'
      onClick={props.onClick}
    >
      <Icon type='arrow' />
    </button>
  )
}

const NextArrow = (props) => {
  return (
    <button
      className='hotel-dialog__offers-slider-btn hotel-dialog__offers-slider-btn--next'
      onClick={props.onClick}
    >
      <Icon type='arrow' />
    </button>
  )
}

class Options extends Component<any, any> {
  constructor(props) {
    super(props)

    this.slider = React.createRef()
  }

  selectOption = (option) => {
    const { select, onClose } = this.props
    select(option)
    onClose()
  }

  renderOptions = () => {
    const { options } = this.props

    return options.map((option) => {
      const amount = get(option, 'amount.formatted', '0,00 zł').toLowerCase()
      const amountPerNight = get(option, 'amountPerNight.formatted', '0,00 zł').toLowerCase()
      const roomType = get(option, 'attributes.roomType.0', null)

      const refundable = get(option, 'cancelDate.refundable', null)
      const cancelDate = get(option, 'cancelDate.date', null)
      const cancelHour = get(option, 'cancelDate.hour', null)

      const allRoomTypes = get(option, 'attributes.roomType', []).join('<br />')

      const cancellableTooltipClassName = classNames({
        'hotel-dialog__offer-detail-icon': true,
        'hotel-dialog__offer-detail-icon--cancellable': refundable,
        'hotel-dialog__offer-detail-icon--non-cancellable': !refundable,
      })

      return (
        <div className='hotel-dialog__offer-wrapper' key={option.uuid} style={{ width: 140 }}>
          <div className='hotel-dialog__offer'>
            <div className='hotel-dialog__offer-content'>
              {roomType && (
                <div className='hotel-dialog__offer-detail'>
                  <Tooltip
                    className='hotel-dialog__offer-detail-text'
                    html={<span dangerouslySetInnerHTML={{ __html: allRoomTypes }} />}
                  >
                    <span>{roomType}</span>
                  </Tooltip>
                </div>
              )}

              <div className='hotel-dialog__offer-detail'>
                <Tooltip
                  className={cancellableTooltipClassName}
                  html={
                    <span>
                      {refundable
                        ? trans('hotels-booking.offer-cancellable', {
                            date: cancelDate,
                            hour: cancelHour,
                          })
                        : trans('hotels-booking.offer-non-cancellable')}
                    </span>
                  }
                >
                  <Icon type='calendar_cancel' />
                </Tooltip>

                {option.hasBreakfast && (
                  <Tooltip
                    className='hotel-dialog__offer-detail-icon'
                    html={<span>{trans('hotels-booking.breakfast-included')}</span>}
                  >
                    <Icon type='restaurant' />
                  </Tooltip>
                )}

                <div className='hotel-dialog__offer-detail-icon'>
                  <CorporateRate option={option} />
                </div>
              </div>
            </div>

            <div>
              <div className='hotel-dialog__offer-price-container'>
                <span className='hotel-dialog__offer-price'>
                  <OptionBrokenRules option={option} />
                  <span>{amount}</span>
                </span>
                {/*<span className="hotel-dialog__offer-price-per-night">{trans('hotels-booking.amount-per-night', {amount: amountPerNight})}</span>*/}
              </div>

              <Button
                className='hotel-dialog__offer-select-btn'
                primary
                onClick={() => this.selectOption(option)}
              >
                {trans('hotels-booking.select-option')}
              </Button>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 8,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      variableWidth: true,
    }

    return (
      <div className='hotel-dialog__offers'>
        <div className='hotel-dialog__offers-slider'>
          {!!this.props.options.length ? (
            <Slider {...settings} ref={this.slider}>
              {this.renderOptions()}
            </Slider>
          ) : (
            <div className='hotel-dialog__no-offers'>
              <span>{trans('hotels-booking.no-offers-for-that-filters')}</span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

Options.propTypes = {
  options: PropTypes.array.isRequired,
  select: PropTypes.func.isRequired,
}

export default Options
export { Options }
