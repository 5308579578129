import PropTypes from 'prop-types'
import React from 'react'
import { Col, Row } from '../../ui'
import { Field } from 'redux-form/immutable'
import { FormField } from '../ui/Form'
import trans from '../../trans'
import { Panel, PanelContent, PanelTitle } from '../ui/Panel'
import MeansOfTransportsManager from '../MeansOfTransports/MeansOfTransportsManager'
import classNames from 'classnames'

function AccountAllowanceForm({
  isMeansOfTransportsEditable,
  accountDimensions,
  accountDimensionItems,
  username,
  selectedAccommodations = [],
  availableMeansOfTransportsOptions,
}) {
  const cssClassForMeansOfTransports = classNames({
    'read-only': !isMeansOfTransportsEditable,
  })

  return (
    <Panel>
      <PanelTitle>
        <h1>{trans('document.basic-info')}</h1>
      </PanelTitle>

      <PanelContent>
        <form>
          <div>
            <div>
              <Row>
                <Col xs={4} className='read-only'>
                  <p>
                    {trans('request.lump-sum-statement')} {username}
                  </p>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col xs={4} className='read-only'>
                  <Field
                    name='uid'
                    type='text'
                    className='input--no-border'
                    component={FormField}
                    placeholder={'-'}
                    label={trans('document.document-number')}
                    disabled
                    labeltop
                  />
                </Col>

                <Col xs={2} />

                <Col xs={4} className='read-only'>
                  <Field
                    name='status_settlement_at'
                    type='datepicker'
                    className='input--no-border'
                    component={FormField}
                    placeholder={'-'}
                    label={trans('document.document-date')}
                    disabled
                    labeltop
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={4} className={cssClassForMeansOfTransports}>
                  <Field
                    name='means_of_transports'
                    type='select'
                    component={FormField}
                    options={availableMeansOfTransportsOptions}
                    label={trans('means-of-transports.field-label')}
                    clearable={true}
                    disabled={!isMeansOfTransportsEditable}
                    labeltop
                    isMulti
                  />
                </Col>

                <Col xs={2} />

                <Col xs={6}>
                  <div className='form-group--no-label'>{selectedAccommodations.join(', ')}</div>
                </Col>
              </Row>
            </div>

            <Row>
              {accountDimensions.map((dimension) => (
                <Col xs={6}>
                  <Field
                    name={dimension.fieldName}
                    type='account-dimensions'
                    component={FormField}
                    accountDimension={dimension}
                    selectedDimensions={accountDimensionItems}
                    placeholder={trans('account-dimensions.placeholder')}
                    label={dimension.label}
                    labeltop
                    asField
                    key={dimension.id}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </form>
      </PanelContent>
    </Panel>
  )
}

AccountAllowanceForm.propTypes = {
  selectedAccommodations: PropTypes.array,
  mpks: PropTypes.array.isRequired,
  accountingAccounts: PropTypes.array.isRequired,
  accountDimensions: PropTypes.array,
  accountDimensionItems: PropTypes.array,
  isMeansOfTransportsEditable: PropTypes.bool,
}

AccountAllowanceForm.defaultProps = {
  accountDimensions: [],
  accountDimensionItems: [],
}

export { AccountAllowanceForm }
export default { AccountAllowanceForm }
